<template>
  <BaseModal
    height="tall"
    startOpen
    ref="modal"
    :notifications="notifications"
  >
    <template #header>
      <h3 class="base-modal__title" v-if="isRescheduling">
        {{ __('meeting_scheduler.rescheduleMeeting') }}
      </h3>
      <h3 class="base-modal__title" v-else-if="ready">
        {{ __('meeting_scheduler.acceptInvitation') }}
      </h3>
    </template>

    <template #subheader>
      <ContactModalHeader
        v-if="otherParty?.id"
        :key="otherParty?.id"
        :contact="otherParty"
      />

      <div v-if="ready && !saving && !success && !errored">
        <ol class="wizard">
          <li
            v-for="step in steps"
            v-bind:key="step.name"
            class="wizard__step"
            :class="{
              'wizard__step--current': activeStep.name === step.name,
              'wizard__step--before-current': activeStep.id < step.id,
              'wizard__step--location': step.name === 'location',
              'wizard__step--date': step.name === 'date',
              'wizard__step--time': step.name === 'time',
            }"
            @click="goToNamedStep(step.name)"
          >
            {{ __(`meeting_scheduler.step.${step.name}`) }}
          </li>
        </ol>
      </div>
    </template>

    <template v-if="(!ready || saving) && !errored">
      <h1 class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>
    </template>

    <template v-if="ready && !saving">
      <div v-if="!success" class="schedule-meeting">
        <template v-if="activeStep.name === 'location'">
          <div class="schedule-meeting__body">

            <div
              v-if="recommended && !chooseMyOwn"
              class="schedule-meeting__recommended"
            >
              <div class="schedule-meeting__recommended-heading" v-html="__('meeting_scheduler.recommended_heading')"></div>
              <div class="schedule-meeting__recommended-option">
                <i class="far fa-clock"></i> {{ recommended.time }}
              </div>
              <div class="schedule-meeting__recommended-option">
                <i class="far fa-calendar-day"></i> {{ recommended.date }}
              </div>
              <div class="schedule-meeting__recommended-option">
                <i class="far fa-location-dot"></i> {{ recommended.location_long }}
              </div>
            </div>

            <ul v-else class="schedule-meeting__options">
              <li v-for="(locationOption, locationId) in locationOptions"
                :key="locationId"
                class="schedule-meeting__option"
                :class="{
                  'schedule-meeting__option--selected': selected?.location === locationId,
                  'schedule-meeting__option--disabled': !locationOption.is_available || !locationOption.is_permitted,
                  'schedule-meeting__option--locked': locationOption.locked,
                }"
                v-show="locationOption.is_permitted"
                @click="locationOption.is_available && locationOption.is_permitted && !locationOption.locked && selectMeetingOption(locationId)"
                :style="{ display: locationOption.is_permitted ? 'list-item' : 'none !important'} "
              >
                {{ locationOption.location_long }}
              </li>
            </ul>

            <div
              v-if="eventEdition.enable_floorplan_in_scheduler"
              class="schedule-meeting__floorplan-container"
            >
              <a
                :href="eventEdition.floorplan_url ?? ''"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img :src="floorplanImageUrl" class="schedule-meeting__floorplan-image">
                <span class="schedule-meeting__floorplan-clicker" v-if="eventEdition.floorplan_url">
                  <i class="far fa-search-plus"></i>
                  {{ __('meeting_scheduler.floorplanClickForFull') }}
                </span>
              </a>
            </div>
          </div>
        </template>

        <template v-if="activeStep.name === 'date'">
          <div class="schedule-meeting__body">
            <ul class="schedule-meeting__options">
              <li v-for="(date,dateId) in locationOptions[selected.location].dates"
                :key="dateId"
                class="schedule-meeting__option"
                :class="{
                  'schedule-meeting__option--selected': selected?.date === dateId,
                  'schedule-meeting__option--disabled': !date.is_available,
                }"
                @click="date.is_available && selectMeetingOption(dateId)"
              >
                {{ date.weekday }} {{ date.name }} {{ date.month }}
                <span v-if="!date.is_available">- {{ __('meeting_scheduler.unavailable') }}</span>
              </li>
            </ul>
          </div>
        </template>

        <template v-if="activeStep.name === 'time'">
          <div class="schedule-meeting__body">
            <ul class="schedule-meeting__options">
              <li v-for="time in timesSorted"
                :key="time.id"
                class="schedule-meeting__option"
                :class="{
                  'schedule-meeting__option--disabled': !time.is_available && !isCurrentTimeSlot(time.id),
                  'schedule-meeting__option--selected': selected?.time === time.id,
                }"
                @click="(time.is_available || isCurrentTimeSlot(time.id)) && selectMeetingOption(time.id)"
              >
                {{ time.time }}
                <span v-if="!time.is_available">- {{ __('meeting_scheduler.unavailable') }}</span>
                <small class="faint" v-else>{{ timezone }}</small>
              </li>
            </ul>
          </div>
        </template>
      </div>

      <template v-if="ready && !saving && success">
        <div class="schedule-meeting__success">
          <h1>
            <i class="fas fa-circle-check info-icon--success"></i>&nbsp;
            <span v-if="isRescheduling">
              {{ __('meeting_scheduler.meetingUpdatedTitle') }}
            </span>
            <span v-else>
              {{ __('meeting.meeting_confirmed.title') }}
            </span>
          </h1>
          <p>{{ __('meeting.meeting_confirmed.message') }}</p>
          <p>
            <a :href="downloadIcsUrl" target="_blank">
              <i class="far fa-calendar-circle-plus"></i>
              {{ __('meeting.addToCalendar') }}
            </a>
          </p>
        </div>
        <div class="schedule-meeting__summary">
          <div>
            <i class="far fa-location-dot"></i>&nbsp;
            {{ locationOptions[this.selected.location].location_long }}
          </div>
          <div>
            <i class="far fa-calendar"></i>&nbsp;
            {{ locationOptions[selected.location].dates[selected.date].date }}
          </div>
          <div>
            <i class="far fa-clock"></i>&nbsp;
            {{ locationOptions[selected.location].dates[selected.date].times[selected.time].time }}
          </div>
        </div>
      </template>
    </template>

    <template #footer>
      <div
        class="button-group"
        v-if="ready && !success && recommended && !chooseMyOwn"
      >
        <button
          type="button"
          class="button button--secondary"
          :disabled="saving"
          @click="chooseMyOwn=true"
        >
          {{ __('meeting_scheduler.buttons.choose_my_own') }}
        </button>

        <button
          type="button"
          class="button button--primary"
          :disabled="saving"
          @click="acceptRecommended"
        >
          {{ __('meeting_scheduler.buttons.accept_recommended') }}
        </button>
      </div>
      <div class="button-group" v-else-if="ready && !success">

        <button
          v-if="activeStep.name === 'location' && chooseMyOwn"
          type="button"
          class="button button--secondary"
          :disabled="saving"
          @click="chooseMyOwn = null"
        >
          <i class="far fa-arrow-left-long"></i> {{ __('meeting_scheduler.buttons.back') }}
        </button>

        <button
          v-else
          type="button"
          class="button button--secondary"
          :disabled="saving || activeStep.name === 'location'"
          @click="stepBackward"
        >
          <i class="far fa-arrow-left-long"></i> {{ __('meeting_scheduler.buttons.back') }}
        </button>

        <button
          type="button"
          class="button button--primary"
          :disabled="saving || !selected[activeStep.name]"
          @click="stepForward"
        >
          {{ __('meeting_scheduler.buttons.next') }} <i class="far fa-arrow-right-long"></i>
        </button>
      </div>

      <div class="button-group" v-else-if="ready && success">
        <a
          class="button button--secondary"
          :href="`#/meetings/${meeting.id}/details`"
        >
          {{ __('meeting.meeting_confirmed.view_meeting') }}
        </a>
        <a v-if="inviteId && nextInviteId"
          class="button button--primary"
          :href="`#/invites/${nextInviteId}/show`"
        >
          {{ __('meeting.meeting_confirmed.next_invite') }}
          <i class="fa-light fa-arrow-right"></i>
        </a>
        <button
          v-else
          type="button"
          class="button button--wide"
          @click="modalClose"
        >
          {{ __('meeting_scheduler.buttons.done') }}
        </button>
      </div>
    </template>

  </BaseModal>
</template>

<script>
import axios from 'axios';
import { sortBy } from 'lodash';
import { user, store, actionRequiredCounts } from '../../../Frontend/store';

export default {
  props: {
    inviteId: {
      type: [String, Number],
      default: '',
    },
    meetingId: {
      type: [String, Number],
      default: '',
    },
    contactDefaultSearchPage: {
      type: String,
    },
  },
  data() {
    return {
      invite: {},
      meeting: {},
      saving: false,
      success: false,
      selected: {},
      locationOptions: {},
      timezone: null,
      step: 0,
      steps: [
        { id: 1, name: 'location', label: 'Location' },
        { id: 2, name: 'date', label: 'Date' },
        { id: 3, name: 'time', label: 'Time' },
      ],
      errored: false,
      errorMessage: null,
      nextInviteId: null,
      user,
      notifications: [],
      actionRequiredCounts,
      floorplanImageUrl: null,
      recommended: null,
      chooseMyOwn: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (this.isRescheduling) {
        axios.get(`/${this.eventEdition.slug}/meetings/${this.meetingId}/reschedule`)
          .then((response) => {
            this.meeting = response.data.meeting;

            if (response.data.scheduler.floorplan_image_url) {
              this.floorplanImageUrl = response.data.scheduler.floorplan_image_url;
            }

            // keep this in sync with the location options loader below
            this.timezone = response.data.scheduler.timezone;
            this.locationOptions = response.data.scheduler.locations.reduce((options, location) => {
              options[location.id] = location;
              return options;
            }, {});

            // Create selections object from meeting data
            this.selected = {};
            this.selected.location = this.meeting.location.id_string;
            this.selected.date = this.meeting.date_short;
            this.selected.time = this.meeting.slot_id;
          })
          .catch((error) => {
            if (error.response) {
              this.errored = true;
              this.notifications.push({
                time: Date.now(),
                type: 'warning',
                message: null, // default
                icon: 'fa-circle-info',
              });
            }
          });
      } else if (this.inviteId) {
        // Create empty selections object
        this.selected = Object.fromEntries(this.steps.map((step) => [step.name, null]));
        this.loadInvite(this.inviteId);
        return true;
      }

      return true;
    },
    loadLocationOptions(inviteId) {
      this.recommended = null;
      this.chooseMyOwn = false;

      axios
        .get(`/${this.eventEdition.slug}/meeting-invites/${inviteId}/accept`)
        .then((response) => {
          this.timezone = response.data.timezone;
          if (response.data.floorplan_image_url) {
            this.floorplanImageUrl = response.data.floorplan_image_url;
          }
          this.locationOptions = response.data.locations.reduce((options, location) => {
            options[location.id] = location;
            return options;
          }, {});

          if (response.data.candidates[0]) {
            // the following code is the same as this.
            // this.recommended = response.data.candidates[0];
            [this.recommended] = response.data.candidates;
          }
        }).catch((response) => {
          if (response) {
            this.errored = true;
            this.errorMessage = response.data.message;
          }
        }).then(() => {
          const availableLocations = Object.values(this.locationOptions)
            .filter((l) => l.is_available);

          if (availableLocations.length === 0) {
            this.notifications.push({
              time: Date.now(),
              type: 'warning',
              // eslint-disable-next-line no-underscore-dangle
              message: this.__('meeting_scheduler.errors.no_available_slots'),
              icon: 'fa-circle-info',
            });
          }
        });
    },
    loadInvite(inviteId) {
      axios
        .get(`/${this.eventEdition?.slug}/meeting-invites/${inviteId}`)
        .then((response) => {
          // If Accept action, fail on already accepted with meeting link
          if (
            this.inviteId
            && response.data.invite.status !== 'pending'
            && response.data.invite.status !== 'expired'
          ) {
            this.errored = true;
            // eslint-disable-next-line no-underscore-dangle
            const messageHTML = this.__('meeting_scheduler.errors.invite_already_actioned') + this.__('meeting_scheduler.errors.invite_already_actioned_link', {
              invite_counterpart_contact_id: response.data.invite.sender_id,
            });
            this.notifications.push({
              time: Date.now(),
              type: 'warning',
              message: messageHTML,
              icon: 'fa-circle-info',
            });
          }
          this.invite = response.data.invite;
          if (!Object.keys(this.locationOptions).length) {
            this.loadLocationOptions(response.data.invite.id);
          }
        })
        .catch(() => {
          this.errored = true;
          this.notifications.push({
            time: Date.now(),
            type: 'warning',
            // eslint-disable-next-line no-underscore-dangle
            message: this.__('meeting_invite.decline.errors.cannot_load'),
            icon: 'fa-circle-info',
          });
        });
    },
    acceptRecommended() {
      this.selected = {};
      this.selected.location = this.recommended.location_id;
      this.selected.date = this.recommended.date;
      this.selected.time = this.recommended.slot_id;

      this.confirm();
    },
    confirm() {
      this.saving = true;

      axios.post(this.endpoint, {
        slot_id: this.locationOptions[this.selected.location]
          .dates[this.selected.date]
          .times[this.selected.time]
          .id,
        location_id: this.locationOptions[this.selected.location].id,
      }).then((response) => {
        // Always maintain meeting state - new or rescheduled
        this.meeting = response.data.meeting;

        // Only used for accept success buttons
        this.nextInviteId = response.data.next_invite?.id;

        // Event used in different contexts
        if (!this.isRescheduling) {
          this.emitter.emit('invites.accepted', {
            inviteId: response.data.meeting.invite.id, // e.g. inbox invite disappears
            meetingId: response.data.meeting.id, // e.g. link to view meeting
            attendeeContactIds: response.data.meeting.attendees.map((attendee) => attendee.id), // update contact cards
          });
          this.actionRequiredCounts.setInvitesReceivedPending(
            response.data.invites_received_pending,
          );
        } else {
          this.emitter.emit('meetings.rescheduled', {
            meetingId: response.data.meeting.id,
          });
        }

        this.success = true;
        this.saving = false;
      })
        .catch(() => {
          this.errored = true;
          this.notifications.push({
            time: Date.now(),
            type: 'warning',
            // eslint-disable-next-line no-underscore-dangle
            message: this.__('meeting_scheduler.errors.invite_already_actioned'),
            icon: 'fa-circle-info',
          });
        });
    },
    isCurrentTimeSlot(time) {
      if (!this.meeting.id) {
        return false;
      }
      return this.meeting?.slot?.id === time;
    },
    selectMeetingOption(selectionId) {
      this.selected[this.activeStep.name] = selectionId;
    },
    stepForward() {
      if (this.isLastStep) {
        this.confirm();
      } else {
        this.step += 1;
      }
    },
    stepBackward() {
      if (this.step > 0) {
        this.step -= 1;
      }
    },
    goToNamedStep(stepName) {
      const stepIndex = this.steps.findIndex((s) => s.name === stepName);
      // Only if an option has been selected
      if (this.steps[stepIndex].name && this.selected[this.steps[stepIndex].name] !== null) {
        this.step = stepIndex;
      }
    },
    getPresence(location) {
      if (location.physical && location.virtual) {
        return 'In Person / Online';
      }
      if (location.physical) {
        return 'In Person';
      }
      return 'Online';
    },
    modalClose() {
      this.$refs.modal.close();
      this.emitter.emit('meetings.modal_closed');
    },
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
    isRescheduling() {
      return !!this.meetingId;
    },
    otherParty() {
      if (!this.invite) {
        return null;
      }
      if (this.user?.activeContact?.id === this.invite.sender_id) {
        return this.invite.recipient;
      }
      return this.invite?.sender;
    },
    activeStep() {
      return this.steps[this.step];
    },
    isLastStep() {
      return this.step === this.steps.length - 1;
    },
    timesSorted() {
      // eslint-disable-next-line prefer-destructuring
      const times = this.locationOptions[this.selected.location].dates[this.selected.date].times;

      return sortBy(Object.values(times), 'atom_time');
    },
    ready() {
      return !!((this.meeting?.id || this.invite?.id)
        && Object.keys(this.locationOptions).length
        && !this.errored);
    },
    defaultSearchPage() {
      return this.contactDefaultSearchPage || this.eventEdition?.default_search_page;
    },
    downloadIcsUrl() {
      return `/${this.eventEdition?.slug}/meetings/${this.meeting.id}/download-ics`;
    },
    endpoint() {
      if (this.isRescheduling) {
        return `/${this.eventEdition?.slug}/meetings/${this.meetingId}/reschedule`;
      }
      return `/${this.eventEdition.slug}/meeting-invites/${this.inviteId}/accept`;
    },
  },
};
</script>
