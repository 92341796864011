<template>
  <BaseModal
    :title="(loading || !theatre?.name) ? '...' : theatre.name"
    ref="modal"
    height="tall"
    startOpen
  >
    <template v-for="(sessions,day) in sessionsByDay" :key="day">
      <div class="base-modal__body-row" style="margin-bottom: var(--sizing-4)">
        <h4 style="margin-bottom: var(--sizing-4)">{{ day }}</h4>

        <div
          v-for="session in sessions"
          :key="session.id"
          style="margin-bottom: var(--sizing-3); border-top: 1px solid var(--gray-2); padding-top: var(--sizing-3);"
        >
          <div style="display: grid; grid-template-columns: 1fr 48px;">
            <div>
              <div style="font-size: var(--font-small); color: var(--gray-5)">
                <i class="far fa-clock"></i> {{ session.formatted_start_time }} - {{ session.formatted_end_time }}
              </div>
              <div>
                {{ session.title }}
              </div>
            </div>
            <div>
              <button v-if="session.policy.is_booked_on" class="button button--link button--compact" @click="unbook(session.id)">
                <i class="fa-regular fa-calendar-circle-minus"></i>
              </button>

              <button v-else-if="!session.policy.is_busy && session.policy.can_book" class="button button--link button--compact" @click="book(session.id)">
                <i class="fa-regular fa-calendar-circle-plus"></i>
              </button>

              <button v-else-if="session.policy.is_busy" class="button button--link button--disabled button--compact" type="button" disabled>
                <i class="fa-regular fa-calendar-circle-plus"></i>
              </button>
            </div>
          </div>

          <div style="font-size: var(--font-small); color: var(--gray-4)">
            <text-clamp :max-lines="2" :text="session.description" />
          </div>
        </div>

      </div>
    </template>

    <template #footer>
      <div class="button-group">
        <button v-if="loading" class="button button--loading" />
        <button type="button" @click="modalClose" class="button button--primary">
          Close
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import TextClamp from 'vue3-text-clamp';
import { store } from '../../../Frontend/store';

export default {
  props: {
    theatreSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      theatre: {},
      sessionsByDay: {},
      lastSessionDate: '',
      loading: true,
      errored: false,
    };
  },
  components: { TextClamp },
  mounted() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    axios.get(this.endpoint, config)
      .then((response) => {
        this.theatre = response.data.data;

        this.updateByDay();
      }).then(() => {
        this.loading = false;
      }).catch(() => {
        this.errored = true;
      });
  },
  methods: {
    updateByDay() {
      this.sessionsByDay = {};
      this.theatre.sessions.forEach((session) => {
        if (!this.sessionsByDay[session.formatted_start_date]) {
          this.sessionsByDay[session.formatted_start_date] = [];
        }
        this.sessionsByDay[session.formatted_start_date].push(session);
      });
    },
    book(sessionId) {
      this.loading = true;
      axios
        .post(`${this.endpoint}/${sessionId}/book`)
        .then((response) => {
          this.theatre = response.data.data;
          this.updateByDay();
          this.loading = false;
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert(
            'Something went wrong, it is likely you have a schedule clash.\nPlease refresh your browser and try again.',
          );
        });
    },
    unbook(sessionId) {
      this.loading = true;
      axios
        .post(`${this.endpoint}/${sessionId}/unbook`)
        .then((response) => {
          this.theatre = response.data.data;
          this.updateByDay();
          this.loading = false;
        });
    },
    modalClose() {
      this.$refs.modal.close();
    },
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
    isIframed() {
      return window.location.pathname.includes('iframe/');
    },
    endpoint() {
      // if (this.isIframed) {
      //   return `${window.location.pathname}/sessions/${this.sessionId}/preview`;
      // }
      return `/${this.eventEdition.slug}/theatres/${this.theatreSlug}`;
    },
    // conferenceUrl() {
    //   return `/${this.eventEdition?.slug}/conference-sessions/${this.conference.id}`;
    // },
  },
};
</script>
