<template>
  <BaseModal
    :title="__('exclusive_events.preview_session.title')"
    ref="modal"
    height="tall"
    startOpen
  >
    <template v-if="exclusiveEvent">
      <div class="base-modal__container--wide">
        <img class="base-modal__body-image base-modal__body-image--exclusive-event-modal-preview"
          :src="mainImage"
          :alt="exclusiveEvent.title"
        />
      </div>

      <h2>{{ exclusiveEvent.title }}</h2>

      <div
        v-if="exclusiveEvent.duration_for_humans && exclusiveEvent.location"
        class="base-modal__body-row"
      >
        <div class="exclusive-event-row__details-container">
          <span class="exclusive-event-row__detail">
            <i class="fa-regular fa-calendar"></i>&nbsp;
            <strong>{{ exclusiveEvent.formatted_start_date }}</strong>
          </span>
          <span class="exclusive-event-row__detail">
            <i class="far fa-clock"></i>&nbsp;
            <strong>{{ exclusiveEvent.formatted_start_time+' - '+exclusiveEvent.formatted_end_time }}</strong
            >&nbsp;
            <small>({{ exclusiveEvent.duration_for_humans }})</small>
          </span>
          <span class="exclusive-event-row__detail">
            <i class="fa-regular fa-location-dot"></i>&nbsp;
            <strong>{{ exclusiveEvent.location }}</strong>
          </span>
        </div>
      </div>

      <div class="base-modal__body-row" v-html="exclusiveEvent.description ? exclusiveEvent.description : __('exclusive_events.no_description')">
      </div>

    </template>

    <template v-if="!exclusiveEvent.id && !errored">
      <h1 class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>
    </template>

    <template #footer v-if="exclusiveEvent">
      <div class="button-group">
        <button v-if="loading" class="button button--loading" />
        <button v-else-if="exclusiveEvent.policy.is_booked_on" class="button button--secondary button--active" @click="unbook">
          {{ __("exclusive_events.row.buttons.booking_remove_from_schedule") }}
        </button>
        <button v-else-if="!exclusiveEvent.policy.is_available" class="button button--secondary button--disabled">
          {{ __("exclusive_events.row.buttons.booking_schedule_clash") }}
        </button>
        <button v-else-if="!exclusiveEvent.policy.has_capacity" class="button button--primary button--disabled">
          {{ __("exclusive_events.row.buttons.booking_capacity_full") }}
        </button>
        <button v-else-if="exclusiveEvent.policy.can_book" class="button button--secondary" @click="book">
          {{ __("exclusive_events.row.buttons.booking_add_to_schedule") }}
        </button>
      </div>
    </template>

  </BaseModal>
</template>

<script>
import axios from 'axios';
import { store } from '../../../Frontend/store';

export default {
  props: {
    eventId: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'palette-1',
    },
  },
  data() {
    return {
      defaultImage: 'https://s3.eu-west-1.amazonaws.com/storage.v4.eventnetworking.com/assets/default-marketplace-card-banner.png',
      exclusiveEvent: {
        type: Object,
      },
      loading: true,
      errored: false,
    };
  },
  mounted() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    axios.get(this.endpoint, config)
      .then((response) => {
        this.exclusiveEvent = response.data.data;
      }).then(() => {
        this.loading = false;
      }).catch(() => {
        this.errored = true;
      });
    this.emitter.on('exclusive_event.booking', (data) => {
      const found = data.policies.find((item) => item.exclusive_event_id === this.exclusiveEvent.id);
      if (found) {
        this.exclusiveEvent.policy = found;
      }
    });
  },
  methods: {
    book() {
      this.loading = true;
      axios.post(`${this.exclusiveEventUrl}/book`)
        .then((response) => {
          this.emitter.emit('exclusive_event.booking', {
            policies: response.data.policies,
          });
        })
        .then(() => {
          this.loading = false;
        });
    },
    unbook() {
      this.loading = true;
      axios.post(`${this.exclusiveEventUrl}/unbook`)
        .then((response) => {
          this.emitter.emit('exclusive_event.booking', {
            policies: response.data.policies,
          });
        }).then(() => {
          this.loading = false;
        });
    },
    modalClose() {
      this.$refs.modal.close();
    },
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
    endpoint() {
      return `/${this.eventEdition.slug}/exclusive-events/${this.eventId}`;
    },
    exclusiveEventUrl() {
      return `/${this.eventEdition?.slug}/exclusive-events/${this.exclusiveEvent.id}`;
    },
    mainImage() {
      return this.exclusiveEvent.card_image ?? this.defaultImage;
    },
  },
};
</script>
