export default {
  'assets.image.image': 'صورة',
  'assets.image.name': 'عنوان الصورة',
  'assets.video.title': 'عنوان الفيديو',
  'assets.video.description': 'وصف الفيديو',
  'assets.video.title.default': 'فيديو بدون عنوان',
  'assets.video.video': 'فيديو',
  'buttons.add': 'إضافة',
  'buttons.addMore': 'إضافة المزيد',
  'buttons.back': 'عودة',
  'buttons.cancel': 'إلغاء',
  'buttons.change': 'تغيير',
  'buttons.clear': 'مسح',
  'buttons.close': 'إغلاق',
  'buttons.contact': 'اتصال',
  'buttons.delete': 'حذف',
  'buttons.done': 'تم',
  'buttons.edit': 'تعديل',
  'buttons.download': 'تحميل',
  'buttons.help': 'مساعدة',
  'buttons.impersonateUser': 'الاتصال كـ',
  'buttons.manage': 'إدارة',
  'buttons.manageImages': 'إدارة الصور',
  'buttons.new': 'جديد',
  'buttons.next': 'التالي',
  'buttons.return': 'عودة',
  'buttons.save': 'حفظ',
  'buttons.sendLink': 'إرسال رابط',
  'buttons.set': 'تعيين',
  'buttons.set_password': 'تعيين كلمة المرور',
  'buttons.update': 'تحديث',
  'buttons.uploadImage': 'تحميل صورة',
  'buttons.locked': 'مقفل',
  'buttons.write_with_ai': 'إنشاء باستخدام الذكاء الاصطناعي',
  'buttons.recreate_with_ai': 'إعادة الإنشاء باستخدام الذكاء الاصطناعي',
  'buttons.notifications': 'إشعارات',
  'buttons.run_search': 'تشغيل',
  'buttons.show_more': 'عرض المزيد',
  'buttons.show_less': 'عرض أقل',
  'action.unfavourite': 'إزالة من المفضلة',
  'action.favourite': 'إضافة إلى المفضلة',
  'action.unhide': 'إلغاء إخفاء',
  'action.hide': 'إخفاء',
  'catalogue.catalogueEntry.description': 'وصف',
  'catalogue.catalogueEntry.gallery': 'صور',
  'catalogue.catalogueEntry.highlights': 'أبرز المزايا',
  'catalogue.catalogueEntry.image': 'صورة',
  'catalogue.catalogueEntry.keywords': 'الكلمات المفتاحية',
  'catalogue.catalogueEntry.links': 'الروابط',
  'catalogue.catalogueEntry.moreOptions': 'خيارات أكثر',
  'catalogue.catalogueEntry.name': 'عنوان',
  'catalogue_product_form.add_image_success': 'تم إضافة صورة بنجاح إلى :filename، ستظهر عند تحديث الصفحة.',
  'catalogue_product_form.delete': 'حذف',
  'catalogue_product_form.description': 'الوصف*:',
  'catalogue_product_form.edit': 'تعديل',
  'catalogue_product_form.highlights.add': 'إضافة ميزة',
  'catalogue_product_form.highlights.no_highlights': 'لم تضاف أي ميزات',
  'catalogue_product_form.highlights.placeholder': '',
  'catalogue_product_form.highlights.title': 'أبرز المزايا',
  'catalogue_product_form.image': 'صورة المنتج*',
  'catalogue_product_form.images': 'الصور',
  'catalogue_product_form.keywords': 'الكلمات المفتاحية',
  'catalogue_product_form.keywords_descriptions': "افصل بين الكلمات المفتاحية المتعددة بفاصلة ','",
  'catalogue_product_form.name': 'الاسم*:',
  'catalogue_product_form.order': 'الترتيب:',
  'catalogue_product_form.order_description': 'الترتيب تصاعدي (1 سيعرض قبل 2).',
  'catalogue_product_form.recommended_image_size': 'الحجم الموصى به 1280px بمقدار 720px (ستعرض الصور بنسبة شاشة 16:9). الحد الأقصى لحجم الملف 5Mb',
  'catalogue_product_form.remove_image_success': 'تمت إزالة الصورة بنجاح من :filename، ستظهر عند تحديث الصفحة.',
  'catalogue_product_form.save': 'حفظ',
  'catalogue_product_form.save_then_upload': 'يرجى الحفظ ثم النقر على تعديل لتحميل الصور.',
  'catalogue_product_form.subtitle': 'العنوان الفرعي*:',
  'catalogue_product_form.tagline': 'الشعار*:',
  'catalogue_product_form.title.create': 'إنشاء {{ type }}',
  'catalogue_product_form.title.update': 'تعديل {{ type }}',
  'catalogue_product_form.update': 'حفظ',
  'catalogue_product_form.update_photo': 'تعديل الصورة',
  'catalogue_product_form.upload_photo': 'تحميل صورة',
  'catalogue_product_preview.buttons.view': 'عرض',
  'catalogue_product_preview.description': 'الوصف',
  'catalogue_product_preview.highlights': 'أبرز مواصفات',
  'catalogue_product_preview.learn_more': 'تعرف على المزيد',
  'catalogue_product_preview.more': 'المزيد',
  'catalogue_product_preview.read_more': 'اقرأ المزيد',
  'catalogue_product_preview.show_less': 'عرض أقل',
  'catalogue_product_preview.view_product': 'عرض المنتج',
  'company.actions.business_card_dropped': 'تمت مشاركة بطاقة العمل',
  'company.actions.drop_business_card': 'مشاركة بطاقة العمل',
  'company.company_book_meeting_modal.cancel': 'إلغاء',
  'company.company_book_meeting_modal.cannot_meet': 'لا يمكنك الاجتماع مع أي جهات اتصال في هذه الشركة',
  'company.company_book_meeting_modal.meet': 'اجتماع',
  'company.company_book_meeting_modal.more': 'المزيد',
  'company.company_book_meeting_modal.next': 'التالي →',
  'company.company_book_meeting_modal.other_contacts': 'جهات اتصال أخرى',
  'company.company_book_meeting_modal.recommended_contacts': 'موصى به',
  'company.company_book_meeting_modal.select_contact': 'اختر جهة اتصال للاجتماع:',
  'company.company_book_meeting_modal.title': 'حجز اجتماع',
  'company.company_preview_modal.activities': 'الأنشطة والاهتمامات',
  'company.company_preview_modal.book_meeting': 'حجز اجتماع',
  'company.company_preview_modal.company_profile': 'ملف الشركة',
  'company.company_preview_modal.people': 'الأشخاص',
  'company.company_preview_modal.read_more': 'اقرأ المزيد',
  'company.company_preview_modal.title': '{{ company_display_name }}',
  'company.company_preview_modal.view_people': 'عرض جميع جهات الاتصال →',
  'company.company_preview_modal.view_profile_page': 'عرض صفحة الملف الشخصي',
  'company.drop_business_card.agreement': 'أعطي هنا الإذن بمشاركة <strong> الاسم والمسمى الوظيفي وعنوان البريد الإلكتروني </strong> مع {{ company_display_name }}',
  'company.drop_business_card.cancel': 'إلغاء',
  'company.drop_business_card.disclaimer': 'أفهم أن معلوماتي سيتم تخزينها بواسطة {{ company_display_name }} ويجب عليّ الاتصال بـ {{ company_display_name }} لتغيير تفضيلات الاتصال الخاصة بي.',
  'company.drop_business_card.header': 'شارك معلومات الاتصال الخاصة بك',
  'company.drop_business_card.share': 'أوافق',
  'company.drop_business_card.share_success.done': 'تم',
  'company.drop_business_card.share_success.message': 'تمت مشاركة اسمك والمسمى الوظيفي وعنوان البريد الإلكتروني ورقم الهاتف مع {{ company_display_name }}',
  'company.drop_business_card.share_success.title': 'تمت مشاركة التفاصيل',
  'company_card.view_company': 'عرض الشركة',
  'concierge_modal.info_text': 'تواصل مع فريق الاستعلامات الخاصة بأحداث الشبكات للحصول على استفسارات ودعم خاص بالاجتماعات.',
  'concierge_modal.link_text': 'بحاجة إلى مساعدة؟',
  'concierge_modal.whatsapp_button': 'الدردشة على WhatsApp',

  // جلسات المؤتمر
  'conference_sessions.ask_a_question.box_closing': 'سيتم إغلاق هذا المربع تلقائيًا.',
  'conference_sessions.ask_a_question.buttons.ask': 'اطرح سؤال',
  'conference_sessions.ask_a_question.buttons.cancel': 'إلغاء',
  'conference_sessions.ask_a_question.buttons.send': 'إرسال',
  'conference_sessions.ask_a_question.description': 'سيقوم منسق الجلسة بمراجعة سؤالك',
  'conference_sessions.ask_a_question.header': 'اطرح سؤال',
  'conference_sessions.ask_a_question.placeholder': 'سؤالي...',
  'conference_sessions.ask_a_question.question_submitted': 'تم تقديم سؤالك.',
  'conference_sessions.preview_session.speakers': 'المتحدثين',
  'conference_sessions.preview_session.title': 'جلسة المؤتمر',
  'conference_sessions.row.buttons.add_to_schedule': 'أضف إلى الخطة',
  'conference_sessions.row.buttons.join_session': 'انضم إلى الجلسة',
  'conference_sessions.row.buttons.more': 'المزيد',
  'conference_sessions.row.buttons.preview_session': 'المعاينة',
  'conference_sessions.row.buttons.remove_from_schedule': 'إزالة من الجدول',
  'conference_sessions.row.buttons.schedule_clash': 'تعارض في الجدول',
  'conference_sessions.row.buttons.booking_capacity_full': 'السعة ممتلئة',
  'conference_sessions.row.buttons.watch_now': 'شاهد الآن',
  'conference_sessions.row.session': 'الجلسة',
  'conference_sessions.row.sponsored_by': 'برعاية',
  'conference_sessions.row.starting_soon': 'يبدأ قريبًا',

  'conversations.accepted_by': 'تم قبول دعوة الاجتماع بواسطة {{ sender }}',
  'conversations.buttons.send_invite': 'إرسال دعوة',
  'conversations.cancelled_by': 'تم إلغاء دعوة الاجتماع بواسطة {{ sender }}',
  'conversations.chips.cancelled': 'الاجتماع تم إلغاؤه',
  'conversations.chips.colleague_added': 'تم إضافة زميل',
  'conversations.chips.confirmed': 'تم تأكيد الاجتماع',
  'conversations.chips.declined': 'تم رفض الدعوة',
  'conversations.chips.forwarded': 'تمت إعادة توجيه الدعوة',
  'conversations.chips.new_invite': 'دعوة لاجتماع',
  'conversations.chips.updated': 'تم تحديث الاجتماع',
  'conversations.chips.withdrawn': 'تم سحب الدعوة',
  'conversations.colleague_added': '{{ added_by }} أضاف {{ new_colleague }} إلى الاجتماع.',
  'conversations.declined_by': 'تم رفض دعوة الاجتماع بواسطة {{ sender }}',
  'conversations.forwarded_to': 'تمت إعادة توجيه دعوة الاجتماع إلى {{ recipient }}',
  'conversations.invitation_from': 'دعوة للاجتماع من {{ sender }}',
  'conversations.view_meeting': 'عرض الاجتماع',
  'conversations.withdrawn_by': 'تم سحب دعوة الاجتماع بواسطة {{ sender }}',
  'countdown.days': 'أيام',
  'countdown.hours': 'ساعات',
  'countdown.minutes': 'دقائق',
  'countdown.seconds': 'ثواني',
  'crm.communicationPreference.channels.email': 'إشعارات وتذكيرات بالبريد الإلكتروني',
  'crm.communicationPreference.channels.phone': 'اتصالات دعم هاتفية',
  'crm.communicationPreference.channels.sms': 'إشعارات وتذكيرات برسائل نصية',
  'crm.communicationPreference.name': 'تفضيلات',
  'crm.company.bio': 'وصف الشركة',
  'crm.company.displayName': 'اسم العرض',
  'crm.company.legalName': 'الاسم القانوني',
  'crm.company.logo': 'شعار الشركة',
  'crm.company.officeLocation': 'الموقع',
  'crm.company.officeLocations': 'المواقع',
  'crm.company.socials': 'وسائل التواصل الاجتماعي',
  'crm.company.tagline': 'مقدمة قصيرة',
  'crm.company.websites': 'المواقع الإلكترونية',
  'crm.companyOfficeLocation.address': 'العنوان',
  'crm.companyOfficeLocation.country': 'الدولة',
  'crm.contact.avatar': 'صورة الملف الشخصي',
  'crm.contact.bio': 'عني',
  'crm.contact.country': 'الدولة',
  'crm.contact.email': 'البريد الإلكتروني',
  'crm.contact.firstName': 'الاسم الأول',
  'crm.contact.fullName': 'الاسم الكامل',
  'crm.contact.jobTitle': 'المسمى الوظيفي',
  'crm.contact.lastName': 'الاسم الأخير',
  'crm.contact.lookingFor': 'يبحث عن',
  'crm.contact.managedBy': 'مديرو الملف الشخصي',
  'crm.contact.optouts': 'الانسحابات',
  'crm.contact.timezone': 'المنطقة الزمنية',
  'crm.contact.title': 'اللقب',
  'crm.link.type.website': 'موقع الكتروني',
  'crm.link.url': 'رابط',
  'crm.phone.types.mobile': 'الهاتف المحمول',
  'crm.phone.types.office': 'هاتف المكتب',

  'directory.default_search_name': 'نتائج البحث لـ {{ date }}',
  'directory.saved_search_success': 'تم حفظ البحث بنجاح.',
  'directory.updated_search_success': 'تم تحديث البحث بنجاح.',
  'directory.edit_search': 'تعديل هذا البحث',
  'directory.export_wishlist': 'تحميل',
  'directory.filters.apply': 'تطبيق',
  'directory.filters.clearAll': 'مسح الكل',
  'directory.filters.close': 'إغلاق الفلاتر',
  'directory.filters.filters': 'الترتيب والتصفية',
  'directory.filters.open': 'الترتيب والتصفية',
  'directory.filters.primary.select_one_option': 'اختر <strong>واحد</strong> من الخيارات:',
  'directory.filters.reset': 'إعادة تعيين',
  'directory.filters.save': 'حفظ',
  'directory.noFavourites.message': 'ليس لديك أي مفضلات حتى الآن.',
  'directory.noFavourites.title': 'لا يوجد مفضلات',
  'directory.noRecommendations.message': 'لا توجد نتائج لعرضها.',
  'directory.noRecommendations.title': 'لا توجد نتائج',
  'directory.noResults.message': 'لم نتمكن من العثور على أي نتائج، حاول تحسين استفسارك',
  'directory.noResults.title': 'لا توجد نتائج',
  'directory.noSavedSearches.message': 'لا توجد عمليات بحث محفوظة. ابدأ <a href="?initialTab=search">البحث ...</a>',
  'directory.noSavedSearches.title': 'لا توجد عمليات بحث',
  'directory.saved_search_card_error': 'حدث خطأ ما، يرجى تحديث المتصفح',
  'directory.notification_frequency': 'أبلغني عن نتائج البحث الجديدة',
  'directory.search_last_run': 'آخر تشغيل: {{ date }}',
  'directory.pagination.load_more': 'تحميل المزيد من النتائج',
  'directory.save_search': 'حفظ البحث',
  'directory.search_name': 'اسم البحث',
  'directory.text_search': 'البحث: {{ value }}',
  'directory.search': 'العودة إلى عمليات البحث',
  'directory.initial_search': 'أولي: {{ value }}',
  'directory.match_made.no_blocked_contacts_message': 'ليس لديك جهات اتصال محظورة',
  'directory.match_made.no_matched_contacts_message': 'ليس لديك جهات اتصال متطابقة',
  'directory.match_made.blocked_contacts': 'جهات الاتصال المحظورة',
  'directory.match_made.matched_contacts': 'جهات الاتصال المطابقة',
  'directory.match_made.view_profile': 'عرض الملف الشخصي',
  'directory.match_made.remove_block': 'إزالة الحظر',
  'directory.match_made.convert_to_match': 'تحويل إلى مطابقة',
  'directory.match_made.remove_match': 'إزالة المطابقة',
  'directory.match_made.convert_to_block': 'تحويل إلى حظر',
  'directory.match_made.start_matching': 'ابدأ المطابقة',

  'drop_image.buttons.cancel': 'إلغاء',
  'drop_image.buttons.clear': 'مسح',
  'drop_image.buttons.remove': 'إزالة',
  'drop_image.buttons.submit': 'إرسال',
  'drop_image.preview_text': 'معاينة {{ image_name }}',
  'drop_image.text': 'السحب والإفلات أو انقر هنا',
  'event_editions.card.coming_soon': 'قريبًا',
  'event_editions.card.event_closed': 'تم إغلاق الحدث',
  'event_editions.card.launch': 'إطلاق الحدث',
  'event_editions.card.notify_me': 'أبلغني',
  'event_editions.card.reg_done': 'تم تسجيلك في هذا الحدث',
  'event_editions.card.reg_open': 'التسجيل مفتوح الآن',
  'event_editions.card.reg_soon': 'التسجيل قريبًا',
  'event_editions.card.register': 'سجل الآن!',
  'expanded_contact.buttons.accept_invite': 'قبول',
  'expanded_contact.buttons.book_meeting': 'حجز اجتماع',
  'expanded_contact.buttons.decline_invite': 'رفض',
  'expanded_contact.buttons.forward_to_colleague': 'إعادة توجيه',
  'expanded_contact.buttons.withdraw_invite': 'سحب',
  'expanded_contact.buttons.match': 'تطابق',
  'expanded_contact.buttons.remove': 'إزالة',
  'expanded_contact.buttons.block': 'حظر',
  'expanded_contact.buttons.blocked': 'محظور',
  'expanded_contact.buttons.matched': 'مطابق',
  'expanded_contact.meeting.addColleague.button': 'إضافة الزملاء',
  'expanded_contact.meeting.addColleague.noColleagues': 'لا يوجد زملاء متاحين',
  'expanded_contact.meeting.addColleague.subheader': 'إضافة الزملاء',
  'expanded_contact.meeting.back': 'العودة إلى تفاصيل الاجتماع',
  'expanded_contact.meeting.cancel.button': 'إرسال إلغاء',
  'expanded_contact.meeting.cancel.subheader': 'لم أعد قادراً على حضور هذا الاجتماع',
  'expanded_contact.meeting.cancelledHeader': 'تم إلغاء الاجتماع',
  'expanded_contact.meeting.contactNumber': 'رقم الجوال: ',
  'expanded_contact.meeting.forwardToColleague.button': 'اختر زميل',
  'expanded_contact.meeting.forwardToColleague.subheader': 'إعادة توجيه الاجتماع إلى زميل',
  'expanded_contact.meeting.informationHeader': 'معلومات الاجتماع',
  'expanded_contact.meeting.informationSubheader': 'سنرسل رسالة نصية تذكيرية قبل 10 دقائق من بدء الاجتماع.',
  'expanded_contact.meeting.inviteColleagues.button': 'دعوة الزملاء',
  'expanded_contact.meeting.inviteColleagues.subheader': 'إعادة توجيه الاجتماع إلى زميل',
  'expanded_contact.meeting.missingContactNumber': 'يرجى إضافة رقم جوال',
  'expanded_contact.meeting.participants': 'المشاركون',
  'expanded_contact.meeting.reschedule.button': 'إعادة جدولة الاجتماع',
  'expanded_contact.meeting.reschedule.subheader': 'تغيير تاريخ، وقت أو مكان الاجتماع',
  'expanded_contact.show.about.company.title': 'معلومات الشركة',
  'expanded_contact.show.about.contact.country': 'الدولة',
  'expanded_contact.show.about.contact.looking_for': 'يبحث عن',
  'expanded_contact.show.about.contact.title': 'عني',
  'expanded_contact.show.colleagues': 'الزملاء',
  'expanded_contact.show.errors.cannot_load': 'لم نتمكن من تحميل معلومات الاتصال.',
  'expanded_contact.show.other_contacts': 'جهات اتصال أخرى في {{ company_display_name }}',
  'expanded_contact.show.respond': 'الرد',
  'expanded_contact.show.send_invite': 'إرسال دعوة',
  'expanded_contact.show.view_invite': 'عرض الدعوة',
  'expanded_contact.show.view_meeting': 'عرض الاجتماع',
  'expanded_contact.show.view_profile': 'عرض الملف الشخصي',
  'expanded_contact.match_score_tooltip_text': 'درجة التطابق – <a href="{{ url }}">اعرف أكثر</a>',

  // الأحداث الحصرية
  'exclusive_events.preview_session.title': 'تفاصيل الحدث',
  'exclusive_events.no_description': 'لا يوجد وصف لهذا الحدث.',
  'exclusive_events.row.buttons.booking_capacity_full': 'السعة ممتلئة',
  'exclusive_events.row.buttons.booking_add_to_schedule': 'أضف إلى الجدول',
  'exclusive_events.row.buttons.booking_schedule_clash': 'تعارض في الجدول',
  'exclusive_events.row.buttons.booking_remove_from_schedule': 'إزالة من الجدول',

  'filter_modal.cancel': 'إلغاء',
  'filter_modal.save_update': 'حفظ وتحديث',
  'form.image.changeTip': '<a href=\'#\'>انقر للتغيير</a> أو السحب والإفلات. <br/>',
  'form.image.maxSize': 'الحد الأقصى لحجم الملف 5 ميجا بايت',
  'form.image.tip': '<a href=\'#\'>انقر للتحميل</a> أو السحب والإفلات. <br/>',
  'form.image.unsupportedFileType': 'نوع الملف غير صالح. استخدم jpg، jpeg، png أو webp',
  'form.image.recommended_size': 'الحجم الموصى به: {{recommended_size}}',
  'form.phone.placeholder': 'أدخل رقم هاتفك',
  'form.video.tip': '<a href=\'#\'>انقر للتحميل</a> أو السحب والإفلات. <br/>',
  'form.unsupportedFileTypePdf': 'نوع الملف غير مدعوم.',
  'forms.select_all': '(الكل)',
  'forms.toggle.on': 'تشغيل',
  'forms.toggle.off': 'إيقاف',
  'general.unknown_error': 'عذرًا، حدث خطأ ما. يرجى تحديث المتصفح والمحاولة مرة أخرى',

  // hubs general page
  'hubs.header.stand': 'المنصة: {{ stand }}',
  'hubs.companyProfile': 'الملف الشخصي للشركة',
  'hubs.definitions.confirmedMeetings': 'الاجتماعات المؤكدة',
  'hubs.definitions.receivedPendingInvites': 'دعوات الاجتماعات المستلمة المفتوحة',
  'hubs.definitions.sentPendingInvites': 'دعوات الاجتماعات المرسلة المفتوحة',
  'hubs.definitions.sentInvites': 'دعوات الاجتماعات المرسلة (جميع الحالات)',

  // الملف الشخصي للأحداث في المحاور
  'hubs.eventProfile.activity.favourited': 'تم الإضافة إلى المفضلة',
  'hubs.eventProfile.activity.hides': 'تم الإخفاء',
  'hubs.eventProfile.activity.marketplacePerformance': 'الأداء',
  'hubs.eventProfile.activity.marketplacePerformanceScore': 'أعلى {{score}}٪',
  'hubs.eventProfile.activity.marketplaceViews': 'المشاهدات',
  'hubs.eventProfile.activity.noData': 'بيانات غير كافية',
  'hubs.eventProfile.activity.performance': 'أداء الملف الشخصي',
  'hubs.eventProfile.activity.profileViews': 'مشاهدات الملف الشخصي',
  'hubs.eventProfile.catalogueActive': 'المدخلات النشطة',
  'hubs.eventProfile.catalogueDescription': 'استخدم دلائل الكتالوج للترويج لمنتجاتك وخدماتك.',
  'hubs.eventProfile.catalogueTitle': 'مدخلات الكتالوج',
  'hubs.eventProfile.countPlays': '{{count}} تشغيلات',
  'hubs.eventProfile.description': 'قم بإدارة ملفك الشخصي والترويج لوجودك في الحدث.',
  'hubs.eventProfile.emptyList': 'لا توجد عناصر مدرجة. إدارة العناصر في <a href="#/my-account/company-profile">الملف الشخصي للشركة <i class="fa "></i></a>',
  'hubs.eventProfile.link': 'ملف الحدث',
  'hubs.eventProfile.marketplaceActive': 'نشط',
  'hubs.eventProfile.marketplaceDescription': 'قم بالترويج لمنتجاتك وخدماتك من خلال دلائل السوق.',
  'hubs.eventProfile.marketplaceTitle': 'السوق',
  'hubs.eventProfile.performanceScore': 'أعلى {{score}}٪',
  'hubs.eventProfile.title': 'ملف الحدث',
  'hubs.eventProfile.videos': 'الفيديوهات',
  'hubs.eventProfile.videosActive': 'الفيديوهات النشطة',
  'hubs.eventProfile.videosDescription': 'قم بإدارة الفيديوهات الترويجية الخاصة بك.',
  'hubs.eventProfile.videosTitle': 'الفيديوهات',
  'hubs.eventProfile.viewsPerDay': 'مشاهدات الملف الشخصي يوميًا',

  // رؤى المحاور
  'hubs.insights.activity': 'أداء الملف الشخصي للشركة',
  'hubs.insights.activity.favourited': 'أضيف إلى المفضلة',
  'hubs.insights.activity.hides': 'أضيف إلى المخفية',
  'hubs.insights.activity.profilePreviews': 'معاينات الملف الشخصي',
  'hubs.insights.activity.profileViews': 'مشاهدات صفحة الملف الشخصي',
  'hubs.insights.activity.noData': 'بيانات غير كافية لعرضها',
  'hubs.insights.description': 'مراجعة أداء الحدث الخاص بك.',
  'hubs.insights.meetings': 'أداء اجتماعات الشركة',
  'hubs.insights.link': 'رؤى',
  'hubs.insights.title': 'رؤى',

  // العملاء المحتملين للمحاور
  'hubs.leads.businessCardDropTable': 'بطاقات العمل المسقطة',
  'hubs.leads.businessCardDropTableDescription': 'الأشخاص التالية أسماؤهم قد أسقطوا بطاقات العمل الخاصة بهم على ملف شركتك للمتابعة.',
  'hubs.leads.description': 'الوصول إلى العملاء المحتملين وبطاقات العمل المسقطة.',
  'hubs.leads.download': 'تحميل العملاء المحتملين',
  'hubs.leads.downloadFormat': 'CSV / Excel',
  'hubs.leads.link': 'العملاء المحتملين',
  'hubs.leads.meetingsWith': 'اجتماعات مع {{people}}',
  'hubs.leads.noDroppedBusinessCards': 'لا توجد اتصالات قد أسقطت بطاقات العمل الخاصة بها بعد.',
  'hubs.leads.numberOfBusinessCardDrops': 'بطاقات العمل',
  'hubs.leads.receivedInvitesFrom': 'تم استلام دعوات من {{people}}',
  'hubs.leads.sentInvitesTo': 'تم إرسال دعوات إلى {{people}}',
  'hubs.leads.title': 'إدارة العملاء المحتملين',

  // عناصر الشراء
  'hubs.purchase_items.available': 'متاح',
  'hubs.purchase_items.emptyList': 'لا توجد عناصر شراء متاحة.',
  'hubs.purchase_items.link': 'الترقيات والعروض',
  'hubs.purchase_items.offers': 'العروض',
  'hubs.purchase_items.out_of_stock': 'غير متوفرة',
  'hubs.purchase_items.product_not_available': 'المنتج غير متوفر',
  'hubs.purchase_items.purchased': 'تم الشراء',
  'hubs.purchase_items.purchases': 'المشتريات',
  'hubs.purchase_items.someAvailable': '{{num}} متوفر',
  'hubs.purchases.purchased_at': 'تم الشراء في: ',
  'hubs.purchases.purchased_by': 'تم الشراء بواسطة: {{contact}}',

  // اجتماعات المحاور
  'hubs.meetings.confirmedMeetings': 'الاجتماعات',
  'hubs.meetings.description': 'مراجعة نشاط الاجتماعات المجدولة مسبقًا على مستوى الشركة.',
  'hubs.meetings.link': 'الاجتماعات',
  'hubs.meetings.MeetingsByDayTitle': 'الاجتماعات حسب اليوم',
  'hubs.meetings.MeetingsByLocationTitle': 'الاجتماعات حسب الموقع',
  'hubs.meetings.performance': 'أداء الاجتماعات',
  'hubs.meetings.performanceScore': 'أعلى {{score}}٪',
  'hubs.meetings.receivedInvites': 'الدعوات المعلقة',
  'hubs.meetings.sentInvites': 'الدعوات المرسلة',
  'hubs.meetings.teamHasMeetings': 'لديه اجتماعات',
  'hubs.meetings.teamHasPending': 'لديه اجتماعات معلقة',
  'hubs.meetings.teamHasSent': 'لديه اجتماعات مرسلة',
  'hubs.meetings.teamTitle': 'نشاط الاجتماعات',
  'hubs.meetings.title': 'الاجتماعات',
  'hubs.stats.average': 'متوسط',
  'hubs.stats.belowAverage': 'أقل من المتوسط',

  // جداول فريق الاجتماعات في المحاور
  'hubs.meetings.teamSchedules.unavailable': 'غير متاح',
  'hubs.meetings.teamSchedules': 'الجداول الزمنية المجمعة للفريق',
  'hubs.meetings.teamSchedules.instructions': 'حدد يومًا لعرض جدول الاتصالات لفترة معينة.',
  'hubs.meetings.teamSchedules.bookings.empty': 'لا توجد حجوزات خلال هذه الفترة.',
  'hubs.meetings.teamSchedules.combinedBookings.empty': 'لا توجد حجوزات خلال هذه الفترة.',

  // الفريق
  'hubs.team.activityChartSubtitle': 'آخر 60 يومًا',
  'hubs.team.activityChartTitle': 'نشاط الفريق - ',
  'hubs.team.badgeTable': 'فريق الحدث',
  'hubs.team.contactUpdatedPlatformProfile': 'الملفات الشخصية المحدثة',
  'hubs.team.description': 'مراقبة نشاط الفريق والحضور.',
  'hubs.team.lastLogin': 'آخر نشاط',
  'hubs.team.link': 'الفريق',
  'hubs.team.noOtherBadges': 'لا زملائك مسجلين في الحدث.',
  'hubs.team.profilesActivated': 'الملفات الشخصية المفعلة',
  'hubs.team.profilesHidden': 'الملفات الشخصية المخفية',
  'hubs.team.title': 'فريق الحدث',
  'hubs.team.register_colleague': 'تسجيل زميل',
  'hubs.team.register': 'تسجيل',
  'hubs.team.country': 'البلد',
  'hubs.team.email': 'البريد الإلكتروني',
  'hubs.team.firstName': 'الاسم الأول',
  'hubs.team.jobTitle': 'المسمى الوظيفي',
  'hubs.team.lastName': 'الكنية',

  'hubs.tasks.link': 'المهام',
  'hubs.tasks.title': 'المهام',
  'hubs.tasks.taskProgress': 'تقدم المهام',
  'hubs.tasks.pendingTasksTitle': 'المهام المعلقة',
  'hubs.tasks.pendingCompanyTasksDescription': 'يجب أن يتم إنجاز المهام التالية بواسطة ممثل من شركتكم.',
  'hubs.tasks.pendingContactTasksDescription': 'يجب أن يتم إنجاز المهام التالية من قبلك.',
  'hubs.tasks.noPendingTasks': 'لا توجد مهام لإكمالها.',
  'hubs.tasks.taskType.mandatory': 'مطلوب',
  'hubs.tasks.taskType.important': 'مهم',
  'hubs.tasks.taskType.optional': 'اختياري',
  'hubs.tasks.completedTasksTitle': 'المهام المكتملة',
  'hubs.tasks.completedTasksDescription': 'تم إتمام المهام التالية.',
  'hubs.tasks.nocompletedTasks': 'ليس لديك مهام مكتملة.',
  'hubs.tasks.start': 'ابدأ',
  'hubs.tasks.expired': 'منتهي الصلاحية',
  'hubs.tasks.view': 'عرض',
  'hubs.tasks.modal.title': 'تفاصيل المهمة',
  'hubs.tasks.modal.cancel': 'إلغاء',
  'hubs.tasks.modal.markAsRead': 'وضع علامة كمقروء',
  'hubs.tasks.modal.submit': 'إرسال',
  'hubs.tasks.modal.close': 'إغلاق',
  'hubs.tasks.completed_by': 'اكتملت بواسطة {{ responder }} @ {{ when }}',
  'hubs.tasks.file_submitted': 'لقد قمت بتحميل ملف بالفعل',
  'hubs.tasks.file_download': 'تحميل الملف',
  'hubs.tasks.user_file_download': 'تحميل الملف',
  'hubs.tasks.deadline': 'الموعد النهائي',
  'hubs.tasks.completed': 'منجز',

  'inbox.header': 'صندوق الوارد',
  'inbox.invites.buttons.accept': 'قبول الاجتماع',
  'inbox.invites.buttons.askQuestion': 'إرسال رسالة',
  'inbox.invites.buttons.decline': 'رفض',
  'inbox.invites.buttons.forward': 'إعادة توجيه',
  'inbox.invites.buttons.moreOptions': 'خيارات أخرى ...',
  'inbox.invites.buttons.next_invite': 'الدعوة التالية',
  'inbox.invites.buttons.read_more': 'المزيد',
  'inbox.invites.buttons.respond': 'الرد',
  'inbox.invites.buttons.show_less': 'إظهار أقل',
  'inbox.invites.buttons.view': 'عرض الدعوة',
  'inbox.invites.buttons.viewProfile': 'عرض الملف الشخصي',
  'inbox.invites.buttons.withdraw': 'سحب',
  'inbox.invites.declinedByActiveUser': 'لقد رفضت هذه الدعوة {{ declined_time }}',
  'inbox.invites.declinedByRecipient': '{{ declined_by }} رفض هذه الدعوة {{ declined_time }}',
  'inbox.invites.expiry.expired': 'منتهي الصلاحية',
  'inbox.invites.expiry.expires': 'تنتهي',
  'inbox.invites.expiry.expiresSoon': 'لديك دعوات اجتماعات تنتهي قريبًا.',
  'inbox.invites.expiry.whenExpired': 'لديك دعوات اجتماع منتهية الصلاحية.',
  'inbox.invites.findPeople': 'بحث',
  'inbox.invites.forwardedByActiveUser': 'لقد قمت بإعادة توجيه هذه الدعوة إلى {{ forwardee }}',
  'inbox.invites.forwardedByColleague': '{{ forwarded_by }} أعاد توجيه دعوة الاجتماع إليك.',
  'inbox.invites.forwardedByRecipient': 'تمت إعادة توجيهها إلى {{ forwardee }} من قبل {{ forwarded_by }}',
  'inbox.invites.info.sent_message_concierge': 'فريق خدمات الاجتماعات سيتواصل مع هذا المستخدم إذا لم يستجب قريبًا',
  'inbox.invites.noResults.message': '<a href="{{ findPeopleUrl }}">البحث</a> عن فرص اجتماعات جديدة.',
  'inbox.invites.noResults.title': 'لا توجد دعوات اجتماع',
  'inbox.invites.sort.newest': 'الأحدث',
  'inbox.invites.sort.oldest': 'الأقدم',
  'inbox.invites.sort.placeholder': 'فرز',
  'inbox.invites.statuses.accepted': 'تم القبول',
  'inbox.invites.statuses.declined': 'مرفوض',
  'inbox.invites.statuses.expired': 'منتهي الصلاحية',
  'inbox.invites.statuses.forwarded': 'تم إعادة التوجيه',
  'inbox.invites.statuses.pending': 'في انتظار الرد',
  'inbox.invites.statuses.sent': 'مُرسَل',
  'inbox.invites.statuses.unread': 'رسالة جديدة',
  'inbox.invites.statuses.withdrawn': 'تم السحب',
  'inbox.invites.statusFilters.accepted': 'تم القبول',
  'inbox.invites.statusFilters.declined': 'مرفوض',
  'inbox.invites.statusFilters.expired': 'منتهي الصلاحية',
  'inbox.invites.statusFilters.forwarded': 'تم إعادة التوجيه',
  'inbox.invites.statusFilters.pending': 'يتطلب إجراء',
  'inbox.invites.statusFilters.sent': 'تم الإرسال',
  'inbox.invites.statusFilters.withdrawn': 'تم السحب',
  'inbox.invites.whenReceived': 'تم الاستلام',
  'inbox.invites.whenSent': 'تم الإرسال',
  'inbox.invites.withdrawnByActiveUser': 'سحبت هذه الدعوة {{ withdrawn_time }}',
  'inbox.invites.withdrawnBySender': '{{ withdrawn_by }} سحب هذه الدعوة {{ withdrawn_time }}',
  'inbox.loading': 'جارٍ التحميل...',
  'inbox.meetings.buttons.addColleagues': 'دعوة الزملاء',
  'inbox.meetings.buttons.cancel': 'إلغاء الاجتماع',
  'inbox.meetings.buttons.moreOptions': 'خيارات أخرى ...',
  'inbox.meetings.buttons.rescheduleMeeting': 'إعادة جدولة الاجتماع',
  'inbox.meetings.cancelledBy': '{{ cancelled_by }} ألغى هذا الاجتماع {{ cancel_time }}',
  'inbox.meetings.cancelledByActiveUser': 'لقد ألغيت هذا الاجتماع {{ cancel_time }}',
  'inbox.meetings.activeContactColleagueMeeting': 'زميلك {{ colleague_name }} لديه بالفعل اجتماع محجوز مع {{ contact_name }}',
  'inbox.meetings.previouslyCancelledByActiveUser': 'لقد ألغيت سابقًا اجتماعًا مع {{ contact_name }} {{ cancel_time }}',
  'inbox.meetings.filters.allDays': 'الكل',
  'inbox.meetings.filters.showCancelled': 'إظهار الاجتماعات الملغاة',
  'inbox.meetings.meetingsToRate_one': 'لديك اجتماع واحد لتقييم. <a href="#/meetings/{{ nextId }}/rate">قيم الآن</a>',
  'inbox.meetings.meetingsToRate_other': 'لديك {{ count }} اجتماعات لتقييم. <a href="#/meetings/{{ nextId }}/rate">قيم الآن</a>',
  'inbox.meetings.noResults.message': '<a href="{{ findPeopleUrl }}">البحث</a> عن فرص اجتماعات جديدة.',
  'inbox.meetings.noResults.title': 'ليس لديك اجتماعات حتى الآن',
  'inbox.meetings.rate': 'تقييم الاجتماع',
  'inbox.meetings.statuses.cancelled': 'ملغى',
  'inbox.meetings.statuses.confirmed': 'اجتماع مؤكد',
  'inbox.meetings.view': 'معلومات الاجتماع',
  'inbox.messages.info.moreMessagesInThread': 'توجد رسائل أخرى في هذه المحادثة',
  'inbox.messages.info.newUnreadMessage': 'رسالة دردشة جديدة. <a href="{{url}}">رد</a>',
  'inbox.messages.meetingInformation': 'معلومات الاجتماع',
  'inbox.messages.noResults.message': 'ليس لديك رسائل.',
  'inbox.messages.noResults.title': 'لا توجد رسائل',
  'inbox.messages.sort.newest': 'الأحدث',
  'inbox.messages.sort.oldest': 'الأقدم',
  'inbox.messages.statusFilters.all': 'الكل',
  'inbox.messages.statusFilters.unread': 'غير مقروءة',
  'inbox.messages.whenReceived': 'تم الاستلام',
  'inbox.messages.whenSent': 'تم الإرسال',
  'inbox.nextPage': 'الصفحة التالية',
  'inbox.previousPage': 'الصفحة السابقة',
  'inbox.relationshipLabels.from': 'من:',
  'inbox.relationshipLabels.to': 'إلى:',
  'inbox.relationshipLabels.with': 'مع:',
  'inbox.subtitle.meetings': 'الاجتماعات المؤكدة',
  'inbox.subtitle.messages': 'الرسائل',
  'inbox.subtitle.null': ' ',
  'inbox.subtitle.receivedInvites': 'الدعوات المستلمة',
  'inbox.subtitle.sentInvites': 'الدعوات المرسلة',

  'meeting.add_colleagues.buttons.add': 'إضافة زملاء',
  'meeting.add_colleagues.buttons.cancel': 'إلغاء',
  'meeting.add_colleagues.buttons.done': 'تم',
  'meeting.add_colleagues.buttons.invite_more': 'دعوة المزيد',
  'meeting.add_colleagues.header': 'إضافة زملاء',
  'meeting.add_colleagues.no_colleagues': 'لا يوجد زملاء متاحين للإضافة',
  'meeting.add_colleagues.subheader': 'إضافة زملاء إلى اجتماعك مع <strong>{{display_name}}, {{job_title}}</strong> في <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting.add_colleagues.success.message_one': 'لقد أضفنا زميلك بنجاح إلى هذا الاجتماع.',
  'meeting.add_colleagues.success.message_other': 'لقد أضفنا زملائك بنجاح إلى هذا الاجتماع.',
  'meeting.add_colleagues.success.title_one': 'تمت إضافة زميل',
  'meeting.add_colleagues.success.title_other': 'تمت إضافة الزملاء',
  'meeting.addColleague.button': 'إضافة زملاء',
  'meeting.addToCalendar': 'إضافة إلى التقويم',
  'meeting.attendees': 'أنت و{{ displayName }}',
  'meeting.attendeesCount': '{{count}} أشخاص',
  'meeting.attendeesTitle': 'المشاركين',
  'meeting.back_to_meeting': 'العودة إلى الاجتماع',
  'meeting.cancel.button': 'إرسال الإلغاء',
  'meeting.cancel.cannotAttend': 'لم أعد قادرًا على حضور هذا الاجتماع',
  'meeting.cancel.keep': 'الاحتفاظ بالاجتماع',
  'meeting.cancel.messageLabel': 'ملاحظة',
  'meeting.cancel.messagePlaceholder': 'للأسف يجب علي إلغاء هذا الاجتماع لأن ...',
  'meeting.cancel.onlyOrganiserCanCancel': 'فقط المنظم الرئيسي يمكنه إلغاء الاجتماع',
  'meeting.cancel.reasonLabel': 'سبب الإلغاء',
  'meeting.cancel.reasonPlaceholder': 'اختر خيار...',
  'meeting.cancel.subheader': 'إلغاء اجتماعك المجدول مع <strong> {{ displayName }}, {{ jobTitle }}</strong> في <a href="{{ companyUrl }}"> {{ companyDisplayName }} </a>.',
  'meeting.cancel.title': 'إلغاء الاجتماع',
  'meeting.cancellation_success.message': 'لقد ألغيت اجتماعك بنجاح مع <strong>{{display_name}}, {{job_title}}</strong> في <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting.cancellation_success.title': 'تم رفض الدعوة',
  'meeting.cancelledBy': 'تم إلغاء هذا الاجتماع بواسطة {{ cancelledBy_display_name }} {{cancelled_at_time}}',
  'meeting.close': 'إغلاق',
  'meeting.countdown_minutes': 'دقائق, ',
  'meeting.countdown_seconds': 'ثواني',
  'meeting.errors.cannot_load': 'تعذر تحميل الاجتماع.',
  'meeting.finished': 'انتهى الاجتماع.',
  'meeting.forward.button': 'حدد زميل',
  'meeting.forward.subheader': 'إعادة توجيه الاجتماع إلى زميل',
  'meeting.forward.title': 'إعادة التوجيه إلى زميل',
  'meeting.in_progress': 'الاجتماع قيد التقدم.',
  'meeting.join_now': 'انضم الآن',
  'meeting.meeting_confirmed.invite_colleagues': 'دعوة الزملاء',
  'meeting.meeting_confirmed.message': 'تم إبلاغ شريك الاجتماع الخاص بك.',
  'meeting.meeting_confirmed.next_invite': 'الدعوة التالية',
  'meeting.meeting_confirmed.title': 'تم تأكيد الاجتماع',
  'meeting.meeting_confirmed.view_meeting': 'عرض التفاصيل',
  'meeting.meeting_starts_in_countdown': 'يبدأ الاجتماع في',
  'meeting.meetingCancelled': 'تم إلغاء الاجتماع',
  'meeting.meetingConfirmed': 'تم التأكيد بالكامل من قبل جميع الأطراف.',
  'meeting.mobileNumberLabel': 'رقم الجوال:',
  'meeting.panelTitle.cancelled': 'تم إلغاء الاجتماع',
  'meeting.panelTitle.information': 'معلومات الاجتماع',
  'meeting.phoneNumberMissing': '<a href="{{ updatePhoneUrl }}">يرجى إضافة رقم الجوال</a>',
  'meeting.rate_meeting.back_to_meeting': 'العودة إلى الاجتماع',
  'meeting.rate_meeting.buttons.cancel': 'إلغاء',
  'meeting.rate_meeting.buttons.done': 'تم',
  'meeting.rate_meeting.buttons.next': 'التالي',
  'meeting.rate_meeting.buttons.rate': 'تقييم',
  'meeting.rate_meeting.buttons.save': 'حفظ الملاحظات',
  'meeting.rate_meeting.errors.already_rated': 'لقد تم تقييم هذا الاجتماع بالفعل',
  'meeting.rate_meeting.fields.feedback.label': 'ملاحظات الاجتماع (اختياري)',
  'meeting.rate_meeting.fields.meeting_did_not_happen.label': 'الاجتماع لم يحدث',
  'meeting.rate_meeting.fields.rating.label': 'قيم اجتماعك',
  'meeting.rate_meeting.header': 'قيم اجتماعك',
  'meeting.rate_meeting.subheader': 'كيف كان اجتماعك مع <strong>{{display_name}}, {{job_title}}</strong> من {{company_display_name}}؟',
  'meeting.rate_meeting.success.title': 'تم تقديم التقييم',
  'meeting.reminder': 'سنرسل تذكيرًا برسالة نصية قبل 10 دقائق من بدء الاجتماع.',
  'meeting.reschedule.back_to_meeting': 'العودة إلى الاجتماع',
  'meeting.reschedule.button': 'إعادة جدولة الاجتماع',
  'meeting.reschedule.onlyOrganiserCanReschedule': 'فقط المنظم الرئيسي يمكنه إعادة جدولة الاجتماع',
  'meeting.reschedule.subheader': 'تغيير تاريخ ووقت أو موقع الاجتماع',
  'meeting.reschedule.title': 'إعادة جدولة الاجتماع',
  'meeting.source_types.auto_accepted': 'تم القبول تلقائيًا',
  'meeting.source_types.ez_match': 'مطابقة تلقائية',
  'meeting.source_types.concierge_booked': 'حجز بواسطة خدمة الكونسيرج',
  'meeting.source_types.walk_up': 'حجز في الموقع',
  'meeting.stand': 'منصة {{ stand_number }}',
  'meeting.startingTime': 'الاجتماع يبدأ في {{ time_until_meeting }}',
  'meeting.statuses.cancelled': 'ملغى',
  'meeting.updatePhone': 'تحديث الرقم',
  'meeting_invite.back_to_conversation': 'العودة إلى المحادثة',
  'meeting_invite.decline.buttons.cancel': 'إلغاء',
  'meeting_invite.decline.buttons.decline': 'رفض الدعوة',
  'meeting_invite.decline.buttons.done': 'تم',
  'meeting_invite.decline.errors.cannot_load': 'لا يمكن تحميل الدعوة.',
  'meeting_invite.decline.header': 'رفض دعوة الاجتماع',
  'meeting_invite.decline.inputs.message.label': 'إضافة رسالة',
  'meeting_invite.decline.inputs.reason.label': 'سبب الرفض',
  'meeting_invite.decline.inputs.reason.placeholder': 'اختر السبب',
  'meeting_invite.decline.subheader': 'ارفض هذه الدعوة للاجتماع من <strong>{{display_name}}, {{job_title}}</strong> في <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.decline.success.message': 'لقد رفضت بنجاح دعوة الاجتماع من <strong>{{display_name}}, {{job_title}}</strong> في <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.decline.success.title': 'تم رفض الدعوة',
  'meeting_invite.forward_invite.buttons.cancel': 'ابقاء الدعوة',
  'meeting_invite.forward_invite.buttons.done': 'تم',
  'meeting_invite.forward_invite.buttons.forward': 'إعادة توجيه الدعوة',
  'meeting_invite.forward_invite.header': 'إعادة التوجيه إلى زميل',
  'meeting_invite.forward_invite.inputs.forward_to_colleague.label': 'أضف ملاحظة لزميلك:',
  'meeting_invite.forward_invite.inputs.forward_to_colleague.placeholder': 'لقد تلقيت دعوة لاجتماع يهمك أكثر...',
  'meeting_invite.forward_invite.subheader': 'أعد توجيه دعوتك للاجتماع من <strong>{{display_name}}, {{job_title}}</strong> في <a href="{{company_url}}">{{company_display_name}}</a> إلى زميل',
  'meeting_invite.forward_invite.success.message': 'لقد أعدنا توجيه دعوة الاجتماع هذه إلى زميلك بنجاح.',
  'meeting_invite.forward_invite.success.title': 'تم توجيه الدعوة إلى الزميل',
  'meeting_invite.opt_in_meetings.accept': 'نعم، الانضمام إلى الشبكات',
  'meeting_invite.opt_in_meetings.decline': 'لا، لا أريد استخدام الشبكات',
  'meeting_invite.opt_in_meetings.opt_in_meetings_text': 'اشترك في الشبكات للعثور على أشخاص، جدولة الاجتماعات وتلقي دعوات الاجتماع من جهات الاتصال الأخرى في الحدث.',
  'meeting_invite.opt_in_meetings.previously_requested_opt_out': 'لقد طلبت مسبقًا عدم استخدام خدمة الشبكات.',
  'meeting_invite.opt_in_meetings.title': 'الاشتراك في الشبكات',
  'meeting_invite.pending_invites.limit_reached.body': 'ستكون قادرًا على إرسال المزيد من الدعوات عندما يتم قبول دعواتك الحالية للاجتماع.',
  'meeting_invite.pending_invites.limit_reached.title': 'تم الوصول إلى حد الدعوات',
  'meeting_invite.pending_invites.remaining': '<i class="fa-solid fa-circle-info"></i> لقد استخدمت <strong>{{ invite_spent }} من {{ invite_credits }}</strong> الدعوات المعلقة.',
  'meeting_invite.send_invite.buttons.cancel': 'إلغاء',
  'meeting_invite.send_invite.buttons.done': 'تم',
  'meeting_invite.send_invite.buttons.send': 'إرسال الدعوة',
  'meeting_invite.send_invite.buttons.view_sent_invites': 'الدعوات المرسلة',
  'meeting_invite.send_invite.errors.cannot_send_invite': 'لا يمكن إرسال الدعوة إلى هذا الاتصال.',
  'meeting_invite.send_invite.header': 'إنشاء دعوتك للاجتماع',
  'meeting_invite.send_invite.message_placeholder': 'مرحبًا، أود أن نجتمع لمناقشة ...',
  'meeting_invite.send_invite.sub_text': 'سيقوم شريكك في الاجتماع بتأكيد تاريخ وموعد الاجتماع.',
  'meeting_invite.send_invite.success.message': 'سيقوم شريكك في الاجتماع بمراجعة دعوتك وتأكيد التاريخ، الوقت، والموقع بناءً على توفرهما.',
  'meeting_invite.send_invite.success.title': 'تم إرسال الدعوة',
  'meeting_invite.send_invite.success.update_availability': 'تحديث توفراتي',
  'meeting_invite.withdraw.buttons.cancel': 'إلغاء',
  'meeting_invite.withdraw.buttons.done': 'تم',
  'meeting_invite.withdraw.buttons.withdraw': 'سحب الدعوة',
  'meeting_invite.withdraw.errors.cannot_load': 'لا يمكن تحميل الدعوة.',
  'meeting_invite.withdraw.header': 'سحب الدعوة',
  'meeting_invite.withdraw.inputs.message.label': 'إضافة رسالة',
  'meeting_invite.withdraw.inputs.reason.label': 'سبب السحب',
  'meeting_invite.withdraw.inputs.reason.placeholder': 'اختر السبب',
  'meeting_invite.withdraw.subheader': 'اسحب هذه الدعوة للاجتماع من <strong>{{display_name}}, {{job_title}}</strong> في <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.withdraw.success.message': 'لقد سحبت بنجاح دعوة الاجتماع لـ <strong>{{display_name}}, {{job_title}}</strong> في <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.withdraw.success.title': 'تم سحب الدعوة',
  'meeting_scheduler.acceptInvitation': 'قبول الدعوة',
  'meeting_scheduler.buttons.back': 'العودة',
  'meeting_scheduler.buttons.done': 'تم',
  'meeting_scheduler.buttons.next': 'التالية',
  'meeting_scheduler.errors.invite_already_actioned': 'تم تنفيذ هذه الدعوة بالفعل.',
  'meeting_scheduler.errors.invite_already_actioned_link': '<a href="#/contacts/{{ invite_counterpart_contact_id }}/conversation">عرض</a>',
  'meeting_scheduler.errors.no_available_slots': 'لا توجد أماكن متاحة للاجتماعات.',
  'meeting_scheduler.floorplanClickForFull': 'مخطط الصفحة بالكامل',
  'meeting_scheduler.meetingUpdatedTitle': 'تم تحديث الاجتماع',
  'meeting_scheduler.rescheduleMeeting': 'إعادة جدولة الاجتماع',
  'meeting_scheduler.standLocation': '{{ companyName }} المنصة {{ standNumberFull }}',
  'meeting_scheduler.step.date': 'التاريخ',
  'meeting_scheduler.step.location': 'الموقع',
  'meeting_scheduler.step.time': 'الوقت',
  'meeting_scheduler.unavailable': 'غير متاح',
  'meeting_scheduler.recommended_heading': 'إليك <strong>الوقت والمكان الموصى بهما</strong> للاجتماع بناءً على التوفر:',
  'meeting_scheduler.buttons.choose_my_own': 'اختيار خاص بي',
  'meeting_scheduler.buttons.accept_recommended': 'قبول',
  'multi_links.add_link': 'إضافة رابط',
  'multi_links.address': 'مثال: https://www.{address}.com/...',
  'multi_links.error': 'يرجى التأكد من تعيين النوع وعنوان URL.',
  'multi_links.please_select': 'يرجى الاختيار',
  'multi_links.title': 'الروابط',
  'multi_links.type': 'النوع',
  'mux_video_upload.buttons.cancel': 'إلغاء',
  'mux_video_upload.buttons.delete': 'حذف',
  'mux_video_upload.buttons.rename': 'إعادة تسمية',
  'mux_video_upload.buttons.save': 'حفظ',
  'mux_video_upload.buttons.upload': 'تحميل',
  'mux_video_upload.error_text': 'حدث خطأ أثناء تحميل الفيديو الخاص بك، يرجى المحاولة مرة أخرى',
  'mux_video_upload.input.label': 'العنوان',
  'mux_video_upload.success_text': 'تم تحميل ملفك بنجاح، سيكون جاهزًا قريبًا',
  'mux_video_upload.video_status.created': 'جديد',
  'mux_video_upload.video_status.deleted': 'تم الحذف',
  'mux_video_upload.video_status.errored': 'حدث خطأ',
  'mux_video_upload.video_status.new_video': 'اختر ملف وقم بتحميله',
  'mux_video_upload.video_status.processing': 'جارٍ المعالجة',
  'mux_video_upload.video_status.ready': 'جاهز',
  'mux_video_upload.video_status.removed': 'تمت الإزالة',
  'mux_video_upload.video_status.upload-cancelled': 'تم إلغاء التحميل',
  'mux_video_upload.video_status.upload-failed': 'فشل في التحميل',
  'mux_video_upload.video_status.upload_failed': 'تحميل غير مكتمل، يرجى الحذف والمحاولة مرة أخرى',
  'mux_video_upload.video_status.uploading': 'يتم التحميل',
  'mux_video_upload.video_status.waiting': 'الانتظار',
  'my_schedule.bookings.share_schedule': 'مشاركة الجدول',
  'my_schedule.bookings.share_no_colleagues': 'لم يتم العثور على زملاء مؤهلين',
  'my_schedule.bookings.source_types.auto_accepted': 'تم القبول تلقائيًا',
  'my_schedule.bookings.source_types.ez_match': 'تطابق تلقائي',
  'my_schedule.bookings.source_types.concierge_booked': 'خدمة مساعد',
  'my_schedule.bookings.source_types.walk_up': 'حجز في الموقع',
  'my_schedule.bookings.table_number': '(الطاولة {{ number }})',
  'my_schedule.other_bookings.buttons.add': 'إضافة',
  'my_schedule.other_bookings.buttons.addOther': 'أخرى',
  'my_schedule.other_bookings.buttons.cancel': 'إلغاء',
  'my_schedule.other_bookings.buttons.delete': 'حذف',
  'my_schedule.other_bookings.buttons.no_undos': 'لا يمكن التراجع عن هذا الإجراء.',
  'my_schedule.other_bookings.buttons.update': 'تحديث',
  'my_schedule.other_bookings.fields.description.label': 'تفاصيل',
  'my_schedule.other_bookings.fields.description.placeholder': 'غداء مع المورد',
  'my_schedule.other_bookings.fields.end_datetime.label': 'النهاية',
  'my_schedule.other_bookings.fields.start_datetime.label': 'البداية',
  'my_schedule.other_bookings.header_add': 'إضافة',
  'my_schedule.other_bookings.header_update': 'تحديث',
  'my_schedule.other_bookings.sub_text': "سيظهر حالتك على أنك 'غير متاح' خلال هذا الوقت.",
  'needHelp.body': 'تواصل مع فريق مساعد الفعالية.',
  'needHelp.dataPrivacy.body': 'تخضع العملاء الذين جمعناهم لشروط الاستخدام وسياسة الخصوصية.',
  'needHelp.dataPrivacy.header': 'الخصوصية',
  'needHelp.dataPrivacy.link': 'سياسة الخصوصية',
  'needHelp.header': 'تحتاج مساعدة؟',
  'needHelp.link': 'اتصل بنا',
  'profile.account_settings.email': 'البريد الإلكتروني',
  'profile.account_settings.first_name': 'الاسم الأول',
  'profile.account_settings.last_name': 'الاسم الأخير',
  'profile.account_settings.salutation': 'التحية',
  'profile.account_settings.title': 'إعدادات الحساب',
  'profile.buttons.cancel': 'إلغاء',
  'profile.buttons.edit': 'تحرير',
  'profile.buttons.save': 'حفظ',
  'profile.communication_preferences.email': 'إخطارات ورسائل تذكير البريد الإلكتروني',
  'profile.communication_preferences.phone': 'الاتصال الهاتفي للدعم',
  'profile.communication_preferences.sms': 'إخطارات ورسائل تذكير نصية',
  'profile.communication_preferences.something_went_wrong': 'عذرًا - حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا',
  'profile.communication_preferences.subheader': 'إدارة تفضيلات برنامج الاجتماعات الخاص بك',
  'profile.communication_preferences.title': 'تفضيلات التواصل',
  'profile.demographics.subheader': 'املأ هذه المعلومات لمساعدة المستخدمين الآخرين في العثور عليك في عمليات البحث',
  'profile.demographics.title': 'معلومات البحث',
  'profile.forms.catalogueEntry.create.modalTitle': 'إضافة جديد {{ name, lowercase }}',
  'profile.forms.catalogueEntry.edit.modalTitle': 'تحرير {{ name, lowercase }}',
  'profile.forms.image.create.heading': 'أضف صورة جديدة إلى كتالوج المنتجات الخاص بك.',
  'profile.forms.image.create.modalTitle': 'إضافة صورة',
  'profile.forms.image.edit.heading': 'تغيير عنوان صورتك',
  'profile.forms.image.edit.modalTitle': 'تحرير الصورة',
  'profile.forms.image.progressMessage': 'يتم تحميل صورتك، يرجى الانتظار',
  'profile.forms.video.create.modalTitle': 'تحميل فيديو',
  'profile.forms.video.edit.modalTitle': 'تحرير الفيديو',
  'profile.forms.video.progressMessage': 'يتم تحميل الفيديو الخاص بك، يرجى الانتظار',
  'profile.groups.aboutMe.heading': 'عني',
  'profile.groups.accountAccess.heading': 'وصول الحساب',
  'profile.groups.accountAccess.subheading': 'يمكن لهؤلاء المستخدمين إدارة ملفك الشخصي والدردشة ونشاط الاجتماعات.',
  'profile.groups.catalogue.heading': '{{ plural }}',
  'profile.groups.companyDemographics.heading': 'معلومات أخرى',
  'profile.groups.companyInformation.heading': 'معلومات الشركة',
  'profile.groups.companyInformation.subheading': 'إدارة ملف الشركة الخاص بك',
  'profile.groups.contactInformation.heading': 'معلومات الاتصال',
  'profile.groups.eventOptouts.heading': 'الانسحابات من الفعالية',
  'profile.groups.password.heading': 'كلمة مرور الحساب',
  'profile.groups.password.lastChanged': 'آخر تغيير <b> {{ lastChangedAt }} </b>',
  'profile.groups.password.subheading': 'يجب أن تكون كلمة مرورك على الأقل 8 أحرف وتشمل حرفًا كبيرًا واحدًا، رقمًا واحدًا وحرفًا خاصًا واحدًا.',
  'profile.groups.searchInformation.heading': 'معلومات أخرى',
  'profile.groups.team.heading': 'أعضاء الفريق <small>({{ count }} أشخاص)</small>',
  'profile.groups.timezone.heading': 'المنطقة الزمنية',
  'profile.groups.timezone.subheading': 'عرض تواريخ وأوقات الفعاليات في منطقتك الزمنية المفضلة. <b>(الفعاليات الافتراضية/عبر الإنترنت فقط)</b>.',
  'profile.informationSharingNotification': 'معلومات الاتصال الخاصة بك <b>ليست</b> معروضة على ملفك الشخصي العام.',
  'profile.modals.previewCompanyCard.modalTitle': 'معاينة بطاقة شركتك',
  'profile.modals.previewContactCard.': 'معاينة بطاقتك',
  'profile.modals.previewContactCard.modalTitle': 'معاينة بطاقتك',
  'profile.optouts.something_went_wrong': 'يرجى تحديث الصفحة والمحاولة مرة أخرى',
  'profile.optouts.subheader': 'اختيار هذه الخيارات سيخفي ملفك الشخصي و/أو يرفض جميع الاجتماعات والمواعيد الأخرى.',
  'profile.optouts.title': 'الانسحابات من الفعالية',
  'profile.optouts.type_state_findable_false': 'أنا <b>لست</b> مرئيًا في البوابة.',
  'profile.optouts.type_state_findable_true': 'أنا مرئي في البوابة.',
  'profile.optouts.type_state_meetable_false': 'أنا <b>لست</b> متاحًا للاجتماعات.',
  'profile.optouts.type_state_meetable_true': 'أنا متاح للاجتماعات.',
  'profile.pages.catalogue.header': 'السوق',
  'profile.pages.companyProfile.header': 'ملف الشركة',
  'profile.pages.companyProfile.previewButton': 'معاينة ملف الشركة',
  'profile.pages.manageContent.header': 'إدارة المحتوى',
  'profile.pages.manageContent.warning': 'قد تحد إعدادات الفعالية من عرض بعض المحتويات.',
  'profile.pages.myAccount.action': 'فتح الفعالية',
  'profile.pages.myAccount.eventAndBadges': 'فعالياتي وشهاداتي',
  'profile.pages.myAccount.header': 'صفحة حسابي الرئيسية',
  'profile.pages.myAccount.welcome': 'مرحبًا {{ firstName }}, مرحبًا بك في حسابك.',
  'profile.pages.notificationSettings.header': 'إعدادات الإشعارات',
  'profile.pages.passwordSettings.header': 'كلمة المرور والإعدادات',
  'profile.pages.purchases.header': 'المشتريات',
  'profile.pages.purchases.receipt': 'الإيصال',
  'profile.pages.profileInformation.header': 'ملفي الشخصي',
  'profile.pages.profileInformation.previewButton': 'معاينة ملفي الشخصي',
  'profile.pages.profileInformation.myAccount': 'حسابي',
  'profile.pages.profileInformation.switchProfile': 'تبديل الملف الشخصي',
  'profile.pages.team.header': 'الفريق',
  'profile.pages.data_and_privacy.header': 'البيانات والخصوصية',
  'profile.pageTitle': 'حسابي',
  'profile.rows.avatarModalTitle': 'تحميل صورة الملف الشخصي',
  'profile.rows.bio.bioPlaceholder': 'وظيفتي تشمل ... ',
  'profile.rows.companyLocations.addModalTitle': 'إضافة موقع',
  'profile.rows.companyLocations.delete': 'هل أنت متأكد أنك تريد حذف هذا الموقع?',
  'profile.rows.companyLocations.updateModalTitle': 'تحرير الموقع',
  'profile.rows.companyWebsites.removeWarning': 'هل أنت متأكد أنك تريد إزالة هذا $t(crm.link.type.website, lowercase) من ملفك الشخصي?',
  'profile.rows.country.selectCountryPlaceholder': 'أين تقع?',
  'profile.rows.fullNameModalTitle': 'تحرير الاسم',
  'profile.rows.logo.logoModalTitle': 'تحميل شعار الشركة',
  'profile.rows.lookingFor.inputLabel': 'ما نوع جهات الاتصال أو الشركات أو المنتجات أو الخدمات التي تهتم بها؟',
  'profile.rows.lookingFor.inputPlaceholder': 'أنا أبحث عن ...',
  'profile.rows.lookingFor.label': 'أنا أبحث عن ...',
  'profile.rows.lookingFor.modalTitle': 'أنا أبحث عن ...',
  'profile.rows.password.changeMessage': 'أرسل رابط تغيير كلمة المرور إلى:',
  'profile.rows.password.label': 'كلمة المرور الحالية',
  'profile.rows.password.modalTitle': 'تغيير كلمة المرور',
  'profile.rows.password.passwordLinkSent': 'تم إرسال رابط تغيير كلمة المرور',
  'profile.rows.password.successMessage': '<h4> تم إرسال البريد الإلكتروني </h4><p> لقد أرسلنا رابط إعادة تعيين كلمة المرور إلى:<br/><a href="mailto:{{email}}"> {{ email }} </a></p><p>ستنتهي صلاحية الرابط خلال ساعة.</p>',
  'profile.rows.phone.deleteWarning': 'حذف هذا الرقم من ملفك الشخصي؟',
  'profile.rows.phone.info': 'قد نتواصل معك بخصوص الاجتماعات أو التحديثات الهامة للحدث عبر الرسائل النصية (SMS) أو الهاتف. رقم هاتفك ليس ظاهرًا في ملفك الشخصي العام.',
  'profile.rows.socials.updateModalTitle': 'تعديل الروابط الاجتماعية',
  'profile.rows.websites.addModalTitle': 'إضافة موقع الكتروني',
  'profile.rows.websites.updateModalTitle': 'تعديل الموقع الالكتروني',
  'profile.rows.legal_notices.title': 'إشعارات قانونية',
  'profile.rows.legal_notices.eventware': 'إيفينتواري',
  'profile.rows.legal_notices.eventwarePrivacyNotice': 'إشعار الخصوصية لإيفينتواري',
  'profile.rows.legal_notices.eventwareEndUserLicenceAgreement': 'اتفاقية ترخيص المستخدم النهائي لإيفينتواري',
  'profile.rows.legal_notices.learnMoreAbout': 'تعرف على المزيد حول',
  'profile.rows.download_personal_data.title': 'البيانات الشخصية',
  'profile.rows.download_personal_data.description': 'قم بتنزيل ملف بتنسيق JSON يحتوي على المعلومات التي نخزنها عنك، تماشياً مع المادة 20 من اللائحة العامة لحماية البيانات. يتضمن هذا التصدير جميع البيانات التي يمكن التعرف عليها المتعلقة بك أو بمؤسستك.',
  'profile.rows.download_personal_data.warning_prefix': 'تحذير:',
  'profile.rows.download_personal_data.warning_description': 'يحتوي هذا الملف على معلومات حساب واستخدام شخصية. احفظه بأمان وتجنب تنزيله على جهاز عام.',
  'profile.tables.catalogueEntry.confirmDelete': 'هل أنت متأكد أنك تريد حذف هذا {{ name, lowercase }}؟',
  'profile.tables.catalogueEntry.emptyMessage': 'لا توجد عناصر لعرضها.',
  'profile.tables.imageGallery.addOne': 'إضافة',
  'profile.tables.imageGallery.confirmDelete': 'هل أنت متأكد أنك تريد حذف هذه الصورة؟',
  'profile.tables.imageGallery.count_one': '({{ count }} عنصر)',
  'profile.tables.imageGallery.count_other': '({{ count }} عناصر)',
  'profile.tables.imageGallery.emptyMessage': 'لا توجد عناصر لعرضها.',
  'profile.tables.imageGallery.title': 'معرض الصور',
  'profile.tables.videos.addOne': 'إضافة',
  'profile.tables.videos.confirmDelete': 'هل أنت متأكد أنك تريد حذف هذا الفيديو؟',
  'profile.tables.videos.count': '({{ count }} / {{ limit }})',
  'profile.tables.videos.emptyMessage': 'لا توجد عناصر لعرضها.',
  'profile.tables.videos.title': 'فيديوهات الملف الشخصي',
  'profile.tips.edit.body': 'التفاصيل المستخدمة للتحقق من هويتك والشركة التي تعمل لأجلها لا يمكن تغييرها. يمكن تعديل معلومات الاتصال وتفضيلات بعض التفاصيل، لكن قد نطلب منك التحقق من هويتك قبل حفظ التحديثات.',
  'profile.tips.edit.title': 'ما هي التفاصيل التي يمكن تعديلها؟',
  'profile.tips.preview.title': 'معاينة البطاقة',
  'profile.tips.share.body': 'نشارك معلومات الاتصال الخاصة بك مع شركاء الاجتماع المؤكدين فقط.',
  'profile.tips.share.title': 'ما المعلومات التي تُشارك مع الآخرين؟',
  'profile.action.logout': 'تسجيل الخروج',
  'profile_select.footer.contact_support': 'الاتصال بالدعم',
  'profile_select.footer.multiple_profiles': 'لماذا لديّ عدة ملفات شخصية؟',
  'profile_select.footer.return_to_list': 'العودة إلى قائمة الملفات',
  'profile_select.header.info': 'انقر على خيارات الملف الشخصي لـ<b>الاتصال، الدمج أو التعيين</b> إلى زميل.',
  'profile_select.header.subheader': 'هناك أكثر من ملف شخصي مرتبط بـ',
  'profile_select.header.title': 'تبديل الملف الشخصي',
  'profile_select.info.duplicate_profiles.description': 'ربما قمت بالتسجيل مرتين.',
  'profile_select.info.duplicate_profiles.solution_text': "اختر 'دمج' من قائمة خيارات الملف الشخصي واتبع الخطوات لجمع البيانات في ملف مستخدم واحد. نوصي بالاتصال والتحقق من الملف الشخصي الذي تريد الاحتفاظ به.",
  'profile_select.info.duplicate_profiles.title': 'هناك ملفات شخصية مكررة باسمي.',
  'profile_select.info.i_see_colleagues.description': 'ربما قامت شركتك بتسجيل العديد من الزملاء باستخدام عنوان بريد إلكتروني مشترك، مثل info@.',
  'profile_select.info.i_see_colleagues.solution_text': 'اختر الاتصال بزميل من قائمة خيارات الملف الشخصي.',
  'profile_select.info.i_see_colleagues.title': 'أرى أسماء زملاء آخرين من شركتي.',
  'profile_select.info.listed_different_company.description': 'ربما تكون مسجلًا تحت اسم أو تمثيل شركات متعددة. إذا كان هذا صحيحًا، يمكنك الاحتفاظ بالتبديل بين الملفات الشخصية واستخدام شاشة اختيار الملف لتبديل الملفات. إذا كان هذا غير صحيح:',
  'profile_select.info.listed_different_company.solution_text': "اختر 'حذف' من قائمة خيارات الملف الشخصي. لا يمكن التراجع عن هذه العملية، نوصي بالاتصال والتحقق من الملفات قبل الحذف.",
  'profile_select.info.listed_different_company.title': 'أنا مدرج تحت أسماء شركات مختلفة.',
  'profile_select.info.solution': 'الحل:',
  'profile_select.info.title': 'لماذا لديّ عدة ملفات شخصية؟',
  'profile_select.profile.last_used': 'آخر استخدام:',
  'profile_select.profile.never_logged_in': 'أبدًا',
  'profile_select.primary_contact': 'الاتصال الرئيسي',
  'ui.add': 'إضافة',
  'ui.delete': 'حذف',
  'ui.edit': 'تعديل',
  'ui.faq': 'الأسئلة المتكررة',
  'ui.help': 'مساعدة',
  'ui.language': 'اللغة',
  'ui.locked': 'مغلق',
  'ui.new': 'جديد',
  'ui.noItemsToDisplay': 'لا توجد عناصر لعرضها.',
  'ui.notAdded': 'لم يضاف',
  'ui.notSet': 'غير محدد',
  'ui.selectCountry': 'اختر البلد',
  'ui.support': 'دعم',
  'ui.switchProfile': 'تبديل الملف الشخصي',
  'update_availability.buttons.cancel': 'إلغاء',
  'update_availability.buttons.update': 'تحديث التوافر',
  'update_availability.header': 'توفر الخاص بك',
  'update_availability.key.available': 'متاح',
  'update_availability.key.unavailable': 'غير متاح',
  'update_availability.subheader': 'حدد الأيام والأوقات التي ستحضر فيها الحدث.',
  'video_meeting.message_concierge.box_closing': 'سيتم إغلاق هذا المربع تلقائيًا في 5 ثوانٍ.',
  'video_meeting.message_concierge.buttons.cancel': 'إلغاء',
  'video_meeting.message_concierge.buttons.message_concierge': 'راسل الكونسيرج',
  'video_meeting.message_concierge.buttons.send': 'إرسال',
  'video_meeting.message_concierge.connecting': 'جارٍ الاتصال',
  'video_meeting.message_concierge.header': 'اطلب المساعدة من الكونسيرج',
  'video_meeting.message_concierge.last_seen': '(آخر مرة شوهد فيها {{ last_seen }})',
  'video_meeting.message_concierge.message_sent': 'تم إرسال رسالتك إلى الكونسيرج، راقب نافذة الدردشة للرد.',
  'video_meeting.message_concierge.online': 'متصل',
  'video_meeting.message_concierge.waiting_join': 'في انتظار انضمام المستخدم',
  chat_visible: 'الدردشة مرئية لجميع المشاركين',
  'purchase_upgrade.agree_terms_link': 'الشروط والأحكام',
  'purchase_upgrade.agree_terms_post': '.',
  'purchase_upgrade.agree_terms_pre': 'أوافق على',
  'purchase_upgrade.buttons.agree': 'أوافق',
  'purchase_upgrade.buttons.back': 'عودة',
  'purchase_upgrade.buttons.buy_now': 'اشتري الآن',
  'purchase_upgrade.buttons.cancel': 'إلغاء',
  'purchase_upgrade.buttons.finish': 'إنهاء',
  'purchase_upgrade.buttons.pay_now': 'ادفع الآن',
  'purchase_upgrade.buttons.print_receipt': 'طباعة الإيصال',
  'purchase_upgrade.buttons.try_again': 'حاول مرة أخرى',
  'purchase_upgrade.checkout_heading': 'الدفع',
  'purchase_upgrade.error_heading': 'خطأ',
  'purchase_upgrade.payment_complete.heading': 'اكتمل الدفع',
  'purchase_upgrade.payment_complete.message': 'تمت معالجة طلبك وتطبيقه على الحساب.',
  'purchase_upgrade.terms.subtitle': 'العنوان الفرعي',
  'purchase_upgrade.terms.title': 'الشروط والأحكام',

  'meeting_invite.notification.confirmed_meeting': 'لديك <a href="{{hash}}">اجتماع مؤكد</a> مع هذا الشخص.',
  'meeting_invite.notification.invite_already_sent': '<a href="{{hash}}">تم إرسال الدعوة بالفعل</a> إلى هذا الشخص.',
  'meeting_invite.notification.invite_already_received': 'لديك <a href="{{hash}}">دعوة اجتماع جديدة</a> من هذا الشخص.',
  'meeting_invite.notification.invite_withdrew_by_ac': 'تم سحب الدعوة في {{withdrawn_time}}. <a href="{{hash}}">عرض التفاصيل</a>.',
  'meeting_invite.notification.meeting_cancelled_by': 'تم إلغاء اجتماع سابق.<a href="{{hash}}">عرض الاجتماع</a>.',
  'meeting_invite.notification.meeting_with_contact_colleague': 'لديك <a href="{{hash}}">اجتماع مؤكد</a> مع هذه الشركة.',
  'meeting_invite.notification.meeting_with_contact_declined': 'تم رفض <a href="{{hash}}">دعوة الاجتماع</a> الخاصة بك.',
  'meeting_invite.notification.meeting_with_contact_declined_by_you': 'لقد رفضت <a href="{{hash}}">دعوة الاجتماع</a> من هذا الاتصال.',
  'meeting_invite.notification.colleague_meeting_intersect': 'زميلك يجتمع مع هذه الشركة.',
  'meeting_invite.notification.meeting_with_colleague_confirmed': 'زميلك يجتمع مع هذا الشخص.',
  'meeting.notification.view_meeting': 'عرض الاجتماع',

  // التسجيلات
  'registrations.form.questions.component.name.first_name': 'الاسم الأول',
  'registrations.form.questions.component.name.last_name': 'اسم العائلة',
  'registrations.form.questions.component.name.first_name_placeholder': 'أدخل الاسم الأول',
  'registrations.form.questions.component.name.last_name_placeholder': 'أدخل اسم العائلة',
  'registrations.form.actions.submit': 'إرسال',
  'registrations.form.actions.next': 'التالي',
  'registrations.form.screens.thankyou.heading': '<strong>مبروك {{ name }}</strong>، تم اكتمال تسجيلك في {{ eventName }}.',
  'registrations.form.screens.thankyou.sub_heading': 'لقد أرسلنا لك بريدًا إلكترونيًا للتأكيد.',
  'registrations.form.screens.not_active.description': 'نموذج التسجيل هذا غير متاح حاليًا، يرجى المحاولة مرة أخرى لاحقًا.',
  'registrations.form.screens.closed.alert_message': 'تم إغلاق التسجيل.',
  'registrations.form.screens.coming_soon.alert_message': 'سيفتح التسجيل قريبًا.',
  'registrations.form.actions.send_login_link': 'إرسال رابط تسجيل الدخول',
  'registrations.form.actions.change_email_address': 'تغيير عنوان البريد الإلكتروني',
  'registrations.form.actions.send_link_to_continue': 'إرسال رابط للمتابعة',
  'registrations.form.validation.email_already_registered_message': 'عنوان بريدك الإلكتروني مسجل بالفعل...',
  'registrations.form.validation.email_already_have_partial_registration_message': 'عنوان بريدك الإلكتروني يحتوي على تسجيل جزئي...',
  'registrations.form.buttons.login': 'تسجيل الدخول',
  'registrations.form.actions.resend_email': 'إعادة إرسال البريد الإلكتروني',
  'registrations.form.actions.resend_email.message': 'تم إرسال البريد الإلكتروني!',

  // مقاطع الفيديو
  'video_card.more': 'تفاصيل أكثر',
  'video_card.view_detail': 'تفاصيل الفيديو',
  'videoModal.buttons.view_company': 'عرض الشركة',

  'eventmap.open_location_on_map': 'افتح الخريطة',

  // Networking
  'networking.match_score.explanation_modal.title': 'تفسير درجة المطابقة',
  'networking.match_score.explanation_modal.paragraph_1_title': 'ما هي درجة المطابقة؟',
  'networking.match_score.explanation_modal.paragraph_1_description': 'تعكس درجة المطابقة الخاصة بك مدى احتمال مشاركتك للاهتمامات والأهداف مع مستخدم آخر. كلما زادت الدرجة، كانت الصلة أكثر ملاءمة.',
  'networking.match_score.explanation_modal.paragraph_2_title': 'لماذا لا تحتوي بعض الملفات الشخصية على درجة مطابقة؟',
  'networking.match_score.explanation_modal.paragraph_2_description': 'قد لا يكون لدينا معلومات كافية بعد لحساب نتيجة دقيقة. يتم تحديث درجات المطابقة في الوقت الفعلي مع تفاعل المستخدمين مع الحدث.',
  'networking.match_score.explanation_modal.paragraph_3_title': 'كيف يتم حساب درجة مطابقتي؟',
  'networking.match_score.explanation_modal.paragraph_3_description': 'تستند درجات المطابقة إلى تفاصيل الملف الشخصي واتجاهات النشاط والتشابهات بين المستخدمين. كلما كان ملفك الشخصي مكتملًا، كانت تطابقاتك أكثر دقة.',
  'networking.match_score.explanation_modal.paragraph_4_title': 'كيف يمكنني تحسين تطابقاتي؟',
  'networking.match_score.explanation_modal.paragraph_4_description': 'إذا كانت تطابقاتك غير دقيقة، قم بزيارة حسابي للتحقق من معلومات ملفك الشخصي. يساعدنا الحفاظ على تحديث ملفك الشخصي في اقتراح محتوى و روابط أفضل.',
  'networking.match_score.explanation_modal.paragraph_5_title': 'ما الدرجة التي يراها الآخرون في ملفي الشخصي؟',
  'networking.match_score.explanation_modal.paragraph_5_description': 'كل درجة مطابقة فريدة للمشاهد وتحدث تلقائيًا ديناميكيًا مع تطور الملفات الشخصية. هذا يضمن أن روابطك تظل ذات صلة قدر الإمكان.',
};
