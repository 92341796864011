<template>
  <MeetingChatRow
    type="MeetingConfirmed"
    :preMessage="preMessage"
    message=""
    :when="chat.when"
    :sender="sender"
    :sentByYou="sentByYou"
    :read="chat.read"
    important
  >
    <template #chip>
      <div class="chip chip--small chip--success">
        <i class="far fa-check-circle"></i>
        {{ __('conversations.chips.confirmed') }}
      </div>
    </template>
    <template #actions>
      <div class="button-group">
        <a
          class="button button--link"
          v-if="policy.can_accept"
          :href="`#/meetings/${meetingId}/details`"
        >
          {{ __('conversations.view_meeting') }}
          <i class="far fa-arrow-right-long"></i>
        </a>
      </div>
    </template>
  </MeetingChatRow>
</template>
<script>

export default {
  props: {
    chat: {
      type: Object,
      required: true,
    },
    sender: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    meetingId: {
      type: Number,
      required: true,
    },
    locationShort: {
      type: String,
      required: true,
    },
    locationLong: {
      type: String,
      default: '',
    },
    policy: {
      type: Object,
      required: false,
    },
    sentByYou: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    preMessage() {
      // eslint-disable-next-line no-underscore-dangle
      let message = this.__('conversations.accepted_by', { sender: this.sender.display_name });

      message = `${message}<br>
        <i class="far fa-fw fa-clock"></i> ${this.time} ${this.date}<br>
        <i class="far fa-fw fa-location-dot"></i> ${this.locationLong}<br>`;

      return message;
    },
  },
};

</script>
