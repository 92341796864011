<template>
  <div
    class="profile-table__row profile-table__row--with-status-border"
    :class="purchaseItem.purchaseable ? 'profile-table__row-highlight profile-table__row--allow' : 'profile-table__row--warning profile-table__row--disabled'"
    @click="purchase(purchaseItem)"
    >
    <div class="profile-table__row-text">
      <div>
        <div>
          <strong>{{ purchaseItem.name }}</strong>
          <p>{{ purchaseItem.description }}</p>
        </div>
        <div class="chip-list">
          <span
            class="chip chip--small chip--neutral"
            v-if="purchaseItem.upgrade_type === 'badge_upgrade' && purchaseItem.contact_already_has_badge"
          >
            {{ __('hubs.purchase_items.purchased') }}
          </span>
          <span
            class="chip chip--small chip--neutral"
            v-else-if="!purchaseItem.is_available"
          >
            {{ __('hubs.purchase_items.product_not_available') }}
          </span>
          <span
            class="chip chip--small"
            :class="purchaseItem.stock_available > 5 ? 'chip--success' : 'chip--alert'"
            v-else-if="purchaseItem.quantity_available < 50"
          >
            {{ __('hubs.purchase_items.someAvailable', { num: purchaseItem.stock_available }) }}
          </span>
          <span
            class="chip chip--small chip--success"
            v-else
          >
            {{ __('hubs.purchase_items.available') }}
          </span>

        </div>
      </div>
    </div>
    <div>
      {{ `${currencySymbol} ${(purchaseItem.price_with_tax/100).toFixed(2)} ${purchaseItem.currency}` }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currencySymbol: '',
    };
  },
  props: {
    purchaseItem: {
      type: Object,
      require: true,
    },
  },
  methods: {
    purchase(purchaseItem) {
      if (!purchaseItem.purchaseable) {
        return;
      }
      window.location.href = `#/purchase-upgrade/${purchaseItem.id}`;
    },
    loadCurrency() {
      switch (this.purchaseItem.currency) {
        case 'USD':
          this.currencySymbol = '$';
          break;
        case 'BRL':
          this.currencySymbol = 'R$';
          break;
        case 'EUR':
          this.currencySymbol = '€';
          break;
        case 'GBP':
        default:
          this.currencySymbol = '£';
          break;
      }
    },
  },
  mounted() {
    this.loadCurrency();
  },
};

</script>
