<template>
  <div
    class="profile-table__row profile-table__row--with-status-border"
    :class="purchase ? 'profile-table__row-highlight' : 'profile-table__row--warning profile-table__row--disabled'"
    >
    <div class="profile-table__row-text">
      <div>
        <div>
          <strong>{{ purchase.purchaseItem.name }}</strong>
          <p>{{ purchase.purchaseItem.description }}</p>
          <ul>
            <li><small>{{ __('hubs.purchases.purchased_by', { 'contact': purchase.contact.display_name }) + ' on ' + new Date(purchase.payment.created_at).toLocaleDateString() }}</small></li>
          </ul>
        </div>
      </div>
    </div>
    <div>
      {{ `${currencySymbol} ${(purchase.payment.total_in_cents/100).toFixed(2)} ${purchase.payment.currency}`  }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currencySymbol: '',
    };
  },
  props: {
    purchase: {
      type: Object,
      require: true,
    },
  },
  methods: {
    loadCurrency() {
      switch (this.purchase.payment.currency) {
        case 'USD':
          this.currencySymbol = '$';
          break;
        case 'BRL':
          this.currencySymbol = 'R$';
          break;
        case 'EUR':
          this.currencySymbol = '€';
          break;
        case 'GBP':
        default:
          this.currencySymbol = '£';
          break;
      }
    },
  },
  mounted() {
    this.loadCurrency();
  },
};

</script>
