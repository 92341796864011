<template>
  <div class="modal overlay">
    <div class="modal__body modal__body--full-page" >
      <ProfileModalHeader
        @closed="$emit('closed')"
        :locales="locales"
      />
      <Layout3Column ref="layout" :activeColumn="tab ? 2 : 1">
        <template #left>
          <NavigationPanel :tabs="tabs" v-model="tab" />
        </template>
        <template v-if="activeTab">
          <h2 class="profile-page__header">
            <IconButton
              v-if="tabletOrSmaller"
              @click="prevTab = tab; tab = null"
              icon="fa-arrow-left"
              :label="__('buttons.back')"
              large
             />

            <div style="margin-bottom: 0;">
              <i class="far" :class="activeTab.icon"> </i>
              {{ activeTab.header }}
            </div>

            <IconButton
              v-if="smallLaptopOrSmaller"
              @click="$refs.helpModal.open()"
              icon="fa-question"
              :label="__('buttons.help')"
              large
              />
          </h2>
          <FeLoading v-if="loading" />
          <component v-else :is="activeTab.page"></component>
        </template>
        <template #right>
          <ProfileTips :faqs="faqsForPage" />
        </template>
      </Layout3Column>
    </div>
    <Teleport to="body">
      <BaseModal ref="helpModal">
        <ProfileTips borderless :faqs="faqsForPage" />
      </BaseModal>
    </Teleport>
  </div>
</template>

<script>
import axios from 'axios';
import useMyAccountStore from '~/Store/MyAccount';
import { mapActions, mapState } from 'pinia';
import { useBreakpoints } from '~/Frontend/useBreakpoints';
import CompanyProfilePage from './Pages/CompanyProfilePage.vue';
import ManageContentPage from './Pages/ManageContentPage.vue';
import NotificationSettingsPage from './Pages/NotificationSettingsPage.vue';
import DataAndPrivacyPage from './Pages/DataAndPrivacyPage.vue';
import PasswordSettingsPage from './Pages/PasswordSettingsPage.vue';
import ProfileCataloguePage from './Pages/ProfileCataloguePage.vue';
import ProfileInformationPage from './Pages/ProfileInformationPage.vue';
import ProfilePurchasesPage from './Pages/ProfilePurchasesPage.vue';
import ProfileTeamPage from './Pages/ProfileTeamPage.vue';

export default {
  props: {
    initialTab: {
      type: String,
      required: false,
    },
  },
  emits: ['closed'],
  data() {
    return {
      faqs: {},
      loading: true,
      locales: [],
      tab: this.tabletOrSmaller ? null : this.initialTab,
      prevTab: this.initialTab,
      enableCatalogue: false,
      originalPageTitle: null,
      enablePurchases: false,
    };
  },
  setup() {
    const { tabletOrSmaller, smallLaptopOrSmaller } = useBreakpoints();
    return { tabletOrSmaller, smallLaptopOrSmaller };
  },
  computed: {
    tabs() {
      return [
        {
          icon: 'far fa-address-card',
          label: this.__('profile.pages.profileInformation.header'),
          name: 'profile-information',
          code: 'profile-information',
          page: ProfileInformationPage,
          header: this.__('profile.pages.profileInformation.header'),
          attentionNeeded: !this.sectionIsCompleted('profile-information'),
        },
        {
          icon: 'far fa-sliders',
          label: this.__('profile.pages.passwordSettings.header'),
          name: 'password-settings',
          code: 'password-settings',
          page: PasswordSettingsPage,
          header: this.__('profile.pages.passwordSettings.header'),
          attentionNeeded: !this.sectionIsCompleted('password-settings'),
        },
        {
          icon: 'far fa-bells',
          label: this.__('profile.pages.notificationSettings.header'),
          name: 'notification-settings',
          code: 'notification-settings',
          page: NotificationSettingsPage,
          header: this.__('profile.pages.notificationSettings.header'),
          attentionNeeded: !this.sectionIsCompleted('notification-settings'),
        },
        {
          icon: 'fas fa-shield-halved',
          label: this.__('profile.pages.data_and_privacy.header'),
          name: 'data-privacy',
          code: 'data-privacy',
          page: DataAndPrivacyPage,
          header: this.__('profile.pages.data_and_privacy.header'),
          attentionNeeded: !this.sectionIsCompleted('data-privacy'),
        },
        {
          icon: 'far fa-buildings',
          label: this.__('profile.pages.companyProfile.header'),
          name: 'company-profile',
          code: 'company-profile',
          page: CompanyProfilePage,
          header: this.__('profile.pages.companyProfile.header'),
          attentionNeeded: !this.sectionIsCompleted('company-profile'),
        },
        {
          icon: 'far fa-box-open',
          label: this.__('profile.pages.catalogue.header'),
          name: 'catalogue',
          code: 'catalogue',
          hidden: !this.enableCatalogue,
          page: ProfileCataloguePage,
          header: this.__('profile.pages.catalogue.header'),
          attentionNeeded: !this.sectionIsCompleted('catalogue'),
        },
        {
          icon: 'far fa-photo-film',
          label: this.__('profile.pages.manageContent.header'),
          name: 'manage-content',
          code: 'manage-content',
          page: ManageContentPage,
          header: this.__('profile.pages.manageContent.header'),
          attentionNeeded: !this.sectionIsCompleted('manage-content'),
        },
        {
          icon: 'far fa-users-cog',
          label: this.__('profile.pages.team.header'),
          name: 'team',
          code: 'team',
          hidden: true,
          page: ProfileTeamPage,
          header: this.__('profile.pages.team.header'),
          attentionNeeded: !this.sectionIsCompleted('team'),
        },
        {
          icon: 'far fa-shopping-basket',
          label: this.__('profile.pages.purchases.header'),
          name: 'purchases',
          code: 'purchases',
          hidden: !this.enablePurchases,
          page: ProfilePurchasesPage,
          header: this.__('profile.pages.purchases.header'),
          attentionNeeded: !this.sectionIsCompleted('purchases'),
        },
      ];
    },
    activeTab() {
      return this.tab ? this.tabs.find((tab) => tab.code === this.tab) : null;
    },
    pageTitle() {
      if (!this.tab) {
        return this.__('profile.pageTitle');
      }
      const tabTitle = this.tabs.find((item) => item.code === this.tab).label;
      return `${this.__('profile.pageTitle')} > ${tabTitle}`;
    },
    faqsForPage() {
      if (!this.tab) {
        return [];
      }
      const key = `my_account_${this.tab.replace('-', '_')}`;
      if (!this.faqs[key]) {
        return [];
      }
      return this.faqs[key];
    },
    ...mapState(useMyAccountStore, ['sectionIsCompleted']),
  },
  methods: {
    ...mapActions(useMyAccountStore, ['loadSectionStatus']),
  },
  watch: {
    tabletOrSmaller(val) {
      if (!val) {
        this.tab = this.prevTab;
      }
    },
    pageTitle() {
      document.title = this.pageTitle;
    },
    activeTab() {
      this.$nextTick(() => {
        const container = this.$refs.layout.$el;
        if (container && container.scrollTo) {
          container.scrollTo({ top: 0, behavior: 'smooth' });
        }
      });
    },
  },
  mounted() {
    this.originalPageTitle = document.title;
    document.title = this.pageTitle;
    axios.get('/profile/overview')
      .then((response) => {
        this.locales = response.data.locales;
        this.enableCatalogue = response.data.enable_catalogue;
        this.loading = false;
        this.faqs = response.data.faqs;
        this.enablePurchases = response.data.has_purchases;
      });
    this.loadSectionStatus();
  },
  unmounted() {
    document.title = this.originalPageTitle;
  },
};
</script>
