<template>
  <div class="registration-demographic-single-select"
    :class="{'registration-demographic-single-select--inline' :
          registrationStore.currentQuestion.layout === 'inline',
          'registration-demographic-single-select--stacked' :
          registrationStore.currentQuestion.layout === 'stacked'}"
    >
    <FrontendRadioInput
      :label="registrationStore.currentQuestion.title"
      v-model="registrationStore.currentQuestion.answer"
      :options="registrationStore.currentQuestion.options.map((option) => ({
        value: option.id,
        text: option.name,
      }))"
      :errors="registrationStore.currentQuestion.error"
      :name="registrationStore.currentQuestion.question_type"
      :required="registrationStore.currentQuestion.is_required"
      :autofocus="true"
    />
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import FrontendRadioInput from '~/Components/Frontend/Inputs/FrontendRadioInput.vue';
import useRegistrationStore from '~/Store/Registration';

export default {
  type: 'lazy',
  name: 'RegistrationDemographicSingleSelectComponent',
  components: {
    FrontendRadioInput,
  },
  computed: {
    // Store will be accessible as its id + 'Store'
    ...mapStores(useRegistrationStore),
  },
};
</script>
