<template>
  <div class="ee_directory__card" @click="previewUrl">
    <div class="ee_directory__image">
      <img :src="mainImage">
    </div>
    <div class="ee_directory__body">
      <div class="ee_directory__header">
        {{ exclusiveEvent.title }}
      </div>
      <div
        class="ee_directory__description"
        v-html="exclusiveEvent.description ? exclusiveEvent.description : __('exclusive_events.no_description')"
      >
      </div>
      <div class="ee_directory__dates">
        <i class="far fa-clock"></i> {{ exclusiveEvent.formatted_start_date }}
      </div>
      <div class="ee_directory__times">
        {{ exclusiveEvent.formatted_start_time }} - {{ exclusiveEvent.formatted_end_time }}
      </div>
      <div class="exclusive-event-row__info">
        <i class="far fa-location-dot"></i> {{ exclusiveEvent.location }}
      </div>
      <div class="exclusive-event-row__buttons">
        <button v-if="loading" class="button button--loading" />
        <button v-else-if="policy.is_booked_on" class="button button--secondary button--active" @click.stop="unbook">
          {{ __("exclusive_events.row.buttons.booking_remove_from_schedule") }}
        </button>
        <button v-else-if="!policy.is_available" class="button button--secondary button--disabled">
          {{ __("exclusive_events.row.buttons.booking_schedule_clash") }}
        </button>
        <button v-else-if="!policy.has_capacity" class="button button--primary button--disabled">
         {{ __("exclusive_events.row.buttons.booking_capacity_full") }}
        </button>
        <button v-else-if="policy.can_book" class="button button--secondary" @click.stop="book">
          {{ __("exclusive_events.row.buttons.booking_add_to_schedule") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

import { store } from '../../../Frontend/store';

export default {
  name: 'ExclusiveEventRow',
  props: {
    model: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      defaultImage: 'https://s3.eu-west-1.amazonaws.com/storage.v4.eventnetworking.com/assets/default-marketplace-card-banner.png',
      policy: this.model.policy,
      loading: false,
    };
  },
  methods: {
    book() {
      this.loading = true;
      axios.post(`${this.exclusiveEventUrl}/book`)
        .then((response) => {
          this.emitter.emit('exclusive_event.booking', {
            policies: response.data.policies,
          });
        })
        .then(() => {
          this.loading = false;
        });
    },
    unbook() {
      this.loading = true;
      axios.post(`${this.exclusiveEventUrl}/unbook`)
        .then((response) => {
          this.emitter.emit('exclusive_event.booking', {
            policies: response.data.policies,
          });
        }).then(() => {
          this.loading = false;
        });
    },
    previewUrl() {
      window.location.href = `#/exclusive-events/${this.exclusiveEvent.id}/show`;
    },
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
    exclusiveEvent() {
      return this.model;
    },
    exclusiveEventUrl() {
      return `/${this.eventEdition?.slug}/exclusive-events/${this.exclusiveEvent.id}`;
    },
    mainImage() {
      return this.model.card_image ?? this.defaultImage;
    },
  },
  mounted() {
    this.emitter.on('exclusive_event.booking', (data) => {
      const found = data.policies.find((item) => item.exclusive_event_id === this.model.id);
      if (found) {
        this.policy = found;
      }
    });
  },
};
</script>
