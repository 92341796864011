<template>
  <BaseModal
    height="tall"
    startOpen
    ref="modal"
    :notifications="notifications"
  >

    <template #header>
      <h3 class="base-modal__title">{{ __('meeting.panelTitle.information') }}</h3>
    </template>

    <template #subheader v-if="meeting.id">
      <ContactModalHeader
        v-if="counterpartLead"
        :key="counterpartLead.id"
        :contact="counterpartLead"
      />

      <ul class="frontend-tabs">
        <li
          :class="{'frontend-tabs__tab--active': activeTab === 'information'}"
          class="frontend-tabs__tab frontend-tabs__tab-grow"
          @click="activeTab = 'information'"
        >
          <i class="far fa-info-circle"></i> Information
        </li>
        <li
          :class="{'frontend-tabs__tab--active': activeTab === 'chat'}"
          class="frontend-tabs__tab frontend-tabs__tab-grow"
          @click="activeTab = 'chat'"
        >
          <i class="far fa-message"></i> Chat
        </li>
        <li
          :class="{'frontend-tabs__tab--active': activeTab === 'people'}"
          class="frontend-tabs__tab frontend-tabs__tab-grow"
          @click="activeTab = 'people'"
        >
          <i class="far fa-users"></i> {{ __('meeting.attendeesTitle') }}
        </li>
      </ul>
    </template>

    <FeLoading v-if="!meeting.id" />

    <!-- main slot -->
    <template v-if="meeting.id">
      <div v-show="activeTab === 'information'">
        <NotificationBar v-if="isCancelled" type="error" icon="fas fa-exclamation-circle">
          {{ __('meeting.meetingCancelled') }}
        </NotificationBar>
        <NotificationBar v-else-if="meeting.has_finished" type="success" icon="fas fa-clock">
          {{ __('meeting.finished') }}
        </NotificationBar>
        <NotificationBar v-else-if="meeting.has_started" type="success" icon="fas fa-clock">
          {{ __('meeting.in_progress') }}
          <span v-if="meeting.is_virtual">
            <a class="button meeting-card__join-now" :href="meeting.virtual_url">
              <i class="far fa-video"></i> {{ __('meeting.join_now') }}
            </a>
          </span>
        </NotificationBar>
        <NotificationBar v-else-if="meeting.show_countdown" type="success" icon="">
          <CountdownTimer
            :deadline="new Date(meeting.countdown.atom_string).getTime()"
            :gracePeriodSeconds="120"
            format="paragraph"
            :countdown-intro-message="__('meeting.meeting_starts_in_countdown')"
          >
            <template #almost-past>
              <div v-if="meeting.is_virtual">
                <a class="button button--primary" :href="meeting.virtual_url">
                  <i class="far fa-video"></i> {{ __('meeting.join_now') }}
                </a>
              </div>
            </template>
            <template #past>
              <div v-if="meeting.is_virtual">
                <a class="button button--primary" :href="meeting.virtual_url">
                  <i class="far fa-video"></i> {{ __('meeting.join_now') }}
                </a>
              </div>
              <p v-else><i class="fas fa-clock"></i> {{ __('meeting.in_progress') }}</p>
            </template>
          </CountdownTimer>
        </NotificationBar>
        <NotificationBar v-else type="success" icon="fas fa-clock">
            {{ __('meeting.startingTime', {time_until_meeting: meeting.time_until_meeting,}) }}
        </NotificationBar>

        <p v-if="isCancelled">
          {{
            __('meeting.cancelledBy', {
              cancelledBy_display_name: cancelledBy.display_name,
              cancelled_at_time: meeting.cancel_time,
            })
          }}
        </p>
        <p v-else>
          {{ __('meeting.meetingConfirmed') }}
          &nbsp;
          <a
            v-if="meeting.can_cancel && !isCancelled && !meeting.has_finished"
            class="button button--warning button--link"
            :href="`#/meetings/${meeting.id}/cancel`"
            >
            {{ __('meeting.cancel.button') }}
          </a>
          <small v-else-if="!isCancelled && !meeting.has_finished" class="button--disabled">
            <br>
            <i class="far fa-triangle-exclamation"></i>
            {{ __('meeting.cancel.onlyOrganiserCanCancel') }}
          </small>
        </p>

        <div
          class="meeting-modal__logistics"
          :class="{ 'meeting-modal__logistics--cancelled': isCancelled }"
        >
          <div class="meeting-modal__logistics__tag">
            <i class="fas fa-fw fa-calendar-day me-1"></i>
            {{ meeting.date_medium }}
          </div>
          <div class="meeting-modal__logistics__tag">
            <i class="fas fa-fw fa-clock me-1"></i>
            {{ meeting.time }}
            <small style="font-size: var(--font-xsmall">
              ({{ meeting.timezone }})
            </small>
          </div>

          <div class="meeting-modal__logistics__tag">
            <i class="fa-regular fa-fw fa-location-dot me-1"></i>
            {{ meeting.location.long }}
          </div>
        </div>

        <div class="meeting-modal__reschedule-container">
          <a
            v-if="meeting.can_reschedule"
            class="button button--link"
            :class="{
              'button--disabled': meeting.location.locked
            }"
            :href="`#/meetings/${meeting.id}/reschedule`"
          >
            {{ __('meeting.reschedule.button') }}
            <i class="far fa-arrow-right-long"></i>
          </a>
        </div>

        <div
          v-if="meeting.walking_distance"
          class="meeting-modal__walking-distance"
        >
          <i class="fas fa-person-walking me-1"></i>
          {{ meeting.walking_distance }}
        </div>

        <template v-if="meeting.location.eventmap_id && meeting.location.eventmap_location_id">
          <EventmapViewer
            :eventmapId="meeting.location.eventmap_id.toString()"
            :locationId="meeting.location.eventmap_location_id.toString()"
            lockAspectRatio
          ></EventmapViewer>

          <div style="margin-top: var(--sizing-3); margin-bottom: var(--sizing-4);">
            <a
              :href="`/${eventEdition.slug}/eventmap/${meeting.location.eventmap_id}?location_id=${meeting.location.eventmap_location_id}`"
              target="_blank"
              style="font-weight: var(--font-normal); font-size: var(--font-small);"
            >
              {{ __('eventmap.open_location_on_map') }}
              <i style="padding-left: var(--sizing-1);" class="fa-solid fa-arrow-up-right-from-square"></i>
            </a>
          </div>

        </template>

      </div>

      <div v-show="activeTab === 'chat'" class="contact-conversation">
        <MeetingConversation
          ref="conversation"
          :meetingId="meeting.id"
          :isBeingViewed="activeTab === 'chat'"
          @loaded="loaded = true"
        />
      </div>

      <div v-show="activeTab === 'people'">
        <div
          v-for="attendee in meeting.attendees"
          :key="attendee.id"
          class="profile-select__profile"
        >
          <div class="profile-select__avatar">
            <ContactAvatar :contact="attendee" size="small" />
          </div>
          <div class="profile-select__name">
            <b>{{ attendee.full_name }}</b>
            <span class="hide-for-mobile">, </span>
            <br class="hide-for-tablet-up" />
            {{ attendee.job_title }} <br />
            {{ attendee.company.display_name }}
          </div>
          <span v-if="attendee.is_lead">
            <i class="fa-solid fa-circle-check info-icon--success"></i>
          </span>
          <span v-else>
            <i class="fa-light fa-circle"></i>
          </span>
        </div>
      </div>
    </template>

    <template #footer>
      <FrontendTextInput
        v-if="meeting.can_message"
        v-show="activeTab === 'chat'"
        id="newMessage"
        key="newMessage"
        name="newMessage"
        v-model="newMessage"
        :max="500"
        type="textarea"
        autosize
        preventResize
        hideCounter
        actionButton
        actionButtonIcon="fas fa-paper-plane"
        @actionButtonClicked="sendChat"
        :placeholder="__('chat_visible')"
      />

      <div v-if="activeTab === 'information' || activeTab === 'people'" class="button-group">
        <button
          @click="downloadIcs"
          v-show="activeTab === 'information'"
          :disabled="!meeting.id || meeting.cancelled_at"
          type="button"
          class="button"
        >
          <i class="far fa-calendar-circle-plus"></i>
          {{ __('meeting.addToCalendar') }}
        </button>

        <button
            v-if="meeting.id"
            v-show="activeTab === 'people'"
            :disabled="isCancelled || !hasColleaguesToAdd"
            class="button button--primary"
            @click="gotoAddColleagueModal"
        >
            <i class="fas fa-user-plus"></i>
            {{ __('meeting.addColleague.button') }}
        </button>

        <a class="button button--secondary" @click="modalClose">
          {{ __('meeting.close') }}
        </a>
      </div>
    </template>

  </BaseModal>
</template>

<script>
import axios from 'axios';
import NotificationBar from '~/Components/Frontend/NotificationBar.vue';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import EventmapViewer from '~/Components/Frontend/Eventmap/EventmapViewer.vue';
import { store, user } from '../../../Frontend/store';

export default {
  name: 'MeetingModal',
  components: {
    FeLoading,
    EventmapViewer,
    NotificationBar,
  },
  inject: ['eventEditionManager'],
  props: {
    meetingId: {
      type: [String, Number],
      required: true,
    },
    initialTab: {
      type: String,
      default: 'information',
    },
  },
  data() {
    return {
      meeting: {},
      errorMessage: null,
      notifications: [],
      activeTab: this.initialTab,
      newMessage: '',
      isMessageable: null,
    };
  },
  mounted() {
    this.loadContent();
  },
  methods: {
    modalClose() {
      this.$refs.modal.close();
      this.emitter.emit('meetings.modal_closed');
    },
    loadContent() {
      this.meeting = {};
      clearInterval(this.countdownTimerInterval);
      this.countdownTimerInterval = null;
      this.countdownTimerDate = null;
      axios.get(`/${this.eventEdition?.slug}/meetings/${this.meetingId}`)
        .then((response) => {
          this.meeting = response.data.meeting;
          this.defaultSearchPage = response.data.default_search_page;

          if (this.meeting.show_countdown) {
            this.countdownTimerDate = new Date(this.meeting.countdown.atom_string).getTime();
            this.countdownTimerInterval = setInterval(this.countdown, 1000);
          }
        })
        .catch(() => {
          this.errored = true;
          this.notifications.push({
            time: Date.now(),
            type: 'warning',
            // eslint-disable-next-line no-underscore-dangle
            message: this.__('meeting.errors.cannot_load'),
            icon: 'fa-circle-info',
          });
        });
    },
    downloadIcs() {
      window.open(this.downloadIcsUrl);
    },
    gotoAddColleagueModal() {
      window.location.hash = `/meetings/${this.meetingId}/add-colleague`;
    },
    sendChat() {
      this.newMessage = this.newMessage.trim();

      if (this.newMessage.length === 0) {
        return;
      }

      const endpoint = `/${this.eventEdition.slug}/meetings/${this.meetingId}/conversation`;

      axios.post(endpoint, { new_message: this.newMessage })
        .then(() => {
          this.newMessage = '';
          this.$refs.conversation.update();
          this.emitter.emit('inboxMessages.new');
        }).catch((response) => {
          this.errored = true;
          this.errorMessage = response?.data?.message;
          this.notifications.push({
            time: Date.now(),
            type: 'error',
            message: this.errorMessage ?? "Couldn't load contact information.",
            icon: 'fa-circle-info',
          });
        });
    },
  },
  computed: {
    counterpartLead() {
      return this.meeting.attendees.find((attendee) => attendee.is_lead && attendee.company_id !== this.activeContact.company_id);
    },
    cancelledBy() {
      return this.meeting.attendees.find((attendee) => attendee.id === this.meeting.cancelled_by);
    },
    hasColleaguesToAdd() {
      return this.meeting.can_add_colleagues;
    },
    activeContact() {
      return user.activeContact;
    },
    eventEdition() {
      return store.eventEdition;
    },
    downloadIcsUrl() {
      return `/${this.eventEdition?.slug}/meetings/${this.meetingId}/download-ics`;
    },
    isCancelled() {
      return this.meeting.status === 'cancelled';
    },
  },
};
</script>
