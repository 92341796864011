<template>
  <div
    v-if="registrationStore.currentQuestion.answer?.length > 0"
    class="registration-selected-items-counter">
    {{ registrationStore.currentQuestion.answer?.length }} selected
  </div>
  <div class="registration-demographic-multi-select"
    :class="{'registration-demographic-multi-select--inline' :
          registrationStore.currentQuestion.layout === 'inline',
          'registration-demographic-multi-select--stacked' :
          registrationStore.currentQuestion.layout === 'stacked'}"
    >
      <FrontendCheckboxInput
        :label="registrationStore.currentQuestion.title"
        v-model="registrationStore.currentQuestion.answer"
        :options="registrationStore.currentQuestion.options.map((option) => ({
          value: option.id,
          text: option.name,
        }))"
        :errors="registrationStore.currentQuestion.error"
        :name="registrationStore.currentQuestion.question_type"
        :required="registrationStore.currentQuestion.is_required"
      />
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import FrontendCheckboxInput from '~/Components/Frontend/Inputs/FrontendCheckboxInput.vue';
import useRegistrationStore from '~/Store/Registration';

export default {
  type: 'lazy',
  name: 'RegistrationDemographicMultiSelectComponent',
  components: {
    FrontendCheckboxInput,
  },
  computed: {
    // Store will be accessible as its id + 'Store'
    ...mapStores(useRegistrationStore),
  },
};
</script>
