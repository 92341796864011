<template>
  <div>
    <div class="registration-alert">
      <h2 class="registration--title">{{ registrationStore.alert.message }}</h2>
      <a :href="registrationStore.alert?.login_route"
        v-if="registrationStore.alert?.action === 'send_login_link'"
        class="button btn btn-primary" >
        {{ __('registrations.form.buttons.login') }}
        <i class="fa-solid fa-long-arrow-right"></i>
      </a>
      <div class="chip-list" v-if="registrationStore.alert?.resend">
        <span class="chip chip--small chip--success">
          <i class="far fa-circle-check me-1"></i>
          {{ __('registrations.form.actions.resend_email.message') }}
        </span>
      </div>
      <a href="#"
        @click="resend"
        v-else-if="registrationStore.alert?.action === 'send_incomplete_registration_link'">
        {{ __('registrations.form.actions.resend_email') }}
        <i class="fa-solid fa-long-arrow-right"></i>
      </a>
    </div>
    <RegistrationFooter></RegistrationFooter>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import useRegistrationStore from '~/Store/Registration';

export default {
  name: 'RegistrationAlert',
  props: {
    registrationActionRoute: {
      type: String,
      required: true,
    },
  },
  computed: {
    // Store will be accessible as its id + 'Store'
    ...mapStores(useRegistrationStore),
  },
  methods: {
    resend() {
      this.registrationStore.performAction(this.registrationActionRoute, true);
    },
  },
};
</script>
