<template>
  <div class="registration-welcome__sponsor-wrapper"
    v-if="this.registrationStore.placement.src">
    <a
      :href="this.registrationStore.placement.url"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        :src="this.registrationStore.placement.src"
        class="registration-welcome__sponsor-image"
        :alt="this.registrationStore.placement?.name"
      />
    </a>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import useRegistrationStore from '~/Store/Registration';
import axios from 'axios';

export default {
  name: 'RegistrationSponsorPlacement',
  props: {
    form: {
      required: true,
      type: Object,
    },
    registrationSponsorshipPlacementRoute: {
      type: String,
      required: true,
    },
  },
  computed: {
    // Store will be accessible as its id + 'Store'
    ...mapStores(useRegistrationStore),
  },
  created() {
    axios.get(this.registrationSponsorshipPlacementRoute)
      .then((response) => {
        if (response.data.data) {
          this.registrationStore.updatePlacement(response.data.data);
        }
      });
  },
};
</script>
