<template>
  <FrontendPhoneInput
    id="{{ registrationStore.currentQuestion.id }}"
    :required="registrationStore.currentQuestion.is_required"
    v-model="registrationStore.currentQuestion.answer"
    v-model:errors="registrationStore.currentQuestion.error"
    name="phone"
    :autofocus="true"
  />
</template>

<script>
import { mapStores } from 'pinia';
import FrontendPhoneInput from '~/Components/Frontend/Inputs/FrontendPhoneInput.vue';
import useRegistrationStore from '~/Store/Registration';

export default {
  type: 'lazy',
  name: 'RegistrationPhoneComponent',
  components: {
    FrontendPhoneInput,
  },
  computed: {
    // Store will be accessible as its id + 'Store'
    ...mapStores(useRegistrationStore),
  },
};
</script>
