<template>
  <div
    @click.prevent="openBooking"
    class="my-schedule__booking"
    :data-bookable-type="type"
    :style="{
      '--schedule-booking-duration': duration,
      '--schedule-booking-start': minutesSince,
    }"
  >
    <div class="my-schedule__booking-summary">
      <div class="my-schedule__booking-type-badge">
        <i :class="icon"></i>
        {{ typeBadge }}
      </div>

      <p class="my-schedule__booking-title">
        <span v-if="type !== 'Unavailability'">
          {{ title }}
        </span>
      </p>

      <div class="my-schedule__booking-time">
        <i class="fas fa-clock"></i>
        {{ startTime }} - {{ endTime }}
      </div>

      <div
        style="display: none;"
        v-if="meeting && meeting.source_type && meeting.source_type !== 'user-invite'"
        class="my-schedule__meeting-source">
      >
        {{ __('my_schedule.bookings.source_types.' + meeting.source_type.replace('-', '_')) }}
      </div>
    </div>

    <div class="my-schedule__booking-details">
      <div v-if="location" style="margin-bottom: var(--sizing-2)">
        <i class="fas fa-location-dot"></i> {{ location }}
        <strong v-if="meeting && meeting.table_number">
          {{ __('my_schedule.bookings.table_number', { number: meeting.table_number }) }}
        </strong>
      </div>

      <div v-if="walkingDistance" style="margin-bottom: var(--sizing-2)">
        <i class="fas fa-person-walking"></i>
        {{walkingDistance}}
      </div>

      <div
        v-if="type === 'Meeting'"
        class="my-schedule__booking-description my-schedule__booking-description--attendees"
        style="margin-top: var(--sizing-3)"
      >
        <div v-for="contact in meeting.attendees" :key="contact.id">
          <ContactAvatar :contact="contact" size="small"/>
        </div>
        <div v-if="meeting.attendees.length > 4">
          +{{ meeting.attendees.length - 4}}
        </div>
      </div>
      <div v-else v-html="description" class="my-schedule__booking-description"></div>

      <div class="my-schedule__booking-button-holder">
        <a href="#" @click.prevent="openBooking">
          {{ buttonText }}
          <i class="fa-regular fa-arrow-right"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ContactAvatar from '../CRM/Components/ContactAvatar.vue';

export default {
  components: { ContactAvatar },
  props: {
    title: {
      type: String,
      required: true,
    },
    minutesSince: {
      type: Number,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    typeBadge: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    startTime: {
      type: String,
      required: true,
    },
    endTime: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    walkingDistance: {
      type: String,
    },
    meeting: {
      type: Object,
    },
    conferenceSession: {
      type: Object,
    },
    exclusiveEvent: {
      type: Object,
    },
    otherBooking: {
      type: Object,
    },
  },
  methods: {
    openBooking() {
      if (this.type === 'Meeting') {
        window.location.href = `#/meetings/${this.meeting.id}/details`;
        return;
      }
      if (this.type === 'ConferenceSession') {
        window.location.href = `#/sessions/${this.conferenceSession.id}/show`;
        return;
      }
      if (this.type === 'ExclusiveEvent') {
        window.location.href = `#/exclusive-events/${this.exclusiveEvent.id}/show`;
        return;
      }
      if (this.type === 'Unavailability') {
        window.location.href = '#/update-availability';
        return;
      }
      window.location.href = `#/other-bookings/${this.otherBooking.id}`;
    },
  },
  computed: {
    location() {
      if (this.type === 'Meeting') {
        return this.meeting.location.long;
      }
      if (this.type === 'ConferenceSession') {
        return this.conferenceSession.location.long;
      }
      if (this.type === 'ExclusiveEvent') {
        return this.exclusiveEvent.location.long;
      }
      return '';
    },
  },
};
</script>
