<template>
  <div class="profile-row legal-notice-row">
    <div class="profile-row__label legal-notice-row__label">
      {{ __('profile.rows.legal_notices.title') }}
    </div>
    <div class="profile-row__value legal-notice-row__links">
      <div v-for="legal_notice in legalNotices" :key="legal_notice.id">
        <a
          :aria-label="legal_notice.name"
          target="_blank"
          :href="legal_notice.link"
          class="button button--link"
        >
          {{ legal_notice.name }}
          <i class="fa fa-arrow-up-right-from-square fa-xs"></i>
        </a>
      </div>
      <div>
        <a
          :aria-label="__('profile.rows.legal_notices.eventwarePrivacyNotice')"
          target="_blank"
          href="https://www.eventware.com/legal/privacy-policy"
          class="button button--link"
        >
          {{ __('profile.rows.legal_notices.eventwarePrivacyNotice') }}
          <i class="fa fa-arrow-up-right-from-square fa-xs"></i>
        </a>
      </div>
      <div>
        <a
          :aria-label="__('profile.rows.legal_notices.eventwareEndUserLicenceAgreement')"
          target="_blank"
          href="https://www.eventware.com/legal/end-user-license-agreement"
          class="button button--link"
        >
          {{ __('profile.rows.legal_notices.eventwareEndUserLicenceAgreement') }}
          <i class="fa fa-arrow-up-right-from-square fa-xs"></i>
        </a>
      </div>
      <p>
        {{ __('profile.rows.legal_notices.learnMoreAbout') }}
        <a
          :aria-label="__('profile.rows.legal_notices.eventware')"
          target="_blank"
          href="https://www.eventware.com"
          class="button button--link"
        >
          {{ __('profile.rows.legal_notices.eventware') }}
          <i class="fa fa-arrow-up-right-from-square fa-xs"></i>
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    legalNotices: {
      type: Array,
      required: true,
    },
  },
};
</script>
