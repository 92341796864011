export default {
  'assets.image.image': 'Image',
  'assets.image.name': 'Titre de l\'image',
  'assets.video.title': 'Titre de la vidéo',
  'assets.video.description': 'Description de la vidéo',
  'assets.video.title.default': 'Vidéo sans nom',
  'assets.video.video': 'Vidéo',
  'buttons.add': 'Rajouter',
  'buttons.addMore': 'Ajouter',
  'buttons.back': 'Retour',
  'buttons.cancel': 'Annuler',
  'buttons.change': 'Changer',
  'buttons.close': 'Fermer',
  'buttons.clear': 'Réinitialiser',
  'buttons.contact': 'Contact',
  'buttons.delete': 'Effacer',
  'buttons.done': 'Terminé',
  'buttons.edit': 'Modifier',
  'buttons.download': 'Télécharger',
  'buttons.help': 'Help',
  'buttons.impersonateUser': 'se connecter comme',
  'buttons.manage': 'Gérer',
  'buttons.manageImages': 'Gérer les images',
  'buttons.new': 'Nouveau',
  'buttons.next': 'Suivant',
  'buttons.return': 'Retour',
  'buttons.save': 'Sauvegarder',
  'buttons.sendLink': 'Envoyer le lien',
  'buttons.set': 'Définir',
  'buttons.set_password': 'Définir un mot de passe',
  'buttons.update': 'Actualiser',
  'buttons.uploadImage': 'Télécharger une image',
  'buttons.locked': 'fermé à clé',
  'buttons.write_with_ai': "Écrire avec l'IA",
  'buttons.recreate_with_ai': "Recréer avec l'IA",
  'buttons.notifications': 'Notifications',
  'buttons.run_search': 'Lancer',
  'buttons.show_more': 'Afficher plus',
  'buttons.show_less': 'Afficher moins',
  'action.unfavourite': 'Retirer des favoris',
  'action.favourite': 'Favori',
  'action.unhide': 'Démasquer',
  'action.hide': 'Cacher',
  'catalogue.catalogueEntry.description': 'Descriptif',
  'catalogue.catalogueEntry.gallery': 'Images',
  'catalogue.catalogueEntry.highlights': 'Points forts',
  'catalogue.catalogueEntry.image': 'Image',
  'catalogue.catalogueEntry.keywords': 'Mots clés ',
  'catalogue.catalogueEntry.links': 'Liens',
  'catalogue.catalogueEntry.moreOptions': 'Plus d\'options',
  'catalogue.catalogueEntry.name': 'Nom',
  'catalogue_product_form.add_image_success': 'Yous avez ajouté une image à :filename, elle sera visible après avoir rafraichi la page.',
  'catalogue_product_form.delete': 'Effacer',
  'catalogue_product_form.description': 'Description*:',
  'catalogue_product_form.edit': 'Modifier',
  'catalogue_product_form.highlights.add': 'Ajouter une autre caractéristique',
  'catalogue_product_form.highlights.no_highlights': 'Pas de caractéristique principale ajouté',
  'catalogue_product_form.highlights.placeholder': '',
  'catalogue_product_form.highlights.title': 'Caractéristiques principales',
  'catalogue_product_form.image': 'Image du Produit*',
  'catalogue_product_form.images': 'Images',
  'catalogue_product_form.keywords': 'Mots-clé',
  'catalogue_product_form.keywords_descriptions': "Séparez plusieurs mots-clé avec une virgule ','",
  'catalogue_product_form.name': 'Nom*:',
  'catalogue_product_form.order': 'Ordonner:',
  'catalogue_product_form.order_description': 'Ordre croissant:',
  'catalogue_product_form.recommended_image_size': 'Taille recommandée: 1280px par 720px (les images seront affichées avec un ratio 16:9). Taille de fichier maximum: 5Mb',
  'catalogue_product_form.remove_image_success': 'Vous avez ajouté une image à :filename, elle sera visible après avoir rafraichi la page.',
  'catalogue_product_form.save': 'Sauvegarder',
  'catalogue_product_form.save_then_upload': 'Veuillez sauvegarder votre produit, ensuite  cliquez sur "Modifier" sur la prochaine page afin d\'ajouter des photos',
  'catalogue_product_form.subtitle': 'Sous-titre*:',
  'catalogue_product_form.tagline': 'Introduction*:',
  'catalogue_product_form.title.create': 'Créer un {{ type }}',
  'catalogue_product_form.title.update': 'Mise à jour {{ type }}',
  'catalogue_product_form.update': 'Réactualiser',
  'catalogue_product_form.update_photo': 'Mise à jour de la photo',
  'catalogue_product_form.upload_photo': 'Télécharger une photo',
  'catalogue_product_preview.buttons.view': 'Afficher',
  'catalogue_product_preview.description': 'Description',
  'catalogue_product_preview.highlights': 'Caractéristiques principales',
  'catalogue_product_preview.learn_more': 'En savoir plus',
  'catalogue_product_preview.more': 'Plus',
  'catalogue_product_preview.read_more': 'Lire la suite',
  'catalogue_product_preview.show_less': 'Afficher moins',
  'catalogue_product_preview.view_product': 'Voir le produit',
  'company.actions.business_card_dropped': 'Carte de visite déposée',
  'company.actions.drop_business_card': 'Laisser ma carte de visite',
  'company.company_book_meeting_modal.cancel': 'Annuler',
  'company.company_book_meeting_modal.cannot_meet': 'Vous ne pouvez pas prendre rendez-vous avec les contacts de cette entreprise',
  'company.company_book_meeting_modal.meet': 'Rendez-vous',
  'company.company_book_meeting_modal.more': 'Plus',
  'company.company_book_meeting_modal.next': 'Suivant →',
  'company.company_book_meeting_modal.other_contacts': 'Autres contacts',
  'company.company_book_meeting_modal.recommended_contacts': 'Recommandés',
  'company.company_book_meeting_modal.select_contact': 'Selectionner un contact:',
  'company.company_book_meeting_modal.title': 'Demande de rendez-vous',
  'company.company_preview_modal.activities': 'Activités & interêts',
  'company.company_preview_modal.book_meeting': 'Prendre rendez-vous',
  'company.company_preview_modal.company_profile': "Profil de l'entreprise",
  'company.company_preview_modal.people': "L'équipe",
  'company.company_preview_modal.read_more': 'Lire la suite',
  'company.company_preview_modal.title': '{{ company_display_name }}',
  'company.company_preview_modal.view_people': 'Afficher tous les contacts →',
  'company.company_preview_modal.view_profile_page': 'Afficher la page de profil',
  'company.drop_business_card.agreement': 'Je donne ma permission de partager mon <strong> nom, ma fonction et mon adresse e-mail </strong> avec {{ company_display_name }}',
  'company.drop_business_card.cancel': "J'annule",
  'company.drop_business_card.disclaimer': 'Je comprends que mes informations seront conservées par {{ company_display_name }} et je dois contacter {{ company_display_name }} afin de modifier mes préférences de communications.',
  'company.drop_business_card.header': 'Partagez vos coordonées',
  'company.drop_business_card.share': "J'accepte",
  'company.drop_business_card.share_success.done': 'Fermer',
  'company.drop_business_card.share_success.message': 'Votre nom, fonction, adresse e-mail et numéro de téléphone ont été partagés avec {{ company_display_name }}',
  'company.drop_business_card.share_success.title': 'Informations partagées',
  'company_card.view_company': "Voir plus d'informations",
  'concierge_modal.info_text': "Contactez l'équipe d'assistance dédiée au service de rendez-vous.",
  'concierge_modal.link_text': "Besoin d'aide?",
  'concierge_modal.whatsapp_button': 'Chat on WhatsApp',

  // Conference sessions
  'conference_sessions.ask_a_question.box_closing': 'Cette fenêtre se fermera automatiquement.',
  'conference_sessions.ask_a_question.buttons.ask': 'Demander une question',
  'conference_sessions.ask_a_question.buttons.cancel': 'Annuler',
  'conference_sessions.ask_a_question.buttons.send': 'Envoyer',
  'conference_sessions.ask_a_question.description': 'Le modérateur de la conférence va passer votre question en revue',
  'conference_sessions.ask_a_question.header': 'Demander une question',
  'conference_sessions.ask_a_question.placeholder': 'Ma question...',
  'conference_sessions.ask_a_question.question_submitted': 'Votre question a été envoyée.',
  'conference_sessions.preview_session.speakers': 'Intervenants',
  'conference_sessions.preview_session.title': 'Conférence',
  'conference_sessions.row.buttons.add_to_schedule': 'Ajouter au planning',
  'conference_sessions.row.buttons.join_session': 'Rejoindre la conférence',
  'conference_sessions.row.buttons.more': 'Plus',
  'conference_sessions.row.buttons.preview_session': 'Aperçu',
  'conference_sessions.row.buttons.remove_from_schedule': 'Enlever de mon planning',
  'conference_sessions.row.buttons.schedule_clash': 'Clash sur le planning',
  'conference_sessions.row.buttons.booking_capacity_full': 'Complet',
  'conference_sessions.row.buttons.watch_now': 'Regarder',
  'conference_sessions.row.session': 'Conférence',
  'conference_sessions.row.sponsored_by': 'Sponsorisé par',
  'conference_sessions.row.starting_soon': 'Commence bientôt',

  'conversations.accepted_by': 'Demande de rendez-vous acceptée par {{ sender }}',
  'conversations.buttons.send_invite': 'Envoyer la demande',
  'conversations.cancelled_by': 'Demande de rendez-vous annulée par {{ sender }}',
  'conversations.chips.cancelled': 'Rendez-vous annulé',
  'conversations.chips.colleague_added': 'Collègue ajouté',
  'conversations.chips.confirmed': 'Rendez-vous confirmé',
  'conversations.chips.declined': 'Demande refusée',
  'conversations.chips.forwarded': 'Demande transmise',
  'conversations.chips.new_invite': 'Demande de rendez-vous',
  'conversations.chips.updated': 'Rendez-vous changé',
  'conversations.chips.withdrawn': 'Demande retirée',
  'conversations.colleague_added': '{{ added_by }} a ajouté {{ new_colleague }} au rendez-vous.',
  'conversations.declined_by': 'Demande de rendez-vous refusée par {{ sender }}',
  'conversations.forwarded_to': 'Demande de rendez-vous transmise à {{ recipient }}',
  'conversations.invitation_from': 'Demande de rendez-vous de {{ sender }}',
  'conversations.view_meeting': 'Voir le rendez-vous',
  'conversations.withdrawn_by': 'Demande de rendez-vous retirée par {{ sender }}',
  'countdown.days': 'jours',
  'countdown.hours': 'heures',
  'countdown.minutes': 'minutes',
  'countdown.seconds': 'secondes',
  'crm.communicationPreference.channels.email': 'Emails de notification et de rappel',
  'crm.communicationPreference.channels.phone': 'Soutien à la communication téléphonique',
  'crm.communicationPreference.channels.sms': 'SMS de notification et de rappel',
  'crm.communicationPreference.name': 'Préférences',
  'crm.company.bio': 'Description de l\'entreprise',
  'crm.company.displayName': 'Nom à afficher',
  'crm.company.legalName': 'Nom légal',
  'crm.company.logo': 'Logo de l\'entreprise',
  'crm.company.officeLocation': 'Localisation',
  'crm.company.officeLocations': 'Localisations',
  'crm.company.socials': 'Réseaux sociaux',
  'crm.company.tagline': 'Courte introduction',
  'crm.company.websites': 'Site internet',
  'crm.companyOfficeLocation.address': 'Adresse',
  'crm.companyOfficeLocation.country': 'Pays',
  'crm.contact.avatar': 'Photo de profil',
  'crm.contact.bio': 'À propos de moi ',
  'crm.contact.country': 'Pays',
  'crm.contact.email': 'Email',
  'crm.contact.firstName': 'Prénom ',
  'crm.contact.fullName': 'Nom',
  'crm.contact.jobTitle': 'Fonction ',
  'crm.contact.lastName': 'Nom',
  'crm.contact.lookingFor': 'Rechercher',
  'crm.contact.managedBy': 'Responsables du profil ',
  'crm.contact.optouts': 'Se déconnecter ',
  'crm.contact.timezone': 'Fuseau horaire',
  'crm.contact.title': 'Intitulé ',
  'crm.link.type.website': 'Site internet',
  'crm.link.url': 'Url',
  'crm.phone.types.mobile': 'Portable',
  'crm.phone.types.office': 'Bureau',

  'directory.default_search_name': '{{ date }} Résultats de recherche',
  'directory.edit_search': 'Modifier cette recherche',
  'directory.export_wishlist': 'Télécharger',
  'directory.filters.apply': 'Appliquer',
  'directory.filters.clearAll': 'Réinitialiser',
  'directory.filters.close': 'Fermer les filtres',
  'directory.filters.filters': 'Classer et filtrer',
  'directory.filters.open': 'Classer et filtrer',
  'directory.filters.primary.select_one_option': 'Sélectionner <strong>une</strong> option:',
  'directory.filters.reset': 'Réinitialiser',
  'directory.noFavourites.message': "Vous n'avez pas encore de favoris.",
  'directory.noFavourites.title': 'Aucun favoris',
  'directory.noRecommendations.message': 'Aucun résultat à afficher.',
  'directory.noRecommendations.title': 'Aucun résultat',
  'directory.noResults.message': "Nous n'avons pas trouvé de résultats, veuillez revoir votre demande",
  'directory.noResults.title': 'Pas de résultats',
  'directory.pagination.load_more': 'Télécharger plus de résultats',
  'directory.save_search': 'Enregistrer la recherche',
  'directory.notification_frequency': 'Me notifier des nouveaux résultats de recherche',
  'directory.saved_search_success': 'Recherche enregistrée avec succès.',
  'directory.updated_search_success': 'Recherche mise à jour avec succès.',
  'directory.saved_search_card_error': 'Quelque chose s\'est mal passé, veuillez rafraîchir votre navigateur',
  'directory.search_name': 'Nom de la recherche',
  'directory.search_last_run': 'Dernière exécution : {{ date }}',
  'directory.text_search': 'Recherche : {{ value }}',
  'directory.initial_search': 'Initial : {{ value }}',
  'directory.noSavedSearches.title': 'Aucune recherche',
  'directory.noSavedSearches.message': 'Aucune recherche enregistrée. Commencez à <a href="?initialTab=search">chercher ...</a>',
  'directory.match_made.no_blocked_contacts_message': 'Vous n\'avez aucun contact bloqué',
  'directory.match_made.no_matched_contacts_message': 'Vous n\'avez aucun contact apparié',
  'directory.match_made.blocked_contacts': 'Contacts bloqués',
  'directory.match_made.matched_contacts': 'Contacts appariés',
  'directory.match_made.view_profile': 'Voir le profil',
  'directory.match_made.remove_block': 'Supprimer le blocage',
  'directory.match_made.convert_to_match': 'Convertir en correspondance',
  'directory.match_made.remove_match': 'Supprimer la correspondance',
  'directory.match_made.convert_to_block': 'Convertir en blocage',
  'directory.match_made.start_matching': 'Commencer le jumelage',

  'drop_image.buttons.cancel': 'Annuler',
  'drop_image.buttons.clear': 'Effacer',
  'drop_image.buttons.remove': 'Supprimer',
  'drop_image.buttons.submit': 'Soumettre',
  'drop_image.preview_text': 'Aperçu de {{ image_name }}',
  'drop_image.text': 'Glissez et déplacez ou cliquez ici',
  'event_editions.card.coming_soon': 'Ouvrira prochainement',
  'event_editions.card.event_closed': 'Evénement clôturé',
  'event_editions.card.launch': 'Accéder',
  'event_editions.card.notify_me': 'Prévenez-moi',
  'event_editions.card.reg_done': 'Vous vous êtes inscrit à cet évènement',
  'event_editions.card.reg_open': 'Inscriptions ouvertes',
  'event_editions.card.reg_soon': 'Les inscriptions ouvriront bientôt',
  'event_editions.card.register': 'Inscrivez-vous maintenant!',
  'expanded_contact.buttons.accept_invite': 'Accepter',
  'expanded_contact.buttons.book_meeting': 'Prendre rendez-vous',
  'expanded_contact.buttons.decline_invite': 'Refuser',
  'expanded_contact.buttons.forward_to_colleague': 'Transférer',
  'expanded_contact.buttons.withdraw_invite': 'Annuler',
  'expanded_contact.buttons.match': 'Correspondre',
  'expanded_contact.buttons.remove': 'Supprimer',
  'expanded_contact.buttons.block': 'Bloquer',
  'expanded_contact.buttons.blocked': 'Bloqué',
  'expanded_contact.buttons.matched': 'Correspondant',
  'expanded_contact.meeting.addColleague.button': 'Ajouter mes collègues',
  'expanded_contact.meeting.addColleague.noColleagues': 'Pas de collègue disponible',
  'expanded_contact.meeting.addColleague.subheader': 'Ajouter un/des collègues',
  'expanded_contact.meeting.back': 'Retour aux détails du rendez-vous',
  'expanded_contact.meeting.cancel.button': 'Annuler',
  'expanded_contact.meeting.cancel.subheader': 'Je ne suis plus en mesure de participer à ce rendez-vous',
  'expanded_contact.meeting.cancelledHeader': 'Rendez-vous annulé',
  'expanded_contact.meeting.contactNumber': 'Numéro de portable: ',
  'expanded_contact.meeting.forwardToColleague.button': 'Séléctionner un collègue',
  'expanded_contact.meeting.forwardToColleague.subheader': 'Transferrer le rendez-vous à un collègue',
  'expanded_contact.meeting.informationHeader': 'Détails du rendez-vous',
  'expanded_contact.meeting.informationSubheader': 'Nous vous enverrons un SMS de rappel 10 minutes avant votre rendez-vous.',
  'expanded_contact.meeting.inviteColleagues.button': 'Inviter des collègues',
  'expanded_contact.meeting.inviteColleagues.subheader': 'Transférer le rendez-vous à un collègue',
  'expanded_contact.meeting.missingContactNumber': 'Veuillez ajouter votre numéro de portable',
  'expanded_contact.meeting.participants': 'Participants',
  'expanded_contact.meeting.reschedule.button': 'Modifier mon rendez-vous',
  'expanded_contact.meeting.reschedule.subheader': "Modifier la date, l'heure ou le lieu de rendez-vous",
  'expanded_contact.show.about.company.title': "Informations sur l'entreprise",
  'expanded_contact.show.about.contact.country': 'Pays',
  'expanded_contact.show.about.contact.looking_for': 'Je recherche',
  'expanded_contact.show.about.contact.title': 'À propos de moi',
  'expanded_contact.show.colleagues': "L'équipe",
  'expanded_contact.show.errors.cannot_load': 'Nous ne pouvons pas afficher les informations demandées',
  'expanded_contact.show.other_contacts': 'Autres contacts à {{ company_display_name }}',
  'expanded_contact.show.respond': 'Répondre',
  'expanded_contact.show.send_invite': 'Prendre rendez-vous',
  'expanded_contact.show.view_invite': 'Afficher la demande rendez-vous',
  'expanded_contact.show.view_meeting': 'Afficher le rendez-vous',
  'expanded_contact.show.view_profile': 'Afficher le profil',
  'expanded_contact.match_score_tooltip_text': 'Score de correspondance – <a href="{{ url }}">En savoir plus</a>',

  // Exclusive events
  'exclusive_events.preview_session.title': 'Événement exclusif',
  'exclusive_events.no_description': "Il n'y a pas de description pour cet événement.",
  'exclusive_events.row.buttons.booking_capacity_full': 'Complet',
  'exclusive_events.row.buttons.booking_add_to_schedule': 'Ajouter au planning',
  'exclusive_events.row.buttons.booking_schedule_clash': 'Conflit de calendrier',
  'exclusive_events.row.buttons.booking_remove_from_schedule': 'Supprimer du planning',

  'filter_modal.cancel': 'Annuler',
  'filter_modal.save_update': 'Sauvegarder',
  'form.image.changeTip': '<a href=\'#\'>Cliquez pour modifier</a> ou glissez et déposez. <br/>',
  'form.image.maxSize': 'Taille maximale du fichier 5MB',
  'form.image.tip': '<a href=\'#\'> Cliquez pour télécharger</a>  ou glissez et déposez. <br/>',
  'form.image.unsupportedFileType': 'Type de fichier non valable. Utiliser un jpg, jpeg, png or webp',
  'form.image.recommended_size': 'Taille recommandée: {{recommended_size}}',
  'form.phone.placeholder': 'Entrer votre numéro de téléphone',
  'form.video.tip': '<a href=\'#\'> Cliquez pour télécharger</a>  ou glissez et déposez. <br/>',
  'forms.select_all': '(tout)',
  'general.unknown_error': 'Oops - Il y a eu un problème. Veuillez rafraîchir votre navigateur et essayer à nouveau',
  'inbox.header': 'Mes messages',
  'inbox.invites.buttons.accept': 'Accepter',
  'inbox.invites.buttons.askQuestion': 'Envoyer un message',
  'inbox.invites.buttons.decline': 'Refuser',
  'inbox.invites.buttons.forward': 'Transmettre à un collègue',
  'inbox.invites.buttons.moreOptions': 'Plus d’options ...',
  'inbox.invites.buttons.next_invite': 'Demande suivante',
  'inbox.invites.buttons.read_more': 'Lire la suite',
  'inbox.invites.buttons.respond': 'Répondre',
  'inbox.invites.buttons.show_less': 'moins',
  'inbox.invites.buttons.view': 'Afficher',
  'inbox.invites.buttons.viewProfile': 'Afficher le profil',
  'inbox.invites.buttons.withdraw': 'Annuler',
  'inbox.invites.declinedByActiveUser': 'Vous avez refusé cette demande {{ declined_time }}',
  'inbox.invites.declinedByRecipient': '{{ declined_by }} a refusé cette demande {{ declined_time }}',
  'inbox.invites.expiry.expired': 'Expiré',
  'inbox.invites.expiry.expires': 'Expire',
  'inbox.invites.expiry.expiresSoon': 'Vous avez des demande qui expirent bientôt.',
  'inbox.invites.expiry.whenExpired': 'Vous avez des invitations à des réunions expirées.',
  'inbox.invites.findPeople': 'Recherche',
  'inbox.invites.forwardedByActiveUser': 'Vous avez envoyé cette demande à {{ forwardee }}',
  'inbox.invites.forwardedByColleague': 'Transférré par {{ forwarded_by }}',
  'inbox.invites.forwardedByRecipient': 'Transférré à {{ forwardee }} par {{ forwarded_by }}',
  'inbox.invites.info.sent_message_concierge': 'Notre équipe de rendez-vous contactera ce participant vous ne recevez pas de réponse.',
  'inbox.invites.noResults.message': '<a href="{{ findPeopleUrl }}">Faites une recherche</a> pour trouver de nouvelles opportunités.',
  'inbox.invites.noResults.title': 'Pas de demandes de rendez-vous',
  'inbox.invites.sort.newest': 'Plus récent',
  'inbox.invites.sort.oldest': 'Moins récent',
  'inbox.invites.sort.placeholder': 'Trier',
  'inbox.invites.statuses.accepted': 'Accepter',
  'inbox.invites.statuses.declined': 'Refusé',
  'inbox.invites.statuses.expired': 'Expiré',
  'inbox.invites.statuses.forwarded': 'Transmis',
  'inbox.invites.statuses.pending': 'En attente',
  'inbox.invites.statuses.sent': 'Envoyé',
  'inbox.invites.statuses.unread': 'Nouveau message',
  'inbox.invites.statuses.withdrawn': 'Annulé',
  'inbox.invites.statusFilters.accepted': 'Accepté',
  'inbox.invites.statusFilters.declined': 'Refusé',
  'inbox.invites.statusFilters.expired': 'Expiré',
  'inbox.invites.statusFilters.forwarded': 'Transmis',
  'inbox.invites.statusFilters.pending': 'Action requise',
  'inbox.invites.statusFilters.sent': 'Envoyé',
  'inbox.invites.statusFilters.withdrawn': 'Annulé',
  'inbox.invites.whenReceived': 'Reçu',
  'inbox.invites.whenSent': 'Envoyé',
  'inbox.invites.withdrawnByActiveUser': 'Vous avez retiré votre demande {{ withdrawn_time }}',
  'inbox.invites.withdrawnBySender': '{{ withdrawn_by }} a retiré cette demande {{ withdrawn_time }}',
  'inbox.loading': 'Chargement...',
  'inbox.meetings.buttons.addColleagues': 'Inviter un collègue',
  'inbox.meetings.buttons.cancel': 'Annuler le rendez-vous',
  'inbox.meetings.buttons.moreOptions': 'Plus d’options ...',
  'inbox.meetings.buttons.rescheduleMeeting': 'Modifier le rendez-vous',
  'inbox.meetings.cancelledBy': '{{ cancelled_by }} a annulé cette demande {{ cancel_time }}',
  'inbox.meetings.cancelledByActiveUser': 'Vous avez annulé cette demande {{ cancel_time }}',
  'inbox.meetings.filters.allDays': 'Tout',
  'inbox.meetings.filters.showCancelled': 'Afficher les rendez-vous annulés',
  'inbox.meetings.meetingsToRate_one': 'Vous avez un rendez-vous à évaluer. <a href="#/meetings/{{ nextId }}/rate">Evaluer</a>',
  'inbox.meetings.meetingsToRate_other': 'Vous avez {{ count }} rendez-vous à évaluer. <a href="#/meetings/{{ nextId }}/rate">Evaluer</a>',
  'inbox.meetings.noResults.message': '<a href="{{ findPeopleUrl }}">Faites une recherche</a> pour trouver de nouvelles opportunités.',
  'inbox.meetings.noResults.title': "Vous n'avez pas encore de rendez-vous confirmés",
  'inbox.meetings.rate': 'Evaluation du rendez-vous',
  'inbox.meetings.statuses.cancelled': 'Annulé',
  'inbox.meetings.statuses.confirmed': 'Rendez-vous confirmé',
  'inbox.meetings.view': 'Détails',
  'inbox.messages.info.moreMessagesInThread': 'Il n’y a plus de message',
  'inbox.messages.info.newUnreadMessage': 'Nouveau chat. <a href="{{url}}">Répondre</a>',
  'inbox.messages.meetingInformation': 'Détails du rendez-vous',
  'inbox.messages.noResults.message': 'Vous n’avez pas de message.',
  'inbox.messages.noResults.title': 'Pas de message',
  'inbox.messages.sort.newest': 'Plus récent',
  'inbox.messages.sort.oldest': 'Plus ancien',
  'inbox.messages.statusFilters.all': 'Tout',
  'inbox.messages.statusFilters.unread': 'Non lus',
  'inbox.messages.whenReceived': 'Reçu',
  'inbox.messages.whenSent': 'Envoyé',
  'inbox.nextPage': 'Page suivante',
  'inbox.previousPage': 'Page précédente',
  'inbox.relationshipLabels.from': 'De:',
  'inbox.relationshipLabels.to': 'À:',
  'inbox.relationshipLabels.with': 'Avec:',
  'inbox.subtitle.meetings': 'Rendez-vous confirmés',
  'inbox.subtitle.messages': 'Messages',
  'inbox.subtitle.null': '',
  'inbox.subtitle.receivedInvites': 'Demandes de rendez-vous reçues',
  'inbox.subtitle.sentInvites': 'Demandes de rendez-vous envoyées',
  'meeting.add_colleagues.buttons.add': 'Ajouter des collègues',
  'meeting.add_colleagues.buttons.cancel': 'Annuler',
  'meeting.add_colleagues.buttons.done': 'Fermer',
  'meeting.add_colleagues.buttons.invite_more': 'Envoyer plus de demandes',
  'meeting.add_colleagues.header': 'Ajouter des collègues',
  'meeting.add_colleagues.no_colleagues': 'Pas de collègue disponible',
  'meeting.add_colleagues.subheader': 'Ajouter des collègues à votre rendez-vous avec <strong>{{display_name}}, {{job_title}}</strong> à <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting.add_colleagues.success.message_one': 'Nous avons ajouté votre collègue à ce rendez-vous.',
  'meeting.add_colleagues.success.message_other': 'Nous avons ajouté vos collègues à ce rendez-vous.',
  'meeting.add_colleagues.success.title_one': 'Collègue ajouté',
  'meeting.add_colleagues.success.title_other': 'Collègues ajoutés',
  'meeting.addColleague.button': 'Ajouter des collègues',
  'meeting.addToCalendar': 'Ajouter au calendrier',
  'meeting.attendees': 'Vous et {{ displayName }}',
  'meeting.attendeesCount': '{{count}} personnes',
  'meeting.attendeesTitle': 'Participants',
  'meeting.back_to_meeting': 'Retour au rendez-vous',
  'meeting.cancel.button': "Envoyer l'annulation",
  'meeting.cancel.cannotAttend': 'Je ne suis plus en mesure de participer à ce rendez-vous.',
  'meeting.cancel.keep': 'Garder le rendez-vous',
  'meeting.cancel.messageLabel': 'Note',
  'meeting.cancel.messagePlaceholder': 'Malheureusement je dois annuler ce rendez-vous car ...',
  'meeting.cancel.onlyOrganiserCanCancel': "Uniquement l'organisateur du rendez-vous peut annuler le meeting",
  'meeting.cancel.reasonLabel': "Raison de l'annulation",
  'meeting.cancel.reasonPlaceholder': 'Séléctionner une option...',
  'meeting.cancel.subheader': 'Annuler votre rendez-vous avec <strong> {{ displayName }}, {{ jobTitle }}</strong> à <a href="{{ companyUrl }}"> {{ companyDisplayName }} </a>.',
  'meeting.cancel.title': 'Annuler le rendez-vous',
  'meeting.cancellation_success.message': 'Votre rendez-vous avec <strong>{{display_name}}, {{job_title}}</strong> à <a href="{{company_url}}">{{company_display_name}}</a> a été annulé',
  'meeting.cancellation_success.title': 'Demande de rendez-vous refusée',
  'meeting.cancelledBy': 'Ce rendez-vous a été annulé par {{ cancelledBy_display_name }} {{cancelled_at_time}}',
  'meeting.close': 'Fermer',
  'meeting.countdown_minutes': 'minutes, ',
  'meeting.countdown_seconds': 'secondes',
  'meeting.errors.cannot_load': "Il n'est pas possible de télécharger ce rendez-vous.",
  'meeting.finished': 'Ce rendez-vous est fini.',
  'meeting.forward.button': 'Séléctionner un collègue',
  'meeting.forward.subheader': 'Transférer le rendez-vous à un collègue',
  'meeting.forward.title': 'Transférer a un collègue',
  'meeting.in_progress': 'Rendez-vous en cours.',
  'meeting.join_now': 'Rejoindre maintenant',
  'meeting.meeting_confirmed.invite_colleagues': 'Inviter des collègues',
  'meeting.meeting_confirmed.message': 'Votre partenaire a été notifié.',
  'meeting.meeting_confirmed.next_invite': 'Demande suivante',
  'meeting.meeting_confirmed.title': 'Rendez-vous confirmé',
  'meeting.meeting_confirmed.view_meeting': 'Afficher les informations',
  'meeting.meeting_starts_in_countdown': 'Ce rendez-vous commence dans',
  'meeting.meetingCancelled': 'Rendez-vous annulé',
  'meeting.meetingConfirmed': 'Confirmé par tous les participants.',
  'meeting.mobileNumberLabel': 'Numéro de portable:',
  'meeting.panelTitle.cancelled': 'Rendez-vous annulé',
  'meeting.panelTitle.information': 'Détails du rendez-vous',
  'meeting.phoneNumberMissing': '<a href="{{ updatePhoneUrl }}">Veuillez ajouter un numéro de téléphone</a>',
  'meeting.rate_meeting.back_to_meeting': 'Retour au rendez-vous',
  'meeting.rate_meeting.buttons.cancel': 'Annuler',
  'meeting.rate_meeting.buttons.done': 'Fermer',
  'meeting.rate_meeting.buttons.next': 'Suivant',
  'meeting.rate_meeting.buttons.rate': 'Evaluer',
  'meeting.rate_meeting.buttons.save': 'Sauvegarder vos commentaires',
  'meeting.rate_meeting.errors.already_rated': 'Ce rendez-vous a déjà été évalué',
  'meeting.rate_meeting.fields.feedback.label': 'Commentaires (optionnel)',
  'meeting.rate_meeting.fields.meeting_did_not_happen.label': "Rendez-vous n'a pas eu lieu",
  'meeting.rate_meeting.fields.rating.label': 'Evaluez votre rendez-vous',
  'meeting.rate_meeting.header': 'Evaluez votre rendez-vous',
  'meeting.rate_meeting.subheader': "Comment s'est passé votre rendez-vous avec' <strong>{{display_name}}, {{job_title}}</strong> de {{company_display_name}}?",
  'meeting.rate_meeting.success.title': 'Evaluation envoyée',
  'meeting.reminder': "Nous enverrons un SMS de rappel à l'autre particpant de votre rendez-vous 10 minutes avant le rendez-vous.",
  'meeting.reschedule.back_to_meeting': 'Retour au rendez-vous',
  'meeting.reschedule.button': 'Modifier le rendez-vous',
  'meeting.reschedule.onlyOrganiserCanReschedule': 'Uniquement l’organisateur principal peut modifier un rendez-vous',
  'meeting.reschedule.subheader': 'Modifier la date, heure ou lieu du rendez-vous',
  'meeting.reschedule.title': 'Modifier le rendez-vous',
  'meeting.source_types.auto_accepted': 'Accepté automatiquement',
  'meeting.source_types.ez_match': 'Ez Match',
  'meeting.source_types.concierge_booked': 'Concierge',
  'meeting.source_types.walk_up': 'Rendez-vous planifié sur place',
  'meeting.stand': 'Stand {{ stand_number }}',
  'meeting.startingTime': 'Le rendez-vous commence {{ time_until_meeting }}',
  'meeting.statuses.cancelled': 'Annulé',
  'meeting.updatePhone': 'Modifier le numéro',
  'meeting_invite.back_to_conversation': 'Retour aux messages',
  'meeting_invite.decline.buttons.cancel': 'Annuler',
  'meeting_invite.decline.buttons.decline': 'Refuser la demande',
  'meeting_invite.decline.buttons.done': 'Fermer',
  'meeting_invite.decline.errors.cannot_load': 'La demande de rendez-vous ne peut pas être téléchargée.',
  'meeting_invite.decline.header': 'Refuser la demande de rendez-vous',
  'meeting_invite.decline.inputs.message.label': 'Ajouter un message',
  'meeting_invite.decline.inputs.reason.label': 'Raison du refus',
  'meeting_invite.decline.inputs.reason.placeholder': 'Séléctionner la raison',
  'meeting_invite.decline.subheader': 'Refuser la demande de rendez-vous de <strong>{{display_name}}, {{job_title}}</strong> à <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.decline.success.message': 'Votre demande de rendez-vous de <strong>{{display_name}}, {{job_title}}</strong> à <a href="{{company_url}}">{{company_display_name}}</a> a été refusée',
  'meeting_invite.decline.success.title': 'Demande refusée',
  'meeting_invite.forward_invite.buttons.cancel': 'Garder la demande',
  'meeting_invite.forward_invite.buttons.done': 'Fermer',
  'meeting_invite.forward_invite.buttons.forward': 'Transférer la demande',
  'meeting_invite.forward_invite.header': 'Transférer à un collègue',
  'meeting_invite.forward_invite.inputs.forward_to_colleague.label': 'Tapez une note pour votre collègue:',
  'meeting_invite.forward_invite.inputs.forward_to_colleague.placeholder': "J'ai reçu une demande de rendez-vous qui est intéressante pour votre/ton département...",
  'meeting_invite.forward_invite.subheader': 'Transférer votre demande de rendez-vous de <strong>{{display_name}}, {{job_title}}</strong> à <a href="{{company_url}}">{{company_display_name}}</a> à un collègue',
  'meeting_invite.forward_invite.success.message': 'Vous avez transféré ce rendez-vous à votre collègue.',
  'meeting_invite.forward_invite.success.title': 'Demande de rendez-vous transférée à un collègue',
  'meeting_invite.opt_in_meetings.accept': 'Oui, je consens au service de résautage',
  'meeting_invite.opt_in_meetings.decline': 'Non, je ne veux pas utiliser le service de résautage',
  'meeting_invite.opt_in_meetings.opt_in_meetings_text': "Consentez au résautage afin de trouver des contacts, de planifier des rendez-vous et de recevoir des demandes de rendez-vous de la part d'autres participants.",
  'meeting_invite.opt_in_meetings.previously_requested_opt_out': 'Vous avez précedemment demander de ne pas utiliser le service de résautage.',
  'meeting_invite.opt_in_meetings.title': 'Consentement pour faire du résautage',
  'meeting_invite.pending_invites.limit_reached.body': "Vous serez en mesure d'envoyer plus de demandes une fois que vos demandes déjà envoyées ont été acceptées.",
  'meeting_invite.pending_invites.limit_reached.title': 'Nombre maxiumum de demandes de rendez-vous a été atteint',
  'meeting_invite.pending_invites.remaining': '<i class="fa-solid fa-circle-info"></i> Vous avez utilisé <strong>{{ invite_spent }} de vos {{ invite_credits }}</strong> demandes.',
  'meeting_invite.send_invite.buttons.cancel': 'Annuler',
  'meeting_invite.send_invite.buttons.done': 'Fermer',
  'meeting_invite.send_invite.buttons.send': 'Envoyer la demande',
  'meeting_invite.send_invite.buttons.view_sent_invites': 'Demandes envoyées',
  'meeting_invite.send_invite.errors.cannot_send_invite': "Il n'est pas possible d'envoyer une demande de rendez-vous à ce participant.",
  'meeting_invite.send_invite.header': 'Créer ma demande de rendez-vous',
  'meeting_invite.send_invite.message_placeholder': 'Bonjour, Je souhaite vous rencontrer afin de ...',
  'meeting_invite.send_invite.sub_text': "L'autre participant du rendez-vous confirmera la date et l'heure du rendez-vous.",
  'meeting_invite.send_invite.success.message': 'Le participant que vous souhaitez rencontrer passera votre demande en revue et confirmera la date, heure et lieu du rendez-vous selon sa disponibilité.',
  'meeting_invite.send_invite.success.title': 'Demande envoyée',
  'meeting_invite.send_invite.success.update_availability': 'Modifier ma disponibilité',
  'meeting_invite.withdraw.buttons.cancel': 'Annuler',
  'meeting_invite.withdraw.buttons.done': 'Fermer',
  'meeting_invite.withdraw.buttons.withdraw': 'Retirer la demande de rendez-vous',
  'meeting_invite.withdraw.errors.cannot_load': 'Demande de peut pas être téléchargée.',
  'meeting_invite.withdraw.header': 'Retirer la demande',
  'meeting_invite.withdraw.inputs.message.label': 'Ajouter un message',
  'meeting_invite.withdraw.inputs.reason.label': "Raison de l'annulation",
  'meeting_invite.withdraw.inputs.reason.placeholder': 'Séléctionner la raison',
  'meeting_invite.withdraw.subheader': 'Retirer la demande de rendez-vous pour <strong>{{display_name}}, {{job_title}}</strong> à <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.withdraw.success.message': 'Votre demande de rendez-vous avec <strong>{{display_name}}, {{job_title}}</strong> à <a href="{{company_url}}">{{company_display_name}}</a> a été annulée',
  'meeting_invite.withdraw.success.title': 'Demande annulée',
  'meeting_scheduler.acceptInvitation': 'Accepter la demande',
  'meeting_scheduler.buttons.back': 'Retour',
  'meeting_scheduler.buttons.done': 'Terminé',
  'meeting_scheduler.buttons.next': 'Suivant',
  'meeting_scheduler.errors.invite_already_actioned': 'Cette demande a déjà été traitée. ',
  'meeting_scheduler.errors.invite_already_actioned_link': '<a href="#/contacts/{{ invite_counterpart_contact_id }}/conversation">Voir</a>',
  'meeting_scheduler.errors.no_available_slots': 'Pas de créneau disponible.',
  'meeting_scheduler.floorplanClickForFull': 'Plan en pleine page',
  'meeting_scheduler.meetingUpdatedTitle': 'Meeting mis à jour',
  'meeting_scheduler.rescheduleMeeting': 'Modifier le meeting',
  'meeting_scheduler.standLocation': '{{ companyName }} stand {{ standNumberFull }}',
  'meeting_scheduler.step.date': 'Date',
  'meeting_scheduler.step.location': 'Lieu',
  'meeting_scheduler.step.time': 'Heure',
  'meeting_scheduler.unavailable': 'Indisponible',
  'meeting_scheduler.recommended_heading': 'Voici l’<strong>horaire et lieu recommandés</strong> pour la réunion selon les disponibilités :',
  'meeting_scheduler.buttons.choose_my_own': 'Choisir moi-même',
  'meeting_scheduler.buttons.accept_recommended': 'Accepter',
  'multi_links.add_link': 'Ajouter un lien',
  'multi_links.address': 'ex. https://www.{address}.com/...',
  'multi_links.error': "N'oubliez pas d'entrer le type et l'url",
  'multi_links.please_select': 'Veuillez sélectionner',
  'multi_links.title': 'Liens',
  'multi_links.type': 'Type',
  'mux_video_upload.buttons.cancel': 'Annuler',
  'mux_video_upload.buttons.delete': 'Effacer',
  'mux_video_upload.buttons.rename': 'Renommer',
  'mux_video_upload.buttons.save': 'Sauvegarder',
  'mux_video_upload.buttons.upload': 'Upload',
  'mux_video_upload.error_text': 'Il y a eu une erreur de téléchargement, veuillez recommencer',
  'mux_video_upload.input.label': 'Titre',
  'mux_video_upload.success_text': 'Votre fichier a été téléchargé, il sera prêt très bientôt',
  'mux_video_upload.video_status.created': 'Nouveau',
  'mux_video_upload.video_status.deleted': 'Supprimé',
  'mux_video_upload.video_status.errored': 'Erroné',
  'mux_video_upload.video_status.new_video': 'Choisir un fichier et télécharger',
  'mux_video_upload.video_status.processing': 'En cours',
  'mux_video_upload.video_status.ready': 'Prêt',
  'mux_video_upload.video_status.removed': 'Effacé',
  'mux_video_upload.video_status.upload-cancelled': 'Téléchargement annulé',
  'mux_video_upload.video_status.upload-failed': 'Téléchargement échoué',
  'mux_video_upload.video_status.upload_failed': 'Téléchargement interrompu, veuillez effacer et recommencer',
  'mux_video_upload.video_status.uploading': 'Téléchargement, veuillez laisser cette fenêtre ouverte ou votre téléchargement peut être interrompu',
  'mux_video_upload.video_status.waiting': 'En attente',
  'my_schedule.bookings.source_types.auto_accepted': 'Accepté automatiquement',
  'my_schedule.bookings.source_types.ez_match': 'Ez Match',
  'my_schedule.bookings.source_types.concierge_booked': 'Concierge',
  'my_schedule.bookings.source_types.walk_up': 'Demande confimée sur place',
  'my_schedule.bookings.table_number': '(table {{ number }})',
  'my_schedule.other_bookings.buttons.add': 'Ajouter',
  'my_schedule.other_bookings.buttons.addOther': 'Autre',
  'my_schedule.other_bookings.buttons.cancel': 'Annuler',
  'my_schedule.other_bookings.buttons.delete': 'Effacer',
  'my_schedule.other_bookings.buttons.no_undos': 'Cette action est irréversible.',
  'my_schedule.other_bookings.buttons.update': 'Mise à jour',
  'my_schedule.other_bookings.fields.description.label': 'Détails',
  'my_schedule.other_bookings.fields.description.placeholder': 'Déjeuner avec un fournisseur',
  'my_schedule.other_bookings.fields.end_datetime.label': 'Fin',
  'my_schedule.other_bookings.fields.start_datetime.label': 'Début',
  'my_schedule.other_bookings.header_add': 'Ajouter',
  'my_schedule.other_bookings.header_update': 'Mise à jour',
  'my_schedule.other_bookings.sub_text': "Vous serez affiché comme 'indisponible' pendant cette période de temps.",
  'needHelp.body': 'Contactez l\'équipe de conciergerie.',
  'needHelp.dataPrivacy.body': 'Les informations collectées sont soumises aux conditions d\'utilisation et à la politique de confidentialité des données.',
  'needHelp.dataPrivacy.header': 'Protection des données',
  'needHelp.dataPrivacy.link': 'Politique de confidentialité',
  'needHelp.header': 'Besoin d’aide?',
  'needHelp.link': 'Contactez-nous',
  'profile.account_settings.email': 'E-mail',
  'profile.account_settings.first_name': 'Prénom',
  'profile.account_settings.last_name': 'Nom',
  'profile.account_settings.salutation': 'Titre',
  'profile.account_settings.title': 'Paramètres du compte',
  'profile.buttons.cancel': 'Annuler',
  'profile.buttons.edit': 'Modifier',
  'profile.buttons.save': 'Sauvegarder',
  'profile.change_password.change_password': 'Modifier le mot de passe',
  'profile.change_password.confirm_password': 'Confirmer le mot de passe',
  'profile.change_password.current_password': 'Mot de passe actuel',
  'profile.change_password.new_password': 'Nouveau mot de passe',
  'profile.change_password.title': 'Modifier le mot de passe',
  'profile.communication_preferences.email': 'E-mails de notification et de rappel',
  'profile.communication_preferences.phone': 'Assistance téléphonique',
  'profile.communication_preferences.sms': 'SMS de notification et de rappel',
  'profile.communication_preferences.something_went_wrong': 'Oops - Il y a eu un problème. Veuillez essayer à nouveau un peu plus tard',
  'profile.communication_preferences.subheader': 'Gérez vos préférences pour votre planning de rendez-vous',
  'profile.communication_preferences.title': 'Préférences de communication',
  'profile.contact_details.add_another_number': 'Ajouter un autre numéro',
  'profile.contact_details.add_number': 'Ajouter un numéro de téléphone',
  'profile.contact_details.input_label': '{{ phone_type }} numéro',
  'profile.contact_details.mobile': 'Numéro de téléphone portable',
  'profile.contact_details.mobile_number': 'Numéro de téléphone portable',
  'profile.contact_details.mobile_placeholder': 'Entrez un numéro de téléphone',
  'profile.contact_details.office': 'Numéro de bureau',
  'profile.contact_details.office_number': 'Numéro de bureau',
  'profile.contact_details.phone_number': 'Numéro de téléphone',
  'profile.contact_details.phone_number_missing': "Vous n'avez pas de numéro de téléphone.",
  'profile.contact_details.phone_placeholder': 'Entrez un numéro de téléphone',
  'profile.contact_details.please_add_a_number': 'Veuillez ajouter un numéro de téléphone',
  'profile.contact_details.select_country_code': 'Séléctionner le code de pays',
  'profile.contact_details.title': 'Coordonnées',
  'profile.demographics.subheader': 'Complétez ces informations pour aider les autres participants à vous trouver dans leurs recherches',
  'profile.demographics.title': 'Recherche',
  'profile.event_features.something_went_wrong': 'Veuillez rafraîchir la page et essayer de nouveau',
  'profile.event_features.subheader': 'Fonctions spéciales.',
  'profile.event_features.title': 'Fonctions',
  'profile.event_features.type_state_auto_accept_meeting_invite_false': "Pas d'acceptation automatique de mes demandes de rendez-vous.",
  'profile.event_features.type_state_auto_accept_meeting_invite_true': 'Acceptation automatique de TOUTES mes demandes de rendez-vous reçues.',
  'profile.forms.catalogueEntry.create.modalTitle': ' Ajouter un nouveau {{ name, lowercase }}',
  'profile.forms.catalogueEntry.edit.modalTitle': 'Modifier {{ name, lowercase }}',
  'profile.forms.image.create.heading': 'Ajouter une nouvelle image dans votre catalogue.',
  'profile.forms.image.create.modalTitle': 'Ajouter une image',
  'profile.forms.image.edit.heading': 'Changer le titre de votre image',
  'profile.forms.image.edit.modalTitle': 'Modifier l’image',
  'profile.forms.image.progressMessage': 'Votre image est en cours de téléchargement, patientez',
  'profile.forms.video.create.modalTitle': 'Télécharger une vidéo',
  'profile.forms.video.edit.modalTitle': 'Modifier la vidéo',
  'profile.forms.video.progressMessage': 'Votre vidéo est en cours de téléchargement, patientez',
  'profile.groups.aboutMe.heading': 'À propos de moi',
  'profile.groups.accountAccess.heading': 'Accès au compte',
  'profile.groups.accountAccess.subheading': 'Cet utilisateur peut gérer votre compte, vos discussions et vos rendez-vous.',
  'profile.groups.catalogue.heading': '{{ plural }}',
  'profile.groups.companyDemographics.heading': 'Autres informations',
  'profile.groups.companyInformation.heading': 'Information sur l’entreprise',
  'profile.groups.companyInformation.subheading': 'Gérer le profil de votre entreprise',
  'profile.groups.contactInformation.heading': 'Coordonnées',
  'profile.groups.eventOptouts.heading': 'Se retirer de l’évènement',
  'profile.groups.password.heading': 'Mot de passe',
  'profile.groups.password.lastChanged': 'Dernier changement <b>{{ lastChangedAt }}</b>',
  'profile.groups.password.subheading': 'Votre mot de passe doit comporter au moins 8 caractères, dont une lettre majuscule, un chiffre et un caractère spécial.',
  'profile.groups.searchInformation.heading': 'Informations complémentaires',
  'profile.groups.team.heading': 'Membres de l’équipe <small>({{ count }})</small>',
  'profile.groups.timezone.heading': 'Fuseau horaire',
  'profile.groups.timezone.subheading': 'Visualisez les dates et heures des événements dans votre fuseau horaire préféré. (Evénements virtuels/en ligne uniquement)',
  'profile.informationSharingNotification': 'Vos coordonnées ne sont <b>pas</b> affichées sur votre profil.',
  'profile.location_language.country': 'Pays',
  'profile.location_language.timezone': 'Fuseau horaire',
  'profile.location_language.title': 'Lieu',
  'profile.modals.previewCompanyCard.modalTitle': 'Prévisualisez votre carte de visite',
  'profile.modals.previewContactCard.': 'Aperçu de votre carte',
  'profile.modals.previewContactCard.modalTitle': 'Aperçu de votre carte',
  'profile.optouts.something_went_wrong': 'Veuillez rafraîchir la page et essayer de nouveau',
  'profile.optouts.subheader': 'En désélectionnant ces options votre profil sera caché et toutes les demandes de rendez-vous refusées.',
  'profile.optouts.title': 'Préférences de visibilité',
  'profile.optouts.type_state_findable_false': 'Je ne suis PAS visible sur la plateforme.',
  'profile.optouts.type_state_findable_true': 'Je suis visible sur la plateforme.',
  'profile.optouts.type_state_meetable_false': 'Je ne suis PAS visible pour prendre des rendez-vous.',
  'profile.optouts.type_state_meetable_true': 'Je suis disponible pour prendre des rendez-vous.',
  'profile.pages.catalogue.header': 'Catalogue',
  'profile.pages.companyProfile.header': 'Profil de l’entreprise',
  'profile.pages.companyProfile.previewButton': 'Prévisualiser le profil de l\'entreprise',
  'profile.pages.manageContent.header': 'Gérer le contenu',
  'profile.pages.manageContent.warning': 'Les paramètres des événements peuvent limiter l\'affichage de certains contenus.',
  'profile.pages.myAccount.action': 'L’évènement est ouvert',
  'profile.pages.myAccount.eventAndBadges': 'Mes évènements & mes badges.',
  'profile.pages.myAccount.header': 'Accueil de mon compte',
  'profile.pages.myAccount.welcome': 'Bonjour {{ firstName }}, Bienvenue sur votre compte.',
  'profile.pages.notificationSettings.header': 'Paramètres de notification',
  'profile.pages.passwordSettings.header': 'Mot de passe & réglages ',
  'profile.pages.profileInformation.header': 'Mon profil',
  'profile.pages.profileInformation.previewButton': 'Prévisualiser mon profil',
  'profile.pages.profileInformation.myAccount': 'Mon compte',
  'profile.pages.profileInformation.switchProfile': 'Changer de profil',
  'profile.pages.team.header': 'Équipe',
  'profile.pages.data_and_privacy.header': 'Données & Confidentialité',
  'profile.pageTitle': 'Mon compte',
  'profile.profile_avatar.recommended_size': 'Recommandations: 250px. Taille de fichier maximum 5Mb.',
  'profile.profile_avatar.title': 'Photo de profil',
  'profile.profile_avatar.upload_photo': 'Télécharger une photo',
  'profile.profile_information.about_me': 'À propos de moi',
  'profile.profile_information.about_me_placeholder': "Donnez plus d'informations sur votre fonction et les raisons de votre participation au salon.",
  'profile.profile_information.job_title': 'Fonction',
  'profile.profile_information.title': 'Information de profil',
  'profile.reset_password.sent': 'Vous recevrez votre e-mail de réinitialisation de mot de passe trés vite',
  'profile.reset_password.title': 'Réinitialiser le mot de passe',
  'profile.rows.avatarModalTitle': 'Télécharger votre photo de profil',
  'profile.rows.bio.bioPlaceholder': 'Mon travail consiste à… ',
  'profile.rows.companyLocations.addModalTitle': ' Ajouter un lieu',
  'profile.rows.companyLocations.delete': 'Êtes-vous sûr de vouloir supprimer cet emplacement?',
  'profile.rows.companyLocations.updateModalTitle': 'Modifier cet emplacement',
  'profile.rows.companyWebsites.removeWarning': 'Êtes-vous sûr de vouloir supprimer ce site internet de votre profil?',
  'profile.rows.country.selectCountryPlaceholder': 'Où êtes-vous situé?',
  'profile.rows.fullNameModalTitle': ' Modifier le nom',
  'profile.rows.logo.logoModalTitle': ' Télécharger le logo de l\'entreprise',
  'profile.rows.lookingFor.inputLabel': 'Quels types de contacts, d\'entreprises, de produits ou de services vous intéressent?',
  'profile.rows.lookingFor.inputPlaceholder': 'Je recherche...',
  'profile.rows.lookingFor.label': 'Je recherche...',
  'profile.rows.lookingFor.modalTitle': ' Je recherche ...',
  'profile.rows.password.changeMessage': 'Envoyer un lien pour changer de mot de passe à:',
  'profile.rows.password.label': 'Mot de passe actuel',
  'profile.rows.password.modalTitle': 'Changer le mot de passe',
  'profile.rows.password.passwordLinkSent': 'Envoi du lien pour le mot de passe',
  'profile.rows.password.successMessage': '<h4> Email sent </h4><p>  Nous avons envoyé un lien pour réinitialisation le mot de passe à :<br/><a href="mailto:{{email}}"> {{ email }} </a></p><p> Votre lien expirera dans 1 heure.</p>',
  'profile.rows.phone.deleteWarning': 'Supprimer ce numéro de téléphone de votre profil?',
  'profile.rows.phone.info': 'Nous pouvons vous contacter au sujet des rendez-vous ou des informations importants de l’évènement par message (SMS) ou par téléphone. Votre numéro de téléphone n\'est pas affiché sur votre profil public.',
  'profile.rows.socials.updateModalTitle': 'Modifier les reseaux sociaux',
  'profile.rows.websites.addModalTitle': 'Ajouter un site internet',
  'profile.rows.websites.updateModalTitle': 'Modifier le site internet',
  'profile.rows.legal_notices.title': 'Mentions légales',
  'profile.rows.legal_notices.eventware': 'Eventware',
  'profile.rows.legal_notices.eventwarePrivacyNotice': 'Avis de confidentialité Eventware',
  'profile.rows.legal_notices.eventwareEndUserLicenceAgreement': 'Contrat de licence de l’utilisateur final Eventware',
  'profile.rows.legal_notices.learnMoreAbout': 'En savoir plus sur',
  'profile.rows.download_personal_data.title': 'Données personnelles',
  'profile.rows.download_personal_data.description': 'Téléchargez un fichier au format JSON contenant les informations que nous stockons à votre sujet, conformément à l\'article 20 du RGPD. Cette exportation inclut toutes les données identifiables vous concernant ou concernant votre organisation.',
  'profile.rows.download_personal_data.warning_prefix': 'Attention :',
  'profile.rows.download_personal_data.warning_description': 'Ce fichier contient des informations personnelles de compte et d\'utilisation. Gardez-le en sécurité et évitez de le télécharger sur un appareil public.',
  'profile.tables.catalogueEntry.confirmDelete': 'Êtes-vous sûr de vouloir supprimer ce  {{ name, lowercase }}?',
  'profile.tables.catalogueEntry.emptyMessage': 'Aucun élément à afficher.',
  'profile.tables.imageGallery.addOne': 'Ajouter',
  'profile.tables.imageGallery.confirmDelete': 'Êtes-vous sûr de vouloir supprimer cette image?',
  'profile.tables.imageGallery.count_one': '({{ count }} item)',
  'profile.tables.imageGallery.count_other': '({{ count }} items)',
  'profile.tables.imageGallery.emptyMessage': 'Aucun élément à afficher.',
  'profile.tables.imageGallery.title': 'Galerie',
  'profile.tables.videos.addOne': 'Ajouter',
  'profile.tables.videos.confirmDelete': 'Êtes-vous sûr de vouloir supprimer cette vidéo?',
  'profile.tables.videos.count': '({{ count }} / {{ limit }})',
  'profile.tables.videos.emptyMessage': 'Aucun élément à afficher.',
  'profile.tables.videos.title': 'Vidéos du profil',
  'profile.tips.edit.body': "Les informations utilisées pour vérifier votre identité et l'entreprise pour laquelle vous travaillez ne peuvent pas être modifiées. Néanmoins, vos coordonnées, vos préférences et certains autres détails peuvent être modifiés. Nous pouvons vous demander de vérifier votre identité avant de sauvegarder certaines modifications.",
  'profile.tips.edit.title': 'Quelles informations peuvent être modifiées?',
  'profile.tips.preview.title': 'Aperçu de la carte',
  'profile.tips.share.body': "Nous ne partagerons vos coordonnées qu'avec les participants de vos rendez-vous confirmés.",
  'profile.tips.share.title': 'Quelles sont les informations qui seront partagées?',
  'profile.action.logout': 'Déconnexion',
  'profile_select.footer.contact_support': "Contacter l'équipe d'assistance",
  'profile_select.footer.multiple_profiles': 'Pourquoi ai-je plusieurs profils?',
  'profile_select.footer.return_to_list': 'Retour à la liste de profils',
  'profile_select.header.info': 'Cliquez les options de profil pour <b>connecter, regrouper ou attribuer</b> à un collègue.',
  'profile_select.header.subheader': "Il y a plus d'un profil associé avec",
  'profile_select.header.title': 'Changer de profil',
  'profile_select.info.duplicate_profiles.description': 'Vous vous êtes peut-être inscrit plusieurs fois.',
  'profile_select.info.duplicate_profiles.solution_text': " Sélectionnez 'regrouper' à partir de menu d'options sur la page de profil et suivez les instructions afin de combiner les données sous un seul profil. Vérifiez quel profil vous souhaitez garder.",
  'profile_select.info.duplicate_profiles.title': 'Il y a plusieurs profils sous votre nom.',
  'profile_select.info.i_see_colleagues.description': 'Votre entreprise a peut-être plusieurs participants avec la même adresse e-mail, e.g. info@.',
  'profile_select.info.i_see_colleagues.solution_text': "Selectionnez le lien de votre collègue à partir du menu d'options sur le profil.",
  'profile_select.info.i_see_colleagues.title': 'Je vois les noms de mes collégues de la même entreprise.',
  'profile_select.info.listed_different_company.description': "Vous êtes peut-être inscrit sous plusiers nom d'entreprises ou représentant de plusieurs entreprises. Dans ce cas, vous pouvez garder vos profils et changer de profil en utilisant cette fenêtre de selection de profil.  Dans ce cas:",
  'profile_select.info.listed_different_company.solution_text': "Séléctionner 'Effacer' à partir du menu d'options sur le profil. Ce procédé est irreversible, connectez-vous et vérifiez vos profil avant de les effacer.",
  'profile_select.info.listed_different_company.title': 'Je suis inscrit sur plusieurs entreprises.',
  'profile_select.info.solution': 'Solution:',
  'profile_select.info.title': "Pourquoi est-ce j'ai plusieurs profils?",
  'profile_select.profile.last_used': 'Dernière utilisation:',
  'profile_select.profile.never_logged_in': 'Jamais',
  'profile_select.primary_contact': 'Premier contact',
  'ui.add': 'Ajouter',
  'ui.delete': 'Supprimer',
  'ui.edit': 'Modifier',
  'ui.faq': 'Foire aux questions',
  'ui.help': 'Help',
  'ui.language': 'Langue',
  'ui.locked': 'Verrouillé',
  'ui.new': 'Nouveau',
  'ui.noItemsToDisplay': 'Aucun élément à afficher.',
  'ui.notAdded': 'Non ajouté',
  'ui.notSet': 'Non défini ',
  'ui.selectCountry': 'Sélectionner un pays',
  'ui.support': 'Soutien',
  'ui.switchProfile': 'Changer de profil',
  'update_availability.buttons.cancel': 'Annuler',
  'update_availability.buttons.update': 'Modifiez votre disponibilité',
  'update_availability.header': 'Votre disponibilité',
  'update_availability.key.available': 'Disponible',
  'update_availability.key.unavailable': 'Indisponible',
  'update_availability.subheader': 'Déterminez les jours et heures de votre participation au salon.',
  'video_meeting.message_concierge.box_closing': 'Cette fenêtre se fermera automatiquement dans 5 seconds.',
  'video_meeting.message_concierge.buttons.cancel': 'Annuler',
  'video_meeting.message_concierge.buttons.message_concierge': "Envoyer un message à l'équipe d'assistance",
  'video_meeting.message_concierge.buttons.send': 'Envoyer',
  'video_meeting.message_concierge.connecting': 'connecting',
  'video_meeting.message_concierge.header': "Demander l'aide de l'équipe d'assistance",
  'video_meeting.message_concierge.last_seen': '(Last seen {{ last_seen }})',
  'video_meeting.message_concierge.message_sent': 'Votre message a été envoyé à notre équipe, gardez un oeil sur la fenêtre de chat pour une réponse.',
  'video_meeting.message_concierge.online': 'online',
  'video_meeting.message_concierge.waiting_join': 'Waiting for user to join',
  chat_visible: 'Chat visible pour tous les participants',

  // hubs general page
  'hubs.header.stand': 'Stand: {{ stand }}',
  'hubs.companyProfile': 'profil de l\'entreprise',
  'hubs.definitions.confirmedMeetings': 'Réunions confirmées',
  'hubs.definitions.receivedPendingInvites': 'Invitations reçues en attente',
  'hubs.definitions.sentPendingInvites': 'Invitations envoyées en attente',
  'hubs.definitions.sentInvites': 'Invitations envoyées (tous les statuts)',

  // hubs event profile
  'hubs.eventProfile.link': 'Profil de l\'événement',
  'hubs.eventProfile.activity.favourited': 'Favorisé',
  'hubs.eventProfile.activity.hides': 'Cacher',
  'hubs.eventProfile.activity.marketplacePerformance': 'Performance',
  'hubs.eventProfile.activity.marketplacePerformanceScore': '{{ score }}% maximal',
  'hubs.eventProfile.activity.marketplaceViews': 'Vues',
  'hubs.eventProfile.activity.performance': 'Performance du profil',
  'hubs.eventProfile.activity.profileViews': 'Vues du profil',
  'hubs.eventProfile.catalogueActive': 'Entrées actives',
  'hubs.eventProfile.catalogueDescription': 'Utilisez les répertoires du catalogue pour promouvoir vos produits et services.',
  'hubs.eventProfile.catalogueTitle': 'Entrées du catalogue',
  'hubs.eventProfile.countPlays': '{{ count }} lectures',
  'hubs.eventProfile.description': 'Gérez votre profil et promouvez votre présence à l\'événement.',
  'hubs.eventProfile.emptyList': 'Aucun élément répertorié. Gérez les éléments dans votre <a href="#/my-account/company-profile">profil d\'entreprise <i class="fa "></i></a>',
  'hubs.eventProfile.marketplaceActive': 'active',
  'hubs.eventProfile.marketplaceDescription': 'Promouvez vos produits et services à travers les répertoires du marché.',
  'hubs.eventProfile.marketplaceTitle': 'Place de marché',
  'hubs.eventProfile.performanceScore': 'Top {{ score }}%',
  'hubs.eventProfile.title': 'Profil de l\'événement',
  'hubs.eventProfile.videos': 'Vidéos',
  'hubs.eventProfile.videosActive': 'Vidéos actives',
  'hubs.eventProfile.videosDescription': 'Gérez vos vidéos promotionnelles.',
  'hubs.eventProfile.videosTitle': 'Vidéos',
  'hubs.eventProfile.viewsPerDay': 'Vues du profil par jour',

  // hubs insights
  'hubs.insights.activity': 'Performance du profil de l\'entreprise',
  'hubs.insights.activity.favourited': 'Ajouté aux favoris',
  'hubs.insights.activity.hides': 'Ajouté aux peaux',
  'hubs.insights.activity.profilePreviews': 'Aperçus du profil',
  'hubs.insights.activity.profileViews': 'Vues de la page du profil',
  'hubs.insights.activity.noData': 'pas assez de données à afficher',
  'hubs.insights.description': 'Examinez votre performance de l’evenement.',
  'hubs.insights.meetings': 'Performance des réunions de l\'entreprise',
  'hubs.insights.link': 'Perspectives',
  'hubs.insights.title': 'Perspectives',

  // hubs leads
  'hubs.leads.businessCardDropTable': 'Cartes de visite déposées',
  'hubs.leads.businessCardDropTableDescription': 'Les personnes suivantes ont déposé leurs cartes de visite sur votre profil d\'entreprise pour un suivi.',
  'hubs.leads.description': 'Accédez à vos prospects et cartes de visite déposées.',
  'hubs.leads.download': 'Télécharger',
  'hubs.leads.downloadFormat': 'CSV / Excel',
  'hubs.leads.link': 'Cartes de visite',
  'hubs.leads.meetingsWith': 'Réunions avec {{ people }}',
  'hubs.leads.noDroppedBusinessCards': 'Pas de carte de visite déposée',
  'hubs.leads.numberOfBusinessCardDrops': 'Cartes de visite déposées',
  'hubs.leads.receivedInvitesFrom': 'Invitations reçues de {{ people }}',
  'hubs.leads.sentInvitesTo': 'Invitations envoyées à {{ people }}',
  'hubs.leads.title': 'Cartes de visite',

  // purchase items
  'hubs.purchase_items.link': 'Mises à jour et offres',
  'hubs.purchase_items.purchases': 'Achats',
  'hubs.purchase_items.available': 'Disponible',
  'hubs.purchase_items.someAvailable': '{{ num }} disponibles',
  'hubs.purchase_items.purchased': 'Acheté',
  'hubs.purchase_items.out_of_stock': 'En rupture de stock',
  'hubs.purchase_items.offers': 'Offres',
  'hubs.purchase_items.emptyList': 'Aucun article à acheter disponible.',
  'hubs.purchase_items.product_not_available': 'Produit non disponible',
  'hubs.purchases.purchased_at': 'Acheté à:',
  'hubs.purchases.purchased_by': 'Acheté par: {{ contact }}',

  // hubs meetings
  'hubs.meetings.confirmedMeetings': 'Rendez-vous',
  'hubs.meetings.description': 'Gérez l’activité des rendez-vous de toute l’entreprise.',
  'hubs.meetings.link': 'Rendez-vous',
  'hubs.meetings.MeetingsByDayTitle': 'Réunions par jour',
  'hubs.meetings.MeetingsByLocationTitle': 'Réunions par lieu',
  'hubs.meetings.performance': 'Performance',
  'hubs.meetings.performanceScore': 'Top {{score}}%',
  'hubs.meetings.receivedInvites': 'Invitations en attente',
  'hubs.meetings.sentInvites': 'Invitations envoyées',
  'hubs.meetings.teamHasMeetings': 'A des réunions',
  'hubs.meetings.teamHasPending': 'A en attente',
  'hubs.meetings.teamHasSent': 'A envoyé',
  'hubs.meetings.teamTitle': 'Activité de réunion',
  'hubs.meetings.title': 'Rendez-vous',
  'hubs.stats.average': 'Moyen',
  'hubs.stats.belowAverage': 'Faible',

  // hubs meetings team schedules
  'hubs.meetings.teamSchedules.unavailable': 'Réservé comme indisponible',
  'hubs.meetings.teamSchedules': 'Horaires combinés de l\'équipe',
  'hubs.meetings.teamSchedules.instructions': 'Sélectionnez un jour pour voir l\'emploi du temps d\'un contact pour une période donnée.',
  'hubs.meetings.teamSchedules.bookings.empty': '{{sharer}} n\'a aucune réservation pendant cette période.',
  'hubs.meetings.teamSchedules.combinedBookings.empty': 'Aucune réservation pendant cette période.',

  // hubs team
  'hubs.team.activityChartSubtitle': '60 derniers jours',
  'hubs.team.activityChartTitle': 'Activité de l\'équipe - ',
  'hubs.team.badgeTable': ' ',
  'hubs.team.contactUpdatedPlatformProfile': 'Profils mis à jour',
  'hubs.team.description': 'Gérez l’activité et la présence de l’équipe.',
  'hubs.team.lastLogin': 'dernière activité',
  'hubs.team.link': 'Équipe',
  'hubs.team.noOtherBadges': 'Aucun de vos collègues n\'est inscrit à l\'événement.',
  'hubs.team.profilesActivated': 'Profils activés',
  'hubs.team.profilesHidden': 'Profils cachés',
  'hubs.team.title': 'Équipe',
  'hubs.team.register_colleague': 'Enregistrer un collègue',
  'hubs.team.register': "S'inscrire",
  'hubs.team.country': 'Pays',
  'hubs.team.email': 'E-mail',
  'hubs.team.firstName': 'Prénom',
  'hubs.team.jobTitle': 'Titre du poste',
  'hubs.team.lastName': 'Nom de famille',

  'meeting_invite.notification.confirmed_meeting': 'Vous avez une <a href="{{hash}}">réunion confirmée</a> avec ce contact.',
  'meeting_invite.notification.invite_already_sent': '<a href="{{hash}}">Invitation déjà envoyée</a> à ce contact.',
  'meeting_invite.notification.invite_already_received': 'Vous avez une <a href="{{hash}}">nouvelle invitation à une réunion</a> de ce contact.',
  'meeting_invite.notification.invite_withdrew_by_ac': 'Invitation retirée le {{withdrawn_time}}. <a href="{{hash}}">Voir les détails</a>.',
  'meeting_invite.notification.meeting_cancelled_by': 'Une réunion précédente a été annulée.<a href="{{hash}}">Voir la réunion</a>.',
  'meeting_invite.notification.meeting_with_contact_colleague': 'Vous avez une <a href="{{hash}}">réunion confirmée</a> avec cette entreprise.',
  'meeting_invite.notification.meeting_with_contact_declined': 'Votre <a href="{{hash}}">invitation à la réunion</a> a été refusée.',
  'meeting_invite.notification.meeting_with_contact_declined_by_you': 'Vous avez refusé une <a href="{{hash}}">invitation à une réunion</a> de ce contact.',
  'meeting_invite.notification.colleague_meeting_intersect': 'Votre collègue rencontre cette entreprise.',
  'meeting_invite.notification.meeting_with_colleague_confirmed': 'Votre collègue a une réunion avec ce contact.',
  'meeting.notification.view_meeting': 'Voir le rendez-vous',

  // Registrations
  'registrations.form.questions.component.name.first_name': 'Prénom',
  'registrations.form.questions.component.name.last_name': 'Nom de famille',
  'registrations.form.questions.component.name.first_name_placeholder': 'entrez votre prénom',
  'registrations.form.questions.component.name.last_name_placeholder': 'Entrer le nom de famille',
  'registrations.form.actions.submit': 'Soumettre',
  'registrations.form.actions.next': 'Suivant',
  'registrations.form.screens.thankyou.heading': '<strong>Congratulations {{ name }}</strong>, your {{ eventName }} registration is complete.',
  'registrations.form.screens.thankyou.sub_heading': 'You will receive a confirmation email shortly.',
  'registrations.form.screens.not_active.description': "Ce formulaire d'inscription est actuellement indisponible, veuillez réessayer plus tard.",
  'registrations.form.screens.closed.alert_message': 'Les inscriptions sont closes.',
  'registrations.form.screens.coming_soon.alert_message': 'Les inscriptions ouvrent bientôt.',
  'registrations.form.actions.send_login_link': 'Envie um link de login',
  'registrations.form.actions.change_email_address': 'Mude o endereço de email',
  'registrations.form.actions.send_link_to_continue': 'Envie um link para continuar',
  'registrations.form.validation.email_already_registered_message': 'Seu endereço de e-mail já está cadastrado...',
  'registrations.form.validation.email_already_have_partial_registration_message': 'Seu endereço de e-mail possui um registro parcial...',
  'registrations.form.buttons.login': 'Log in',
  'registrations.form.actions.resend_email': 'Resend email',
  'registrations.form.actions.resend_email.message': 'Email sent!',

  // Videos
  'video_card.more': 'Plus de détails',
  'video_card.view_detail': 'Détail de la vidéo',
  'videoModal.buttons.view_company': "Voir l'entreprise",

  // Hub Tasks
  'hubs.tasks.link': 'Tâches',
  'hubs.tasks.title': 'Tâches',
  'hubs.tasks.taskProgress': 'Progression de la tâche',
  'hubs.tasks.pendingTasksTitle': 'Tâches en attente',
  'hubs.tasks.pendingCompanyTasksDescription': 'Les tâches suivantes doivent être accomplies par un représentant de votre entreprise.',
  'hubs.tasks.pendingContactTasksDescription': 'Les tâches suivantes doivent être accomplies par vous.',
  'hubs.tasks.noPendingTasks': 'Il n\'y a pas de tâches à compléter.',
  'hubs.tasks.taskType.mandatory': 'Requis',
  'hubs.tasks.taskType.important': 'Important',
  'hubs.tasks.taskType.optional': 'Optionnel',
  'hubs.tasks.completedTasksTitle': 'Tâches complétées',
  'hubs.tasks.completedTasksDescription': 'Les tâches suivantes ont été complétées.',
  'hubs.tasks.nocompletedTasks': 'Vous n\'avez aucune tâche complétée.',
  'hubs.tasks.start': 'Commencer',
  'hubs.tasks.expired': 'Expiré',
  'hubs.tasks.view': 'Voir',
  'hubs.tasks.modal.title': 'Détails de la tâche',
  'hubs.tasks.modal.cancel': 'Annuler',
  'hubs.tasks.modal.markAsRead': 'Marquer comme lu',
  'hubs.tasks.modal.submit': 'Soumettre',
  'hubs.tasks.modal.close': 'Fermer',
  'hubs.tasks.completed_by': 'Complété par {{ responder }} le {{ when }}',
  'hubs.tasks.file_submitted': 'Vous avez déjà téléchargé un fichier',
  'hubs.tasks.file_download': 'Télécharger le fichier',
  'hubs.tasks.user_file_download': 'Télécharger le fichier',
  'hubs.tasks.capacity_remaining': '{{ capacity_remaining }} restant(s)',
  'hubs.tasks.capacity_remaining_zero': 'Rupture de stock',
  'hubs.tasks.deadline': 'Date limite',
  'hubs.tasks.completed': 'Terminé',

  'eventmap.open_location_on_map': 'Ouvrir la carte',

  // Networking
  'networking.match_score.explanation_modal.title': 'Explication du score de correspondance',
  'networking.match_score.explanation_modal.paragraph_1_title': "Qu'est-ce qu'un score de correspondance?",
  'networking.match_score.explanation_modal.paragraph_1_description': 'Votre score de correspondance reflète la probabilité que vous partagiez des intérêts et des objectifs avec un autre utilisateur. Plus le score est élevé, plus la connexion est pertinente.',
  'networking.match_score.explanation_modal.paragraph_2_title': "Pourquoi certains profils n'ont-ils pas de score de correspondance?",
  'networking.match_score.explanation_modal.paragraph_2_description': "Nous n'avons peut-être pas encore suffisamment d'informations pour calculer un résultat précis. Les scores de correspondance sont mis à jour en temps réel à mesure que les utilisateurs interagissent avec l'événement.",
  'networking.match_score.explanation_modal.paragraph_3_title': 'Comment mon score de correspondance est-il calculé?',
  'networking.match_score.explanation_modal.paragraph_3_description': "Les scores de correspondance sont basés sur les détails du profil, les tendances d'activité et les similarités entre utilisateurs. Plus votre profil est complet, plus vos correspondances seront précises.",
  'networking.match_score.explanation_modal.paragraph_4_title': 'Comment puis-je améliorer mes correspondances?',
  'networking.match_score.explanation_modal.paragraph_4_description': 'Si vos correspondances sont inexactes, visitez Mon Compte pour vérifier les informations de votre profil. Garder votre profil à jour nous aide à proposer de meilleurs contenus et connexions.',
  'networking.match_score.explanation_modal.paragraph_5_title': 'Quel score les autres voient-ils sur mon profil?',
  'networking.match_score.explanation_modal.paragraph_5_description': 'Chaque score de correspondance est unique pour le spectateur et se met à jour dynamiquement au fur et à mesure que les profils évoluent. Cela garantit que vos connexions restent aussi pertinentes que possible.',

  'purchase_upgrade.agree_terms_link': 'termes et conditions',
  'purchase_upgrade.agree_terms_post': '.',
  'purchase_upgrade.agree_terms_pre': 'J\'accepte les',
  'purchase_upgrade.buttons.agree': 'J\'accepte',
  'purchase_upgrade.buttons.back': 'Retour',
  'purchase_upgrade.buttons.buy_now': 'Acheter maintenant',
  'purchase_upgrade.buttons.cancel': 'Annuler',
  'purchase_upgrade.buttons.finish': 'Terminer',
  'purchase_upgrade.buttons.pay_now': 'Payer maintenant',
  'purchase_upgrade.buttons.print_receipt': 'Imprimer le reçu',
  'purchase_upgrade.buttons.try_again': 'Réessayer',
  'purchase_upgrade.checkout_heading': 'Caisse',
  'purchase_upgrade.error_heading': 'Erreur',
  'purchase_upgrade.payment_complete.heading': 'Paiement terminé',
  'purchase_upgrade.payment_complete.message': 'Votre commande a été traitée et appliquée au compte.',
  'purchase_upgrade.terms.subtitle': '',
  'purchase_upgrade.terms.title': 'Termes et conditions',

};
