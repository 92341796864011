import { defineStore } from 'pinia';
import axios, { AxiosError } from 'axios';

const useMyAccountStore = defineStore('my-account', {
  id: 'my-account',
  state: () => ({
    sections: {
      'profile-information': true,
      'company-profile': true,
      'password-settings': true,
      'notification-settings': true,
      'data-privacy': true,
      catalogue: true,
      'manage-content': true,
      team: true,
      purchases: true,
    },
  }),
  getters: {
    sectionIsCompleted: (state) => (code) => state.sections[code],
  },
  actions: {
    async loadSectionStatus() {
      try {
        const response = await axios.get('/my-account/section-status');
        this.sections = response.data.data;
      } catch (error) {
        if (!(error instanceof AxiosError)) {
          throw error;
        }
      }
    },
  },
});

export default useMyAccountStore;
