<template>
  <h2 class="registration--title">{{ heading }}</h2>
  <p class="registration--description">
    {{ registrationStore.currentQuestion.answer }}
  </p>
  <div class="registration-actions"
    >
    <FrontendRadioInput
      v-model="option"
      :options="options"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapStores } from 'pinia';
import FrontendRadioInput from '~/Components/Frontend/Inputs/FrontendRadioInput.vue';
import useRegistrationStore from '~/Store/Registration';

export default {
  name: 'RegistrationSendLink',
  components: {
    FrontendRadioInput,
  },
  data() {
    return {
      actions: ['send_login_link', 'send_incomplete_registration_link'],
      option: '',
    };
  },
  computed: {
    // Store will be accessible as its id + 'Store'
    ...mapStores(useRegistrationStore),
    options() {
      if (this.registrationStore.currentQuestion.errors?.user_email) {
        return [
          {
            value: 'send_login_link',
            text: this.__('registrations.form.actions.send_login_link'),
          },
          {
            value: 'change_email_address',
            text: this.__('registrations.form.actions.change_email_address'),
          },
        ];
      }
      return [
        {
          value: 'send_incomplete_registration_link',
          text: this.__('registrations.form.actions.send_link_to_continue'),
        },
        {
          value: 'change_email_address',
          text: this.__('registrations.form.actions.change_email_address'),
        },
      ];
    },
    heading() {
      if (this.registrationStore.currentQuestion.errors?.user_email) {
        return this.__('registrations.form.validation.email_already_registered_message');
      }
      return this.__('registrations.form.validation.email_already_have_partial_registration_message');
    },
  },
  watch: {
    option(newValue) {
      const { currentQuestion } = this.registrationStore;
      const currentIndex = this.registrationStore.questions.findIndex((question) => question.id === currentQuestion.id);
      if (newValue === 'change_email_address') {
        this.registrationStore.clearQuestionFieldProperties(currentIndex);
      } else if (_.includes(this.actions, newValue)) {
        this.registrationStore.updateQuestionField(
          currentIndex,
          'action',
          newValue,
        );
      }
    },
  },
};
</script>
