<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('profile.rows.download_personal_data.title') }}
    </div>
    <div class="profile-row__value">
      <p class="text-small text-gray-dark">{{ __('profile.rows.download_personal_data.description') }}</p>
      <a :href="downloadLink" class="button button--secondary mt-2">
        <i class="fa fa-download"></i>
        {{ __('buttons.download') }}
      </a>
      <p class="text-small mt-4"><strong>{{ __('profile.rows.download_personal_data.warning_prefix') }}</strong> {{ __('profile.rows.download_personal_data.warning_description') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    downloadLink: {
      type: String,
      required: true,
    },
  },
};
</script>
