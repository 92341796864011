<template>
  <div
    v-if="registrationStore.currentQuestion.answer?.length > 0"
    class="registration-selected-items-counter">
    {{ registrationStore.currentQuestion.answer?.length }} selected
  </div>
  <MultilevelDropdown
    v-model="registrationStore.currentQuestion.answer"
    :options="registrationStore.currentQuestion.options"
    :isMulti="true"
    :errors="registrationStore.currentQuestion.error"
  />
</template>

<script>
import { mapStores } from 'pinia';
import MultilevelDropdown from '~/Components/Common/MultilevelDropdown.vue';
import useRegistrationStore from '~/Store/Registration';

export default {
  type: 'lazy',
  name: 'RegistrationDemographicTreeViewComponent',
  components: {
    MultilevelDropdown,
  },
  computed: {
    // Store will be accessible as its id + 'Store'
    ...mapStores(useRegistrationStore),
  },
};
</script>
