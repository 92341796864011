import { defineStore } from 'pinia';
import axios from 'axios';
import _ from 'lodash';
import StringReplace from '~/Utilities/StringReplace';

const useRegistrationStore = defineStore('registration', {
  id: 'registration',
  state: () => ({
    questions: [],
    countries: [],
    currentQuestionId: null,
    loading: false,
    errorMessage: null,
    alert: {},
    errors: {},
    progress: 0,
    placement: {},
    personalization: {},
    timeout: null,
    currentAction: null,
    registration: {},
  }),
  getters: {
    currentQuestion: (state) => state.questions.find((q) => q.id === state.currentQuestionId),
    currentQuestionIndex: (state) => state.questions.findIndex((q) => q.id === state.currentQuestionId),
    isLastQuestion: (state) => state.questions.length === state.currentQuestionIndex + 1,
    currentQuestionTitle: (state) => StringReplace(state.currentQuestion?.title, state.personalization),
  },
  actions: {
    resetState() {
      this.$reset(); // Resets the state to the initial state
    },
    async updateQuestions(questions, questionId) {
      this.questions = questions.map((question) => ({
        ...this.formatQuestion(question),
        options: question.options?.length ? this.sortQuestionOptions(question.options, question.sort_by) : question.options,
      }));
      this.navigateToQuestion(questionId, 'load');
    },
    formatQuestion(question) {
      const formattedQuestion = { ...question };
      if (question.question_type === 'name') {
        formattedQuestion.answer = {
          first_name: question?.answer?.first_name || '',
          last_name: question?.answer?.last_name || '',
        };
      } else if (question.question_type === 'demographic-tree-view') {
        formattedQuestion.answer = question?.answer || [];
      }
      return formattedQuestion;
    },
    updateQuestionField(index, field, value) {
      const question = this.questions[index];
      if (field === 'error') {
        question.error = value[this.currentQuestion.question_type];
        question.errors = value;
      } else {
        question[field] = value;
      }
    },
    clearQuestionFieldProperties(index) {
      const question = this.questions[index];
      question.error = [];
      question.errors = [];
      question.action = '';
    },
    updateProgress() {
      const index = this.currentQuestionIndex;
      this.progress = Math.floor((index / this.questions.length) * 100);
    },
    updatePersonalization(personalization) {
      this.personalization = personalization;
    },
    isNumber(value) {
      return typeof value === 'number' && !Number.isNaN(value);
    },
    navigateToQuestion(questionId, actionToTake) {
      /* Incase if someone comes back to complete their registration from a specific
        question to onward, so we have to validate some points.
        1: First time registration take to the first question.
        2: If he/she try to start from some specific question so validate all previous questions
        has been answered, there is possibilties some new questions has been added
        from back office, if it is, then take him to back to that question that has been not
        answered yet.
        3: Otherwise allow him to start and complete their onward registration.
      */
      const targetId = Number(questionId);

      if (!this.isNumber(targetId)) {
        this.currentQuestionId = this.questions[0]?.id;
      } else {
        const targetQuestion = this.questions.find((q) => {
          const isUnanswered = !q.answer || q.answer.length === 0;
          const matchesTarget = targetId === q.id;
          const noDependencies = !q.demographic_dependencies;

          return (isUnanswered && noDependencies && !matchesTarget) || matchesTarget;
        });

        this.currentQuestionId = targetQuestion?.id || this.questions[0]?.id;
      }
      this.currentAction = actionToTake;
    },
    async performAction(route, resend = false) {
      try {
        const response = await axios.post(route.replace('**action**', this.currentQuestion?.action), {
          email: this.currentQuestion?.answer,
          registrationId: this.registration.id,
          id: this.currentQuestion?.id,
        });
        this.setAlert({
          login_route: response.data.login_route,
          action: response.data.action,
          message: response.data.message,
          resend,
        });
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.setAlert({ ...this.alert, resend: false });
        }, 5000);
      } catch (error) {
        this.errorMessage = error?.response?.data?.message;
      }
    },
    async save(isCompleted = false) {
      try {
        const response = await axios.post(this.registration.saveRoute, {
          question: this.currentQuestion,
          registrationId: this.registration.id,
          response: this.questions,
          is_completed: isCompleted || this.isLastQuestion,
        });
        this.handleSaveResponse(response);
      } catch (error) {
        this.handleSaveError(error);
      } finally {
        this.loading = false;
      }
    },
    handleSaveResponse(response) {
      if (response.data.is_completed) {
        window.location.hash = '/thank-you';
      } else {
        this.clearQuestionFieldProperties(this.currentQuestionIndex);
        this.navigateToQuestion(this.questions[this.currentQuestionIndex + 1]?.id, 'next');
        window.location.hash = `/process/${response.data.registration.uuid}/${this.questions[this.currentQuestionIndex + 1]?.id}`;
      }
    },
    handleSaveError(error) {
      if (error?.response?.status === 406) {
        window.location.hash = '/welcome';
      } else if (error?.response?.data?.errors?.registration_pending) {
        this.setAlert({ message: error?.response?.data?.message });
        window.location.hash = '/welcome';
      } else {
        this.updateQuestionField(this.currentQuestionIndex, 'error', error?.response?.data?.errors);
      }
    },
    setErrors(errors) {
      this.errors = errors;
    },
    setAlert(alertData) {
      this.alert = alertData;
    },
    setLoading(value) {
      this.loading = value;
    },
    setErrorMessage(value) {
      this.errorMessage = value;
    },
    sortQuestionOptions(options, sortType = 'implicit-ordering') {
      if (sortType !== 'implicit-ordering') {
        const sortedItems = sortType === 'alphabetic'
          ? options.sort((a, b) => (a.label < b.label || a.name < b.name ? -1 : 1))
          : _.shuffle(options);
        return sortedItems.map((node) => ({
          ...node,
          options: node.options ? this.sortQuestionOptions(node.options, sortType) : [],
        }));
      }
      return options;
    },
    updatePlacement(placement) {
      this.placement = placement;
    },
    updateRegistrationDetails(registration) {
      this.registration = registration;
    },
    updateCountries(countries) {
      this.countries = countries.map(({ code, name }) => ({ value: code, text: name }));
    },
    validateCurrentQuestion() {
      if (this.currentQuestion?.demographic_dependencies?.length) {
        const isAnswerFound = this.questions.some((q) => {
          const isAnswerArray = Array.isArray(q?.answer);
          const answerArray = isAnswerArray ? q.answer.map((a) => parseInt(a, 10)) : [parseInt(q.answer, 10)];
          const demographicDependenciesArray = this.currentQuestion.demographic_dependencies.map((dep) => parseInt(dep, 10));
          return _.includes(['demographic-single-select', 'demographic-multi-select', 'demographic-tree-view'], q.question_type)
            && ((isAnswerArray && _.intersection(answerArray, demographicDependenciesArray).length)
              || (!isAnswerArray && _.includes(demographicDependenciesArray, answerArray[0])));
        });

        if (!isAnswerFound) this.handleDemographicDependency();
      }
    },
    handleDemographicDependency() {
      const indexOffset = ['load', 'next'].includes(this.currentAction) ? 1 : -1;
      const newQuestionIndex = this.currentQuestionIndex + indexOffset;
      this.updateOrRedirectQuestion(this.questions[newQuestionIndex]);
    },
    updateOrRedirectQuestion(newQuestion) {
      if (newQuestion) {
        this.currentQuestionId = newQuestion.id;
        if (this.registration.id) {
          window.location.hash = `/process/${this.registration.id}/${newQuestion.id}`;
        }
      } else {
        this.redirectOnEdgeCases();
      }
    },
    redirectOnEdgeCases() {
      if (this.isLastQuestion && this.questions[this.questions.length - 1].id === this.currentQuestion.id) {
        this.currentQuestionId = this.questions[this.questions.length - 2]?.id;
        this.save(true);
      } else if (this.currentQuestion.id === this.questions[0]?.id) {
        this.currentQuestionId = this.questions[1]?.id;
        window.location.hash = `/process/${this.registration.id}/${this.questions[1]?.id}`;
      }
    },
  },
});

export default useRegistrationStore;
