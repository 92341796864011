<template>
  <form @submit="startRegistration">
    <div class="registration-welcome">
      <h2 class="registration--title">{{ form.external_title }}</h2>
      <p class="registration--description">
        {{ form.description }}
      </p>
      <RegistrationSponsorPlacement
        :form="form"
        :registration-sponsorship-placement-route="registrationSponsorshipPlacementRoute"
      />
    </div>
    <RegistrationFooter>
      <button type="submit" class="button button--primary button--full" autofocus>
          Start <i class="far fa-light fa-arrow-right"></i>
      </button>
    </RegistrationFooter>
  </form>
</template>

<script>
import { mapStores } from 'pinia';
import useRegistrationStore from '~/Store/Registration';
import RegistrationFooter from '~/Components/Frontend/Registration/Screens/RegistrationFooter.vue';
import RegistrationSponsorPlacement from '~/Components/Frontend/Registration/Screens/RegistrationSponsorPlacement.vue';

export default {
  name: 'RegistrationWelcome',
  components: [
    RegistrationFooter,
    RegistrationSponsorPlacement,
  ],
  created() {
    if (this.form.is_closed) {
      window.location.hash = '/closed';
    } else if (this.form.is_upcoming) {
      window.location.hash = '/coming-soon';
    } else if (!this.form.is_open) {
      window.location.hash = '/not-active';
    }
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    registrationSponsorshipPlacementRoute: {
      type: String,
      required: true,
    },
  },
  computed: {
    // Store will be accessible as its id + 'Store'
    ...mapStores(useRegistrationStore),
  },
  methods: {
    startRegistration(evt) {
      evt.preventDefault();
      evt.stopPropagation();

      window.location.hash = '/process';
    },
  },
  mounted() {
    this.registrationStore.resetState();
  },
};
</script>
