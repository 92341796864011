<template>
  <form @submit="navigate('next', $event)">

    <teleport to=".registration-header">
      <div class="registration-progress">
        <div class="registration-progress__active" :style="{ width: `${store.progress}%` }"></div>
      </div>
    </teleport>

    <div class="registration-process" v-if="currentRoute" aria-live="polite">
      <div v-if="isSendLink">
        <RegistrationSendLink />
      </div>
      <div v-else>
        <h2 class="registration--title">{{ store.currentQuestionTitle }}</h2>
        <p class="registration--description">
          {{ store.currentQuestion.description }}
        </p>
        <component :is="currentView"></component>
      </div>
    </div>

    <RegistrationFooter>
      <button
        type="button"
        :disabled="!isPrev"
        class="button button--gray registration-footer__back-button"
        @click="() => navigate('prev', $event)"
        :aria-label="__('buttons.back')"
        :aria-disabled="!isPrev"
      >
        <i class="far fa-light fa-arrow-left text-primary"></i>
      </button>
      <button type="submit"
        :disabled="isSendLink && !store.currentQuestion?.action"
        class="button button--primary button--full"
        :aria-label="isSubmit ? __('registrations.form.actions.submit') : __('registrations.form.actions.next')"
        :aria-disabled="isSendLink && !store.currentQuestion?.action"
      >
        {{ isSubmit ? __('registrations.form.actions.submit') : __('registrations.form.actions.next') }}
        &nbsp;
        <i v-if="!isSubmit" class="far fa-light fa-arrow-right"></i>
      </button>
    </RegistrationFooter>
  </form>
</template>

<script>
import {
  watch,
  computed,
  onMounted,
  defineComponent,
} from 'vue';
import _ from 'lodash';
import { storeToRefs } from 'pinia';
import axios from 'axios';
import useRegistrationStore from '~/Store/Registration';
import RegistrationFooter from '~/Components/Frontend/Registration/Screens/RegistrationFooter.vue';
import RegistrationSendLink from '~/Components/Frontend/Registration/QuestionTypes/Email/RegistrationSendLink.vue';
import RegistrationNameComponent from '~/Components/Frontend/Registration/QuestionTypes/RegistrationNameComponent.vue';
import RegistrationEmailComponent from '~/Components/Frontend/Registration/QuestionTypes/RegistrationEmailComponent.vue';
import RegistrationLongtextComponent from '~/Components/Frontend/Registration/QuestionTypes/RegistrationLongtextComponent.vue';
import RegistrationShortTextComponent from '~/Components/Frontend/Registration/QuestionTypes/RegistrationShortTextComponent.vue';
import RegistrationPhoneComponent from '~/Components/Frontend/Registration/QuestionTypes/RegistrationPhoneComponent.vue';
import RegistrationCountryComponent from '~/Components/Frontend/Registration/QuestionTypes/RegistrationCountryComponent.vue';
import RegistrationLegalComponent from '~/Components/Frontend/Registration/QuestionTypes/RegistrationLegalComponent.vue';
import RegistrationDemographicSingleSelectComponent from '~/Components/Frontend/Registration/QuestionTypes/RegistrationDemographicSingleSelectComponent.vue';
import RegistrationDemographicMultiSelectComponent from '~/Components/Frontend/Registration/QuestionTypes/RegistrationDemographicMultiSelectComponent.vue';
import RegistrationDemographicTreeViewComponent from '~/Components/Frontend/Registration/QuestionTypes/RegistrationDemographicTreeViewComponent.vue';

export default defineComponent({
  name: 'RegistrationProcess',
  components: {
    RegistrationFooter,
    RegistrationSendLink,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    questionId: String,
    registrationId: String,
    registrationQuestionsRoute: {
      type: String,
      required: true,
    },
    registrationSaveRoute: {
      type: String,
      required: true,
    },
    registrationActionRoute: {
      type: String,
      required: true,
    },
    countries: Array,
  },
  setup(props) {
    const store = useRegistrationStore();
    const { currentQuestionId } = storeToRefs(store);

    const routes = {
      name: RegistrationNameComponent,
      email: RegistrationEmailComponent,
      longtext: RegistrationLongtextComponent,
      shorttext: RegistrationShortTextComponent,
      phone: RegistrationPhoneComponent,
      country: RegistrationCountryComponent,
      legal: RegistrationLegalComponent,
      'demographic-single-select': RegistrationDemographicSingleSelectComponent,
      'demographic-multi-select': RegistrationDemographicMultiSelectComponent,
      'demographic-tree-view': RegistrationDemographicTreeViewComponent,
    };

    const compiledRoutes = computed(() => Object.entries(routes).map(([route, view]) => ({ route, view })));

    const currentRoute = computed(() => {
      const { currentQuestion } = store;
      return currentQuestion ? compiledRoutes.value.find((route) => currentQuestion.question_type === route.route) : null;
    });

    const currentView = computed(() => (currentRoute.value ? currentRoute.value.view : null));

    const isSubmit = computed(() => store.isLastQuestion
      || store.currentQuestion?.errors?.user_email
      || store.currentQuestion?.errors?.registration_incomplete);

    const isSendLink = computed(() => store.currentQuestion?.errors?.user_email
      || store.currentQuestion?.errors?.registration_incomplete);

    const isPrev = computed(() => store.currentQuestionIndex > 0);

    const navigate = (navigationType, evt) => {
      evt?.preventDefault();
      evt?.stopPropagation();

      if (_.includes(['send_login_link', 'send_incomplete_registration_link'], store.currentQuestion?.action)) {
        store.performAction(props.registrationActionRoute);
      } else if (navigationType === 'prev' && isPrev.value) {
        store.clearQuestionFieldProperties(store.currentQuestionIndex);
        store.navigateToQuestion(store.questions[store.currentQuestionIndex - 1].id, 'back');
        window.location.hash = `/process/${props.registrationId}/${store.questions[store.currentQuestionIndex - 1].id}`;
      } else if (navigationType === 'next') {
        store.save();
      }
    };

    const loadQuestions = async () => {
      if (!store.questions.length) {
        store.setLoading('screen-loading');
        try {
          const response = await axios.post(props.registrationQuestionsRoute, { registrationId: props.registrationId });
          store.updateQuestions(response.data.questions, props.questionId);
          store.updatePersonalization(response.data.personalization);
          store.updateCountries(props.countries);
        } catch (error) {
          store.setErrorMessage(error?.response?.data?.message);
          store.setErrors(error?.response?.data?.errors ?? {});
        } finally {
          store.setLoading('');
        }
      }
    };

    watch(currentQuestionId, () => {
      store.updateProgress();
      store.validateCurrentQuestion();
    });

    onMounted(() => {
      loadQuestions();
      store.updateRegistrationDetails({
        id: props.registrationId,
        saveRoute: props.registrationSaveRoute,
      });
    });

    return {
      currentRoute,
      currentView,
      isSubmit,
      isSendLink,
      isPrev,
      navigate,
      store,
    };
  },
});
</script>
