<template>
  <FeLoading v-if="loading" />

  <template v-else>
    <ProfileGroup>
      <LegalNoticesRow :legal-notices="legalNotices" />
    </ProfileGroup>
    <ProfileGroup>
      <DownloadPersonalDataRow download-link="/my-account/download-personal-data" />
    </ProfileGroup>
  </template>
</template>

<script>
import axios from 'axios';
import LegalNoticesRow from '~/Components/Frontend/Profile/Rows/LegalNoticesRow.vue';
import DownloadPersonalDataRow from '~/Components/Frontend/Profile/Rows/DownloadPersonalDataRow.vue';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import ProfileGroup from '~/Components/Frontend/Profile/ProfileGroup.vue';

export default {
  name: 'NotificationSettingsPage',
  components: {
    LegalNoticesRow,
    DownloadPersonalDataRow,
    FeLoading,
    ProfileGroup,
  },
  data() {
    return {
      legalNotices: null,
      loading: true,
    };
  },
  mounted() {
    axios.get('/legal-notices')
      .then((response) => {
        this.legalNotices = response.data.legal_notices;
      })
      .then(() => {
        this.loading = false;
      });
  },
};
</script>
