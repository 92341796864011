<template>
  <BaseModal
    startOpen
    width="wide"
    titleIcon="fa-list"
    :title="__('networking.match_score.explanation_modal.title')"
    ref="modal"
    >
    <h3>{{__('networking.match_score.explanation_modal.paragraph_1_title')}}</h3>
    <p>{{__('networking.match_score.explanation_modal.paragraph_1_description')}}</p>

    <h3>{{__('networking.match_score.explanation_modal.paragraph_2_title')}}</h3>
    <p>{{__('networking.match_score.explanation_modal.paragraph_2_description')}}</p>

    <h3>{{__('networking.match_score.explanation_modal.paragraph_3_title')}}</h3>
    <p>{{__('networking.match_score.explanation_modal.paragraph_3_description')}}</p>

    <h3>{{__('networking.match_score.explanation_modal.paragraph_4_title')}}</h3>
    <p>{{__('networking.match_score.explanation_modal.paragraph_4_description')}}</p>

    <h3>{{__('networking.match_score.explanation_modal.paragraph_5_title')}}</h3>
    <p>{{__('networking.match_score.explanation_modal.paragraph_5_description')}}</p>

    <template #footer>
      <div class="button-group">
        <button class="button" @click="$refs.modal.close()">
          <i class="fas fa-arrow-circle-left"></i> {{ __('buttons.return') }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>
<script>
export default {};
</script>
